import debounce from 'debounce'
import { useCallback } from 'react'

import { Loader } from '@/components'
import { TabProps } from '@/components/Tabs/Tabs'
import { useTabContext } from '@/context/TabContext/TabContext'
import { useAppDispatch, useAppStore } from '@/hooks'
import {
	initMapping,
	saveMapping,
	selectMappingTab,
	updateMapping,
} from '@/store/modules/mapping/actions'
import { MappingDataForm, MappingTab } from '@/store/modules/mapping/types'
import { UpdateDeepPartial } from '@/store/utils'

import { useDetailTabContext } from '../../components/DetailTab/context/DetailTabContext'
import { EditableNodeActions } from '../../components/EditableNodeActions/EditableNodeActions'
import { Title } from '../../components/Title/Title'
import { TitleLeftContent } from '../../components/TitleLeftContent'
import { useNodeInit } from '../../hooks/useNodeInit'
import { MappingTabs } from './components/Tabs'

interface MappingDetailProps {
	defaultMapping?: boolean
}

export const MappingDetail = ({ defaultMapping }: MappingDetailProps) => {
	const { onSaveError } = useTabContext()

	const {
		state: { node, editMode },
	} = useDetailTabContext()

	const dispatch = useAppDispatch()
	const mappings = useAppStore((state) => state.mapping.mappings)
	const data = mappings[node.id]

	useNodeInit()

	const handleTabChange = useCallback(
		(tab: TabProps) => {
			dispatch(selectMappingTab(node, tab.id as MappingTab))
		},
		[dispatch, node],
	)

	const saveDebounced = useCallback(
		debounce(async () => {
			try {
				await dispatch(saveMapping(node))
			} catch (e) {
				onSaveError(e)
			}
		}, 1000),
		[node.id],
	)

	const handleChange = useCallback(
		(v: UpdateDeepPartial<MappingDataForm>) => {
			if (editMode) {
				dispatch(updateMapping(node, v))
				saveDebounced()
			}
		},
		[editMode, dispatch, node],
	)

	const handleEdit = useCallback(async () => {
		await dispatch(initMapping({ nodeId: node.id, editMode: true }))
	}, [dispatch, node.id])

	const handleCancel = useCallback(async () => {
		await dispatch(initMapping({ nodeId: node.id, editMode: false }))
	}, [dispatch, node.id])

	if (!data) {
		return <Loader loaded={false} />
	}

	return (
		<>
			<Title
				type={node.type}
				title={node.name}
				editMode={editMode}
				leftContent={<TitleLeftContent node={node} />}
				rightContent={
					<EditableNodeActions
						node={node}
						editMode={editMode}
						dirty={data.dirty}
						onEdit={handleEdit}
						onCancel={handleCancel}
					/>
				}
			/>
			<MappingTabs
				defaultMapping={!!defaultMapping}
				onTabChange={handleTabChange}
				onChange={handleChange}
			/>
		</>
	)
}
