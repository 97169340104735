import { UpdateTableData } from '@/components'

export const addNewRow = <TData extends { id?: number }>(
	updateTableData: UpdateTableData<TData>,
	newRowPayload: TData,
) => {
	return updateTableData?.((draft: TData[]) => {
		// Calculate next ID
		const highestId = draft.reduce((accId, draftRow) => {
			return Math.max(accId, draftRow?.id || 0)
		}, 0)

		const { id: _id, ...payloadWithoutId } = newRowPayload

		// Add new row
		draft.push({
			id: highestId + 1,
			...payloadWithoutId,
		} as TData)
	})
}
