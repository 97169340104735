import { useMemo } from 'react'

import { DiagramProvider } from '@/components/Diagram/context/DiagramProvider'
import { Tabs } from '@/components/Tabs/Tabs'
import { useAppContext, useAppDispatch } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { DiagramTab } from '@/pages/User/pages/Home/pages/DiagramDetail/components/enums'
import { selectTabInTab } from '@/store/modules/tab/actions'

import { DiagramGraph } from '../pages/DiagramGraph'
import { DiagramOverview } from '../pages/DiagramOverview'

export const DiagramTabs = () => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const {
		state: { node, editMode },
	} = useDetailTabContext()

	const tabs = useMemo(
		() => [
			{
				id: DiagramTab.Overview,
				title: t('TAB_OVERVIEW'),
				content: <DiagramOverview />,
			},
			{
				id: DiagramTab.Diagram,
				title: t('TAB_DIAGRAM'),
				content: (
					<DiagramProvider isEditMode={editMode} node={node}>
						<DiagramGraph />
					</DiagramProvider>
				),
			},
		],
		[editMode, node, t],
	)

	return (
		<Tabs
			onChange={(tab) => dispatch(selectTabInTab(node.id, tab.id))}
			tabs={tabs}
			isPadded
		/>
	)
}
