import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { Button as MuiButton } from '@mui/material'
import property from 'lodash/property'
import without from 'lodash/without'
import { useState } from 'react'

import { Button } from '@/components'
import { Modal } from '@/components/Modal/Modal'
import { Checkbox } from '@/components/Properties/components/inputs/Checkbox'
import { MappingColumn } from '@/endpoints/schemas'
import { useAppContext } from '@/hooks'
import { StructureDto } from '@/rtkApi/mddApiSliceGenerated'
import { TableColumnFlat } from '@/store/modules/table/types'

import { ContentContainer, FooterContainer } from '../styles'
import { TableToolbarProps } from '../types'

interface FillColumnsButtonProps<TData>
	extends Partial<TableToolbarProps<TData>> {
	disabled?: boolean
}

const mapTableColumnToMappingColumn = (
	column: TableColumnFlat,
	currentId: number,
): MappingColumn => {
	return {
		id: currentId, // Use pre-computed unique ID
		columnCode: column.code ?? '',
		mapping: '',
	}
}

export const FillColumnsButton = <TData extends StructureDto>({
	availableColumns,
	tableData,
	updateTableData,
	disabled = false,
}: FillColumnsButtonProps<TData>) => {
	const { t } = useAppContext()
	const [isModalOpen, setModalOpen] = useState(false)
	const [selectedColumnIds, setSelectedColumnIds] = useState<number[]>([])
	const isAllSelected = selectedColumnIds.length === availableColumns?.length

	const onFillColumns = () => {
		const existingIds = tableData?.map((item) => item.id ?? -1)
		let nextId = existingIds?.length ? Math.max(...existingIds) + 1 : 1 // Start from `1` if tableData is empty

		const columnsToAdd = availableColumns
			?.filter(({ id }) => selectedColumnIds.includes(id))
			.map((column) => {
				const mappedColumn = mapTableColumnToMappingColumn(column, nextId)
				nextId++
				// Remove this casting when the logic is more generic
				return mappedColumn as unknown as TData
			})

		if (columnsToAdd?.length) {
			updateTableData?.((draft) => {
				draft.push(...columnsToAdd)
			})
		}

		setModalOpen(false)
	}

	return (
		<>
			<MuiButton
				onClick={() => setModalOpen(true)}
				startIcon={<PlaylistAddIcon />}
				disabled={disabled}
				variant="contained"
				color="primary"
			>
				Fill
			</MuiButton>

			<Modal
				open={isModalOpen}
				onClose={() => setModalOpen(false)}
				footer={
					<FooterContainer>
						<Button
							schema="primaryOutlined"
							onClick={() => setModalOpen(false)}
						>
							Cancel
						</Button>
						<Button schema="primary" onClick={onFillColumns}>
							Fill Columns
						</Button>
					</FooterContainer>
				}
			>
				<ContentContainer>
					<Checkbox
						label={t('SELECT_ALL')}
						disabled={false}
						isFocused={false}
						checked={isAllSelected}
						onChange={() =>
							isAllSelected
								? setSelectedColumnIds([])
								: setSelectedColumnIds(
										availableColumns?.map(property('id')) || [-1],
									)
						}
					/>
					{availableColumns?.map(({ id, name }) => (
						<Checkbox
							key={id}
							label={name}
							disabled={false}
							isFocused={false}
							checked={selectedColumnIds.includes(id)}
							onChange={() =>
								selectedColumnIds.includes(id)
									? setSelectedColumnIds((prev) => without(prev, id))
									: setSelectedColumnIds((prev) => [...prev, id])
							}
						/>
					))}
				</ContentContainer>
			</Modal>
		</>
	)
}
