import { MappingData } from '@/endpoints/schemas'
import { GeneralNodeActions } from '@/store/modules/tab/types'
import { updateTabData } from '@/store/utils'
import { NativeMap } from '@/utils/collections'

import { ensureValidData } from '../helpers'
import { TAB_CLOSE } from '../tab/constants'
import {
	MAPPING_INIT,
	MAPPING_SAVE,
	MAPPING_SELECT_TAB,
	MAPPING_UPDATE,
} from './constants'
import { defaultMappingData, getInitialMappingData } from './helpers'
import { Actions, MappingTab, OpenedMappingData } from './types'

type State = Readonly<typeof initialState>

const initialState = {
	mappings: {} as NativeMap<OpenedMappingData>,
}

export default (
	state = initialState,
	action: Actions | GeneralNodeActions,
): State => {
	switch (action.type) {
		case TAB_CLOSE: {
			const mappings = { ...state.mappings }
			delete mappings[action.nodeId]

			return {
				...state,
				mappings,
			}
		}

		case MAPPING_INIT: {
			const { node, editMode, force } = action

			const previous = state.mappings[Number(node.id)]

			if (previous && editMode && previous.parsedEditMode && !force) {
				return state
			}

			if (!node.id) return state

			const serializedData = editMode
				? node.workingData || node.data
				: node.data

			const parsed = JSON.parse(serializedData || '{}')

			const data: MappingData = Object.keys(parsed).length
				? ensureValidData(parsed, defaultMappingData)
				: getInitialMappingData(node.name)

			if (!data.columns) {
				data.columns = []
			}

			if (typeof data.columnsLastId !== 'number') {
				data.columnsLastId = Math.max(0, ...data.columns.map((c) => c.id))
			}

			if (!data.sourceItems) {
				data.sourceItems = []
			}

			if (typeof data.sourceItemsLastId !== 'number') {
				data.sourceItemsLastId = Math.max(
					0,
					...data.sourceItems.map((c) => c.id),
				)
			}

			if (typeof data.objectPermissionLastId !== 'number') {
				data.objectPermissionLastId = Math.max(
					0,
					...(data.objectPermissions?.map((c) => c.id ?? 0) ?? [0]),
				)
			}

			return {
				...state,
				mappings: {
					...state.mappings,
					[node?.id]: {
						form: data,
						original: data,
						stereotypeId: node.stereotypeId,
						tab: previous ? previous.tab : MappingTab.Overview,
						dirty: false,
						parsedEditMode: editMode,
					} as OpenedMappingData,
				},
			}
		}

		case MAPPING_SAVE: {
			const { node } = action.metadata

			if (!node.id) return state

			return {
				...state,
				mappings: updateTabData(state.mappings, node.id, (node) => ({
					...node,
					dirty: false,
				})),
			}
		}

		case MAPPING_UPDATE: {
			const { node, update } = action

			if (!node.id) return state

			return {
				...state,
				mappings: updateTabData(state.mappings, node.id, (node) => ({
					...node,
					form: {
						...node.form,
						...update,
					},
					dirty: true,
				})),
			}
		}

		case MAPPING_SELECT_TAB: {
			if (!action.node.id) return state

			return {
				...state,
				mappings: updateTabData(state.mappings, action.node.id, {
					tab: action.tab,
				}),
			}
		}

		default:
			return state
	}
}
