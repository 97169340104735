import AutoSizer from 'react-virtualized-auto-sizer'

import { Message } from '@/components'
import { AceEditorWithoutForm } from '@/components/AceEditor/AceEditor'
import { useAppContext } from '@/hooks'
import { GeneratedSqlDto } from '@/rtkApi/mddApiSliceGenerated'

interface SqlContentProps {
	sql: GeneratedSqlDto | null
}

export const SqlContent = ({ sql }: SqlContentProps) => {
	const { t } = useAppContext()

	const renderMessages = (errorArray?: string[]) => (
		<>
			{errorArray?.map((error) => (
				<Message key={error} type="error" header={t('ERROR')} message={error} />
			))}
		</>
	)

	return (
		<>
			{sql?.errors && renderMessages(sql.errors)}
			<AutoSizer>
				{({ width, height }: { height: number; width: number }) => (
					<div style={{ height, width }}>
						<AceEditorWithoutForm
							name="sqlData"
							value={sql?.sql || ''}
							disabled
							height={height}
							width={width.toString()}
						/>
					</div>
				)}
			</AutoSizer>
		</>
	)
}
