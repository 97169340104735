export const tableColInitVisibility = {
	columnCode: true,
	mapping: true,
	outerMapping: true,
	mappingDescription: true,
	mappingComment: true,
	keyFlag: true,
	updateFlag: true,
	groupByFlag: true,
	rightAction: true,
}

export const TABLE_COL_HIDDEN_FIELDS = [
	'description',
	'comment',
	'domainId',
	'dataType',
	'defaultValue',
	'stereotypeId',
	'virtualColumnScript',
	'primaryKey',
	'notNullFlag',
	'foreignKey',
]
