import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button, DialogWrapper, Flex, RemoveModal } from '@/components'
import { deleteRelation } from '@/endpoints'
import { useAppContext } from '@/hooks'
import { DependenciesModal } from '@/pages/User/pages/Home/components/Dependencies/components/DependenciesModal'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { useGetNodeRelationsQuery } from '@/rtkApi'
import { RelationDto } from '@/rtkApi/mddApiSliceGenerated'

import { DependencyLinkCellProps } from '../types'

export const DependenciesActions = <T extends RelationDto>({
	row,
}: DependencyLinkCellProps<T>) => {
	const { t } = useAppContext()

	const {
		state: { node, editMode },
	} = useDetailTabContext()

	const { refetch } = useGetNodeRelationsQuery({
		structureId: node.id,
	})

	const dependency = row.original
	return (
		<Flex>
			{dependency.relationType === 'CUSTOM_LINK' &&
				dependency.leftSideStructure?.id === node.id && (
					<>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<DependenciesModal
										node={node}
										dependency={dependency}
										onSave={refetch}
										onClose={onClose}
									/>
								)
							}
						>
							{(onClick) => (
								<Button
									size="sm"
									schema="transparent"
									onClick={onClick}
									disabled={!editMode}
								>
									<FontAwesomeIcon title={t('EDIT')} icon={faEdit} />
								</Button>
							)}
						</DialogWrapper>

						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<RemoveModal
										callback={deleteRelation}
										params={[dependency.id]}
										onRemove={refetch}
										removeId={node.id}
										textOnModal={t('REMOVE_DESC', [dependency?.name ?? ''])}
										header={t('REMOVE_STEREOTYPE')}
										onClose={onClose}
									/>
								)
							}
						>
							{(onClick) => (
								<Button
									size="sm"
									schema="transparent"
									onClick={onClick}
									disabled={!editMode}
								>
									<FontAwesomeIcon
										title={t('REMOVE')}
										color="#d00"
										icon={faTimes}
									/>
								</Button>
							)}
						</DialogWrapper>
					</>
				)}
		</Flex>
	)
}
