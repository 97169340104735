import TextField from '@mui/material/TextField'
import { MRT_ColumnDef } from 'material-react-table'
import { ReactNode } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'

import { NewCheckBox } from '@/components'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { AutoResizingTextFieldBase } from '@/components/AutoResizingTextField'
import { useTableContext } from '@/components/NewTable'
import { Select } from '@/components/UberForm'
import { useCanGenerateNaming } from '@/components/UberForm/Input/Naming/useCanGenerateNaming'
import { ItemPropertyType } from '@/enums'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { TableColumnFlat } from '@/store/modules/table/types'
import { NamingTypeEnum } from '@/types'
import { cn } from '@/utils/cn'

import { getUnavailableOptionClass } from './helpers'
import { resolveSelectOptions } from './utils'

interface FormFieldsProps {
	control: Control<FieldValues, any>
	fieldName: string
	fieldProp: MRT_ColumnDef<any>
	selectOptions?: TableColumnFlat[]
}

export const formFieldSwitch: Record<
	ItemPropertyType,
	(props: FormFieldsProps) => ReactNode
> = {
	[ItemPropertyType.ACE_EDITOR]: ({ control, fieldName, fieldProp }) => {
		const {
			state: { editMode },
		} = useDetailTabContext()

		const { selectedRow } = useTableContext<any>()

		return (
			<Controller
				control={control}
				name={fieldName}
				render={({ field }) => {
					return (
						<AceEditorField
							title={fieldProp.header}
							disabled={
								selectedRow.stereotypeCode !== 'VIRTUAL_COLUMN' || !editMode
							}
							{...field}
							value={field.value}
						/>
					)
				}}
			/>
		)
	},
	[ItemPropertyType.CHECKBOX]: ({ control, fieldName }) => {
		const {
			state: { editMode },
		} = useDetailTabContext()
		return (
			<Controller
				control={control}
				name={fieldName}
				render={({ field }) => (
					<NewCheckBox
						className="justify-start"
						checked={field.value}
						disabled={!editMode}
						{...field}
					/>
				)}
			/>
		)
	},
	[ItemPropertyType.OPTION]: ({ control, fieldProp, fieldName }) => {
		const {
			state: { editMode },
		} = useDetailTabContext()

		const { selectOptions, optionLabel, optionValue, isNumeric } =
			fieldProp.meta || {}
		const { invalidColumnCodes, selectedRow } = useTableContext()

		const className = getUnavailableOptionClass(
			selectedRow?.columnCode,
			invalidColumnCodes,
		)

		const labelClassName = cn('pb-1', {
			'text-neutral-400': !editMode,
		})

		return (
			<Controller
				defaultValue={null}
				control={control}
				name={fieldName}
				render={({ field }) => {
					return (
						<>
							<label className={labelClassName} htmlFor="custom select">
								{fieldProp.header}
							</label>
							<Select
								className={className}
								options={resolveSelectOptions(selectOptions)}
								valueKey={optionValue}
								labelKey={optionLabel}
								clearable
								isDisabled={!editMode}
								isNumeric={isNumeric}
								{...field}
							/>
						</>
					)
				}}
			/>
		)
	},
	[ItemPropertyType.TEXT]: ({ control, fieldName, fieldProp }) => {
		const {
			state: { editMode, node },
		} = useDetailTabContext()

		const canGenerateNaming = useCanGenerateNaming(
			node.id,
			NamingTypeEnum.GENERATE_TABLE_COLUMN_CODE,
		)

		const getDisabled = () => {
			if (canGenerateNaming && fieldName === 'code') return true

			return !editMode
		}

		const disabled = getDisabled()

		return (
			<Controller
				control={control}
				name={fieldName}
				render={({ field }) => {
					return (
						<TextField
							{...field}
							disabled={disabled}
							fullWidth
							label={fieldProp.header}
							size="small"
							value={field.value ?? ''}
						/>
					)
				}}
			/>
		)
	},
	[ItemPropertyType.TEXTAREA]: ({ control, fieldName, fieldProp }) => {
		const {
			state: { editMode },
		} = useDetailTabContext()
		return (
			<Controller
				control={control}
				name={fieldName}
				render={({ field }) => (
					<AutoResizingTextFieldBase
						{...field}
						className="w-full"
						disabled={!editMode}
						label={fieldProp.header}
						value={field.value ?? ''}
					/>
				)}
			/>
		)
	},
	[ItemPropertyType.HIDDEN]: () => <></>,
}
