import { updateDataNode } from 'src/endpoints'

import { MappingColumn, MappingData, SourceItem } from '@/endpoints/schemas'
import { StructureDto, StructureObjectDto } from '@/rtkApi/mddApiSliceGenerated'
import { apiCallAction, AppDispatch, UpdateDeepPartial } from '@/store/utils'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'

import { loadNodeOrHistoryVersion } from '../node/utils'
import {
	MAPPING_INIT,
	MAPPING_SAVE,
	MAPPING_SELECT_TAB,
	MAPPING_UPDATE,
} from './constants'
import { Actions, InitMapping, MappingDataForm, MappingTab } from './types'

export const initMapping =
	({
		nodeId,
		editMode = false,
		force = false,
		version,
		envId,
	}: InitDataParams) =>
	async (dispatch: AppDispatch) => {
		const node = await loadNodeOrHistoryVersion(nodeId, version, envId)

		dispatch({
			type: MAPPING_INIT,
			node,
			editMode,
			force,
		} as InitMapping)
	}

export const updateMapping = (
	node: StructureDto,
	update: UpdateDeepPartial<MappingDataForm>,
): Actions => ({
	type: MAPPING_UPDATE,
	node,
	update,
})

export const saveMapping = (node: StructureDto) =>
	apiCallAction(
		(getState) => {
			const mapping = getState().mapping.mappings[Number(node.id)]

			if (!mapping) {
				throw new Error(`Saving unopened state ${JSON.stringify(node)}`)
			}

			const filteredColumns = (columns: MappingColumn[] = []) =>
				columns.filter((c) => !!c.columnCode)

			const filteredSourceItems = (sourceItems: SourceItem[] = []) =>
				sourceItems.filter((c) => c.id !== -1)

			const formData: MappingData = {
				...mapping.form,
				columns: filteredColumns(mapping.form.columns),
				sourceItems: filteredSourceItems(mapping.form.sourceItems),
			}

			return updateDataNode(Number(node.id), {
				data: JSON.stringify(formData),
			})
		},
		MAPPING_SAVE,
		{ node },
	)

export const selectMappingTab = (
	node: StructureObjectDto,
	tab: MappingTab,
): Actions => ({
	type: MAPPING_SELECT_TAB,
	node,
	tab,
})
