import debounce from 'debounce'

import { Form, SelectFormField, TextFormField } from '@/components/UberForm'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { ValidationMessageDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'

type FilterProps = {
	onChange: (values: FilterValues) => void
}

export type FilterValues = {
	fulltext?: string
	severity?: ValidationMessageDto.SeverityEnum
}

const severityOptions = [
	{ value: ValidationMessageDto.SeverityEnum.ERROR },
	{ value: ValidationMessageDto.SeverityEnum.WARN },
	{ value: ValidationMessageDto.SeverityEnum.INFO },
]

export const Filter = ({ onChange }: FilterProps) => {
	const { t } = useAppContext()

	const debouncedOnChange = debounce(onChange, 200)

	const handleChange = (values: FilterValues, source?: FormlessFormField) =>
		source?.props.name === 'fulltext'
			? debouncedOnChange(values)
			: onChange(values)

	return (
		<Form onChange={handleChange}>
			<div className="grid grid-cols-2 gap-4 max-w-3xl mb-4">
				<div className="min-w-60">
					<SelectFormField
						title={t('VALIDATION_SEVERITY')}
						options={severityOptions}
						name="severity"
						labelKey="value"
						allowEmpty
						clearable
					/>
				</div>
				<div className="min-w-60">
					<TextFormField title={t('VALIDATION_FULLTEXT')} name="fulltext" />
				</div>
			</div>
		</Form>
	)
}
