import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import { MRT_RowData } from 'material-react-table'

import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'

import { type MrtCellProps } from '../types'

export const RowActionsCell = <TData extends MRT_RowData>({
	deleteRow,
	row,
}: MrtCellProps<TData>) => {
	const {
		state: { editMode },
	} = useDetailTabContext()

	return (
		<div className="flex justify-center items-center">
			<IconButton
				onClick={() => deleteRow?.(row?.original.id || -1)}
				aria-label="remove"
				color="error"
				size="small"
				disabled={!editMode}
			>
				<DeleteIcon />
			</IconButton>
		</div>
	)
}
