import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { Resizable } from '@/components/Resizable/Resizable'
import {
	useAppDispatch,
	useAppStore,
	useDebounceCallback,
	useResizeObserver,
} from '@/hooks'
import { tableRowSelected } from '@/store'
import {
	setTabPropertiesShown,
	setTabPropertiesWidth,
} from '@/store/modules/tab/actions'

import { Properties } from './Properties'
import { PropsContainer } from './styles'
import { PanelPropertiesProps } from './types'
import { usePropertiesPanel } from './utils'

export const PanelProperties = <Item extends object>({
	errors,
	items,
	listOfValues,
	node,
	onChange,
	panelPropertiesDefaultOpen,
	panelPropertiesMinWidth,
	properties,
	tabKey,
}: PanelPropertiesProps<Item>) => {
	const dispatch = useAppDispatch()
	const { propertiesHidden } = usePropertiesPanel(node?.id, tabKey)
	const refContainer = useRef<HTMLDivElement | null>(null)
	const entry = useResizeObserver(refContainer)

	const openedTabs = useAppStore((store) => store.tab.openedTabs)
	const nodeId = useAppStore((store) => store.tab.selectedId)
	const tabInfo = openedTabs.find((tab) => tab.nodeId === nodeId)
	const isEditMode = Boolean(tabInfo?.editMode)

	const selectedRowIndex =
		useAppStore((store) => store.miscSlice.selectedRowTable) || 0

	const propertiesStoreWidth =
		useAppStore(
			(state) =>
				state.tab.openedTabs.find((t) => t.nodeId === node?.id)
					?.propertiesShown[tabKey]?.width,
		) || 500

	const propertiesWidth = useMemo(
		() => (propertiesHidden ? 0 : propertiesStoreWidth),
		[propertiesHidden, propertiesStoreWidth],
	)

	const onWidthChange = useDebounceCallback((width: number) => {
		dispatch(setTabPropertiesWidth(node, tabKey, width))
	}, 50)

	const panelOpen = () => dispatch(setTabPropertiesShown(node, tabKey, true))

	useEffect(() => {
		if (panelPropertiesDefaultOpen) {
			panelOpen()
		}
	}, [panelPropertiesDefaultOpen])

	const handleSelect = useCallback((selectedIndex: number) => {
		return dispatch(tableRowSelected(selectedIndex))
	}, [])

	return (
		<>
			<PropsContainer $propertiesHidden={propertiesHidden} ref={refContainer}>
				{properties.length > 0 && (
					<Resizable
						initialWidth={propertiesStoreWidth}
						minWidth={panelPropertiesMinWidth ?? 150}
						maxWidth={entry?.contentRect?.width}
						onChange={onWidthChange}
					>
						<Properties
							item={items[selectedRowIndex]}
							index={selectedRowIndex}
							onChange={onChange}
							properties={properties}
							listOfValues={listOfValues}
							readonly={!isEditMode}
							isHidden={propertiesHidden}
							onShow={() => panelOpen()}
							onHide={() =>
								dispatch(setTabPropertiesShown(node, tabKey, false))
							}
							errors={errors}
							propertiesWidth={propertiesWidth}
							onSelect={handleSelect}
						/>
					</Resizable>
				)}
			</PropsContainer>
		</>
	)
}
