import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import { MaterialReactTable } from 'material-react-table'
import React, { useCallback, useMemo } from 'react'

import {
	muiRowDragHandleProps,
	NewPanelProperties,
	PageWrap,
	TableProvider,
	TableToolbar,
} from '@/components'
import { useCanGenerateNaming } from '@/components/UberForm/Input/Naming/useCanGenerateNaming'
import { newTableColumnPayload } from '@/constants'
import { StereotypeDto, StructureDto } from '@/endpoints/models'
import {
	useAppContext,
	useAppDispatch,
	useAppStore,
	usePanelProperties,
	useTable,
	useTableHandlers,
} from '@/hooks'
import { ImportColumnsButton } from '@/pages/User/pages/Home/pages/TableDetail/components/ImportColumnsButton'
import {
	TABLE_COL_HIDDEN_FIELDS,
	tableColInitVisibility,
} from '@/pages/User/pages/Home/pages/TableDetail/pages/Columns/constants'
import { ColumnsComponentProps } from '@/pages/User/pages/Home/pages/TableDetail/pages/types'
import { useGetStereotypesQuery } from '@/rtkApi'
import { findSystemNodeId } from '@/store/modules/node/helpers'
import { saveTable, updateTable } from '@/store/modules/table/actions'
import { TableColumnFlat } from '@/store/modules/table/types'
import { NamingTypeEnum } from '@/types'
import { NativeMap } from '@/utils'
import { useDomainTechnology } from '@/utils/domain'

import { useAsyncColumnValueUpdate } from '../../hooks/useAsyncColumnValueUpdate'
import { useSyncColumnValueUpdate } from '../../hooks/useSyncColumnValueUpdate'
import { getColumnSx } from './helpers'
import { useColumnProperties } from './useColumnProperties'
import { duplication } from './validation'

const ColumnsComponent = ({
	node,
	data,
	systemNodeId,
	technicalColumns,
	domains,
	reloadTableData,
}: ColumnsComponentProps) => {
	const { t } = useAppContext()

	const nodes = useAppStore((state) => state.node.nodes)
	const systemId = findSystemNodeId(node, nodes as NativeMap<StructureDto>)
	const systemsSlice = useAppStore((state) => state.system.systems)
	const isCodeLowerCase = systemsSlice[systemId]?.form
		?.letterCaseToggle as boolean

	const { data: columnStereotypes } = useGetStereotypesQuery({
		type: StereotypeDto.TypeEnum.COLUMN,
	})

	const dispatch = useAppDispatch()

	const { getDomainData } = useDomainTechnology(systemNodeId)

	const errors = useMemo(
		() => duplication(data.form.columns, technicalColumns, t),
		[data.form.columns, t, technicalColumns],
	)

	const canGenerateNaming = useCanGenerateNaming(
		node.id,
		NamingTypeEnum.GENERATE_TABLE_COLUMN_CODE,
	)

	const { handleAsyncColumnValueUpdate } = useAsyncColumnValueUpdate({
		sequenceColumnCode: data.form.sequenceColumnCode,
		node,
		isCodeLowerCase,
	})

	const { handleSyncColumnValueUpdate } = useSyncColumnValueUpdate({
		columnStereotypes,
		domains,
		getDomainData,
		formColumns: data.form.columns,
	})

	const {
		columnVisibility,
		dataKey,
		isUserInteracting,
		selectedRow,
		setSelectedRow,
		setTableData,
		tableData,
		updateTableData,
		deleteRow,
		updateCellValue,
		setColumnVisibility,
		updateSelectedRow,
		handleRowClick,
	} = useTableHandlers<TableColumnFlat>({
		columnInitVisibility: tableColInitVisibility,
		initialData: data.form.columns || [],
		reduxActions: {
			saveAction: saveTable,
			updateAction: updateTable,
			updateKey: 'columns',
		},
		customUpdateCellValue: useCallback(
			async (
				rowIndex: number,
				columnId: keyof TableColumnFlat,
				value: unknown,
			) => {
				const draftRowOriginal = data.form.columns[rowIndex]

				if (!isUserInteracting.current || value === undefined || value === null)
					return

				const newCode = await handleAsyncColumnValueUpdate(
					columnId,
					value,
					draftRowOriginal,
				)

				// NOW sync state synchronously after finishing async tasks
				updateTableData((draft) =>
					handleSyncColumnValueUpdate(
						draft,
						rowIndex,
						columnId,
						value,
						newCode,
					),
				)
			},
			[
				data.form.sequenceColumnCode,
				data.form.columns,
				isCodeLowerCase,
				dispatch,
				node,
				columnStereotypes,
			],
		),
		customDeleteRow: useCallback((deletedRowId: number | string) => {
			if (!isUserInteracting.current) return
			updateTableData((draft) => {
				// sync sequenceColumnCode when removing column
				if (data.form.sequenceColumnCode) {
					const isSequenceColumnRemoved = draft.some(
						(c) => !(c.code === data.form.sequenceColumnCode),
					)

					if (isSequenceColumnRemoved) {
						dispatch(
							updateTable(node, {
								sequenceColumnCode: undefined,
								sequenceCode: undefined,
							}),
						)
					}
				}

				const idx = draft.findIndex((row) => row.id === deletedRowId)
				if (idx !== -1) draft.splice(idx, 1)
			})
		}, []),
	})

	const { isPanelOpen, invalidColumnCodes, handleClosePanel, handleOpenPanel } =
		usePanelProperties({
			setColumnVisibility,
			columnsHiddenOnOpen: TABLE_COL_HIDDEN_FIELDS,
		})

	const columns = useColumnProperties({
		canGenerateNaming,
		constraints: data.form.constraints,
		deleteRow,
		domains,
		handleRowClick,
		isPanelOpen,
		updateCellValue,
	})

	const techColumns = useColumnProperties({
		domains,
		constraints: data.form.constraints,
		canGenerateNaming,
		isTechColumn: true,
	})

	const table = useTable<TableColumnFlat>({
		columns,
		maxHeight: '400px',
		data: tableData,
		options: {
			initialState: {
				density: 'compact',
			},
			layoutMode: isPanelOpen ? 'grid' : 'semantic',
			renderBottomToolbar: () => {
				return (
					<TableToolbar<TableColumnFlat>
						updateTableData={updateTableData}
						newRowPayload={newTableColumnPayload}
						tableData={tableData}
						customButtons={
							<ImportColumnsButton reloadTableData={reloadTableData} />
						}
					/>
				)
			},
			state: {
				columnVisibility,
			},
			muiRowDragHandleProps: ({ table }) =>
				muiRowDragHandleProps({ updateTableData, table }),
			enableColumnResizing: true,
		},
	})

	const techTable = useTable<TableColumnFlat>({
		columns: techColumns,
		data: technicalColumns,
		options: {
			initialState: {
				density: 'compact',
			},
			layoutMode: isPanelOpen ? 'grid' : 'semantic',
			state: {
				columnVisibility,
			},
			enableRowOrdering: false,
		},
	})

	return (
		<PageWrap
			onMouseEnter={() => {
				isUserInteracting.current = true
			}}
			hasPropPanel
		>
			<TableProvider<TableColumnFlat>
				invalidColumnCodes={invalidColumnCodes}
				isPanelOpen={isPanelOpen}
				selectedRow={selectedRow}
				setSelectedRow={setSelectedRow}
				setTableData={setTableData}
				tableData={tableData}
				updateTableData={updateTableData}
			>
				{/*Columns Table*/}
				<Box component="main" sx={getColumnSx(isPanelOpen)}>
					<MaterialReactTable key={dataKey} table={table} />
				</Box>

				{/*Technical Columns Table*/}
				{technicalColumns && technicalColumns.length > 0 && (
					<>
						<Typography className="pt-8 pb-4" component="h5">
							{t('TECHNICAL_COLUMNS_TITLE')}
						</Typography>

						<Box component="main" sx={getColumnSx(isPanelOpen)}>
							<MaterialReactTable key={dataKey} table={techTable} />
						</Box>
					</>
				)}

				<NewPanelProperties<TableColumnFlat>
					formFields={columns}
					updateSelectedRow={updateSelectedRow}
					handleClosePanel={handleClosePanel}
					handleOpenPanel={handleOpenPanel}
				/>
			</TableProvider>
		</PageWrap>
	)
}

export const Columns = React.memo(ColumnsComponent)
