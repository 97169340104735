import { useContext } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { FormFieldContext } from '@/components/UberForm/FormFieldContext'
import { AppContext, AppContextType } from '@/context/AppContext'
import {
	AuthContext,
	AuthContextInterface,
} from '@/context/AuthContext/AuthContext'
import { AppDispatch, RootState } from '@/store'

export const useAppContext = () => {
	return useContext(AppContext) as AppContextType
}

export const useFormFieldContext = () => {
	return useContext(FormFieldContext)
}

export const useAuthContext = () => {
	return useContext(AuthContext) as AuthContextInterface
}

export const useAppStore = <T>(selector: (state: RootState) => T) => {
	return useSelector(selector)
}

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useAppDispatch = () => useDispatch<AppDispatch>()

export { useClickOutside } from './useClickOutside'
export * from './useCustomAttributes'
export { useDebounce } from './useDebounce'
export { useDebounceCallback } from './useDebounceCallback'
export { useEffectWithoutMount } from './useEffectWithoutMount'
export { useDocumentEvent, useEvent, useWindowEvent } from './useEvent'
export { useInterval } from './useInterval'
export { useIsMounted } from './useIsMounted'
export { useLocalStore } from './useLocalStore'
export { useMemoObject } from './useMemoObject'
export { usePanelProperties } from './usePanelProperties'
export { usePrevious } from './usePrevious'
export { useResizeObserver } from './useResizeObserver'
export { useResponsiveBreakpoint } from './useResponsiveBreakpoint'
export { useSaveFile } from './useSaveFile'
export { useScrollbarWidth } from './useScrollbarWidth'
export { useTable } from './useTable'
export { useTableColumnDefinitions } from './useTableColumnDefinitions'
export { useTableColumns } from './useTableColumns'
export { useTableHandlers } from './useTableHandlers'
export { useWhyUpdate } from './useWhyUpdate'
