import { SystemUser } from '@/rtkApi/mddApiSliceGenerated'

interface PermissionUpdateCellValueProps<T> {
	columnId: keyof T
	isUserInteracting: React.MutableRefObject<boolean>
	rowIndex: number
	systemUsers: SystemUser[]
	updateTableData: (updater: (draft: T[]) => void) => void
	value: T[keyof T]
}

export interface PermissionType {
	[key: string]: any
	id?: number
	permissions?: string[]
	user?: SystemUser
}

export const permissionUpdateCellValue = <T extends PermissionType>({
	rowIndex,
	columnId,
	value,
	updateTableData,
	isUserInteracting,
	systemUsers,
}: PermissionUpdateCellValueProps<T>) => {
	// Without this check on first render the logic would assign undefined to some dropdowns
	if (!isUserInteracting.current || value === undefined || value === null)
		return

	updateTableData((draft: T[]) => {
		const updatedRow: T = draft?.[rowIndex]

		const foundUser = systemUsers?.find((user) => user.id === updatedRow?.id)

		if (foundUser) {
			updatedRow.user = {
				...updatedRow.user,
				code: foundUser.code,
				comment: foundUser.comment,
				description: foundUser.description,
				name: foundUser.name,
			}
		}

		updatedRow[columnId] = value
	})
}
