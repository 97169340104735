import { faPlus, faPuzzlePiece } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
	Button,
	Container,
	DialogWrapper,
	LightTable,
	Loader,
} from '@/components'
import { useAppContext } from '@/hooks'
import { useCreateTemplateMutation, useGetAllTemplatesQuery } from '@/rtkApi'
import { type TemplateListDto } from '@/rtkApi/mddApiSliceGenerated'

import { useFilter } from '../../hooks/useFilter'
import {
	TitleActions,
	TitleContainer,
	TitleIcon,
	TitleText,
} from '../../styles'
import {
	TemplateFilter,
	type TemplateFilterValues,
	TemplateFormValues,
	TemplateModal,
} from './components'
import { handleTemplateOperation } from './helpers'
import { useTemplatesColumns, useTemplatesFilteredData } from './hooks'

interface TemplatesProps {
	textAreaHeight?: string
}

export const Templates = ({ textAreaHeight }: TemplatesProps) => {
	const { t } = useAppContext()
	const { data, isLoading, refetch } = useGetAllTemplatesQuery({})
	const [createTemplate] = useCreateTemplateMutation()
	const [isConfirmModalVisible, setIsConfirmModalVisible] =
		useState<boolean>(false)

	const { filter, onChangeFilter } = useFilter<TemplateFilterValues>()

	const onOpenConfirmModal = () => {
		setIsConfirmModalVisible(true)
	}

	const columns = useTemplatesColumns({
		onOpenConfirmModal,
		isConfirmModalVisible,
		setIsConfirmModalVisible,
	})
	const filteredData = useTemplatesFilteredData(filter, data)

	const onSubmit = async (
		formValues: TemplateFormValues,
		onClose: () => void,
	) => {
		try {
			await handleTemplateOperation(formValues, onClose, {
				createTemplate,
				isCreate: true,
			})
			toast(t('TEMPLATE_NEW_SUCCESS'), {
				type: 'success',
			})
		} catch (error) {
			toast(t('TEMPLATE_NEW_FAILED', [error]), {
				type: 'error',
			})
		}
	}

	const dialog = (opened: boolean, onClose: () => void) =>
		opened && (
			<TemplateModal
				isCreateModal
				onOpenConfirmModal={onClose}
				onSubmit={(formValues) => onSubmit(formValues, onClose)}
				textAreaHeight={textAreaHeight}
			/>
		)

	const parseTemplateData = () => {
		const parsedData = filteredData.map((item) => ({
			...item,
			technologyName: item.technology?.name,
			stereotypeName: item.stereotype?.name,
			authorName: item.author?.loginName,
			objectSettingsName: item.objectSettings?.name,
		}))

		return parsedData
	}

	const tableData = parseTemplateData()

	useEffect(() => {
		refetch()
	}, [])

	return (
		<>
			<TitleContainer>
				<TitleIcon icon={faPuzzlePiece} />
				<TitleText>{t('TEMPLATES')}</TitleText>
				<TitleActions>
					<DialogWrapper dialog={dialog}>
						{(onClick) => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TitleActions>
			</TitleContainer>

			<Container>
				<TemplateFilter onChangeFilter={onChangeFilter} />
				<Loader loaded={!isLoading} $absolute />

				<LightTable<TemplateListDto>
					data={tableData}
					columns={columns}
					keyField={'id'}
					isLoading={isLoading}
				/>
			</Container>
		</>
	)
}
