import { MRT_Row } from 'material-react-table'

import { RelationDto, StructureDto } from '@/rtkApi/mddApiSliceGenerated'

export enum SearchEnum {
	API = 'API',
	FOLDER = 'FOLDER',
	MAPPING = 'MAPPING',
	QUEUE = 'QUEUE',
	SYSTEM_NODE = 'SYSTEM_NODE',
	TABLE = 'TABLE',
	VIEW = 'VIEW',
	WORKFLOW = 'WORKFLOW',
}

export enum TypeEnum {
	CUSTOM_LINK = 'CUSTOM_LINK',
	LINK = 'LINK',
	LINK_COLUMN = 'LINK_COLUMN',
	PK_FK_COLUMN = 'PK_FK_COLUMN',
	PK_FK_TABLE = 'PK_FK_TABLE',
}

export interface DependencyLinkCellProps<T extends RelationDto> {
	node?: StructureDto
	row: MRT_Row<T>
}

export interface ResolveLinkProps extends RelationDto {
	nodeId: number | undefined
}
