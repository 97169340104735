import { createMRTColumnHelper, MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'

import { TextCell } from '@/components'
import { useAppContext } from '@/hooks'
import { HistoryDto } from '@/rtkApi/mddApiSliceGenerated'

export const useVersionsTableColumns = () => {
	const { t } = useAppContext()

	const columnHelper = createMRTColumnHelper<HistoryDto>()

	const columns = useMemo(
		() => [
			columnHelper.accessor('version', {
				header: t('TABLE_GENERAL_VERSION'),
				size: 50,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
					/>
				),
			}),
			columnHelper.accessor('created', {
				header: t('TABLE_GENERAL_DATE'),
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
					/>
				),
			}),
			columnHelper.accessor('authorName', {
				header: t('TABLE_GENERAL_AUTHOR'),
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
					/>
				),
			}),
			columnHelper.accessor('description', {
				header: t('TABLE_COLUMNS_DESCRIPTION'),
				size: 50,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
					/>
				),
			}),
		],
		[columnHelper, t],
	)

	return columns as MRT_ColumnDef<HistoryDto>[]
}
