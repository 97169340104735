import { createTheme } from '@mui/material/styles'

import { colors, mainColors } from '@/styles/index'

// Define a custom theme with a grey palette
export const theme = createTheme({
	palette: {
		primary: {
			main: mainColors.primary, // Primary main color (blue by default)
		},
		secondary: {
			main: mainColors.secondary, // Secondary main color (purple by default)
		},
	},
	components: {
		MuiTableHead: {
			styleOverrides: {
				root: {
					'.MuiTableRow-root': {
						backgroundColor: mainColors.primary,
					},
					'.MuiTableCell-root': {
						fontSize: '1rem',
						color: 'white',
						':not(:last-child)': {
							borderRight: '0.063rem solid white',
						},
					},
					'.MuiInputBase-input': {
						color: 'white',
						border: 'none',
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					color: 'white', // Header text color
					'& .MuiTableSortLabel-root': {
						color: 'white', // Sort label (text)

						'&:hover': {
							color: 'white', // Text color when hovered
						},

						'&.Mui-active': {
							color: 'white', // Text color when the column is active (sorted)
							'& .MuiTableSortLabel-icon': {
								color: 'white !important', // Sort arrow color when active
							},
						},
					},
					'& .MuiSvgIcon-root': {
						color: 'white !important', // Filter, three dots icon color
						'&:hover': {
							color: 'white !important', // Hover state color for filter and icons
						},
					},
					'&[data-pinned="true"]:before': {
						backgroundColor: 'unset !important',
						boxShadow: 'unset !important',
					},
				},
				root: {
					'&:first-of-type': {
						textAlign: 'center',
						justifyContent: 'center', // Styles for first column
					},
					border: `1px solid ${colors.table.thead.borderColor}`, // Apply 1px solid border to all table cells, including head and body
					'&[data-pinned="true"]:before': {
						backgroundColor: 'unset !important',
						boxShadow: 'unset !important',
					},
				},
				body: {
					// Styles that apply ONLY to body cells (<td>)
					padding: '0 !important', // Remove padding from body cells
				},
			},
		},
	},
})

export default theme
