import { useEffect, useMemo, useRef, useState } from 'react'

import { Tabs } from '@/components'
import { ModalForm } from '@/components/Modal/ModalForm'
import { TabProps } from '@/components/Tabs'
import { Form } from '@/components/UberForm'
import { TemplateTab } from '@/enums'
import { useAppContext, useAppSelector } from '@/hooks'
import { TemplateConfirmModal } from '@/pages/User/pages/Settings/pages/Templates/components/TemplateConfirmModal'
import {
	useGetTemplateQuery,
	useLockMutation,
	usePushMutation,
	useUnlockMutation,
} from '@/rtkApi'
import {
	type TemplateDto,
	type TemplateListDto,
} from '@/rtkApi/mddApiSliceGenerated'

import {
	getTempModalInitValues,
	isDisabled,
	isLockedByCurrentUser,
} from '../helpers'
import { TemplateForm } from './TemplateForm'
import { TemplateFormValues, TemplateModalProps } from './types'

export const TemplateModal = ({
	onOpenConfirmModal,
	onSubmit,
	isCreateModal = false,
	templateRowData,
	textAreaHeight,
	isConfirmModalVisible,
	setIsConfirmModalVisible,
	onCloseParentModal,
}: TemplateModalProps) => {
	const { t } = useAppContext()
	const refForm = useRef<Form<TemplateDto>>(null)
	const [unlockOnCancel, setUnlockOnCancel] = useState<boolean>(false)

	const [lockTemplate] = useLockMutation()
	const [unlockTemplate] = useUnlockMutation()
	const [pushTemplate] = usePushMutation()

	const { user } = useAppSelector((state) => state.auth)

	const { data } = useGetTemplateQuery(
		{
			id: templateRowData?.id as number,
		},
		{
			skip: !templateRowData?.id,
		},
	)

	const handleUnlockOnCancel = () => setUnlockOnCancel(!unlockOnCancel)

	const handleConfirmClose = () => {
		if (unlockOnCancel) {
			handleUnlock()
		}

		setIsConfirmModalVisible?.(false)
		onCloseParentModal?.()
	}

	const pushHandler = async () => {
		if (!templateRowData?.id) return

		if (refForm.current) {
			const currentFormValues = refForm.current.getValues()
			if (currentFormValues) {
				onSubmit(currentFormValues as TemplateFormValues)
			}
		}

		await pushTemplate({ templateId: templateRowData.id, unlock: true })
		onOpenConfirmModal()
	}

	const isLockedByThisUser = isLockedByCurrentUser(
		templateRowData as TemplateListDto,
		user,
	)
	const isTemplateLocked = Boolean(templateRowData?.lockedBy)

	const handleLock = async () => {
		if (isTemplateLocked) return
		await lockTemplate({ templateId: templateRowData?.id as number })
	}
	const handleUnlock = async () => {
		await unlockTemplate({ templateId: templateRowData?.id as number })
		onOpenConfirmModal()
	}

	const initialValues = getTempModalInitValues(data)

	const tabs = useMemo((): TabProps[] => {
		return [
			{
				id: TemplateTab.Form,
				title: 'Form',
				content: (
					<TemplateForm
						formData={data}
						template={templateRowData}
						textAreaHeight={textAreaHeight}
					/>
				),
			},
		]
	}, [])

	const modalHeader = () => (
		<>{templateRowData ? t('TEMPLATE_UPDATE') : t('TEMPLATE_NEW')}</>
	)

	useEffect(() => {
		refForm.current?.setValues(initialValues)
	}, [initialValues])

	useEffect(() => {
		if (templateRowData?.id) {
			handleLock()
		}
	}, [templateRowData?.id])

	useEffect(() => {
		const handleEsc = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				onOpenConfirmModal
			}
		}
		document.addEventListener('keypress', handleEsc)

		return () => {
			document.removeEventListener('keypress', handleEsc)
		}
	}, [])

	return (
		<>
			<ModalForm<TemplateDto>
				open
				onClose={onOpenConfirmModal}
				contentStyle={{
					width: 1000,
					minWidth: 1000,
				}}
				bodyStyle={{
					display: 'flex',
					flexDirection: 'column',
				}}
				resizable
				hasPushButton={!!templateRowData}
				formRef={refForm}
				header={modalHeader}
				onSubmit={onSubmit as (values: TemplateDto) => void | Promise<void>}
				onPush={pushHandler}
				disabled={isDisabled({
					isCreateModal,
					isTemplateLocked,
					isLockedByCurrentUser: isLockedByThisUser,
				})}
			>
				<Tabs tabs={tabs} />
			</ModalForm>

			<TemplateConfirmModal
				onConfirmClose={handleConfirmClose}
				handleUnlockOnCancel={handleUnlockOnCancel}
				unlockOnCancel={unlockOnCancel}
				isOpen={isConfirmModalVisible}
			/>
		</>
	)
}
