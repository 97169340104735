import {
	createMRTColumnHelper,
	MRT_ColumnDef,
	MRT_RowData,
} from 'material-react-table'
import { useMemo } from 'react'

import {
	CustomSelectOption,
	getMrtTableDisabled,
	type MrtCellProps,
	RowActionsCell,
	ScriptModalCell,
	SelectCell,
	TextCell,
	TextModalCell,
} from '@/components'
import {
	getAvailableStructureForSourceObject,
	getExistingStructuresForSourceMapping,
} from '@/endpoints'
import { useApi } from '@/endpoints/hooks'
import { SourceEnums, SourceItem } from '@/endpoints/schemas'
import { useAppContext } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { useGetSystemUsersQuery } from '@/rtkApi'
import { TableSelectOptions } from '@/types'
import { getAvailableStructures } from '@/utils/source/getAvailableStructures'
import { useSourceOptions } from '@/utils/source/useSourceOptions'

export const useSourceItemColumns = <TData extends MRT_RowData>({
	cellLoading,
	deleteRow,
	updateCellValue,
}: MrtCellProps<TData>) => {
	const { t } = useAppContext()

	const {
		state: { node, systemNodeId, editMode },
	} = useDetailTabContext()

	const systemTables = useApi(
		editMode
			? getAvailableStructureForSourceObject(Number(node.id), 'SYSTEM')
			: getExistingStructuresForSourceMapping(Number(node.id), 'SYSTEM'),
	)

	const { data: systemUsers, isLoading: isSystemUserLoading } =
		useGetSystemUsersQuery({
			structureId: systemNodeId,
		})

	const { sourceObjectTypes, sourceStereotypes } = useSourceOptions()

	const columnHelper = createMRTColumnHelper<SourceItem>()

	const sourceItemProperties = useMemo(
		() => [
			columnHelper.accessor('stereotype', {
				header: t('MAPPING_SOURCES_STEREOTYPE'),
				size: 90,
				meta: {
					selectOptions: sourceStereotypes || [],
					optionValue: 'value',
					optionLabel: 'label',
					isNumeric: true,
				},
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<SourceItem>({
						editMode,
						row,
						column,
					})

					return (
						<SelectCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('objectType', {
				header: t('MAPPING_SOURCES_OBJECT_TYPE'),
				size: 90,
				meta: {
					selectOptions: sourceObjectTypes || [],
					optionValue: 'value',
					optionLabel: 'label',
					isNumeric: true,
				},
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<SourceItem>({
						editMode,
						row,
						column,
					})

					return (
						<SelectCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('objectId', {
				header: t('MAPPING_SOURCES_OBJECT'),
				size: 150,
				minSize: 150,
				meta: {
					selectOptions: (row) =>
						getAvailableStructures(systemTables.data, row),
					optionValue: 'structureId',
					optionLabel: 'fullPath',
					isNumeric: true,
					customSelectOptions: (props) => <CustomSelectOption {...props} />,
				},
				Cell: ({ cell, table, row, column }) => {
					const isCellLoading =
						cellLoading?.rowIndex === row.index &&
						cellLoading?.columnId === column.id
					const isLoading = systemTables.loading || isCellLoading

					const isDisabled = getMrtTableDisabled<SourceItem>({
						editMode,
						row,
						column,
					})

					return (
						<SelectCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							isLoading={isLoading}
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('ownerId', {
				header: t('NODE_OWNER'),
				size: 100,
				meta: {
					selectOptions: (systemUsers as TableSelectOptions<SourceItem>) || [],
					optionValue: 'id',
					optionLabel: 'name',
					isLoading: isSystemUserLoading,
					isNumeric: true,
				},
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<SourceItem>({
						editMode,
						row,
						column,
					})

					return (
						<SelectCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('objectCustom', {
				header: t('SOURCE_SUBSELECT'),
				size: 90,
				meta: {
					isDisabled: (row) =>
						row.original.objectType !== SourceEnums.ObjectTypeEnum.SUB_SELECT,
				},
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<SourceItem>({
						editMode,
						row,
						column,
					})

					return (
						<ScriptModalCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('alias', {
				header: t('MAPPING_SOURCES_ALIAS'),
				size: 90,
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<SourceItem>({
						editMode,
						row,
						column,
					})

					return (
						<TextCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('expression', {
				header: t('MAPPING_SOURCES_EXPRESSION'),
				size: 120,
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<SourceItem>({
						editMode,
						row,
						column,
					})
					return (
						<ScriptModalCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('sourceContext', {
				header: t('MAPPING_SOURCES_SOURCE_CONTEXT'),
				size: 120,
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<SourceItem>({
						editMode,
						row,
						column,
					})
					return (
						<TextModalCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('rightAction', {
				header: 'Actions',
				size: 60,
				meta: {
					hideFilter: true,
				},
				enableColumnActions: false,
				enableSorting: false,
				Cell: ({ table, row }) => (
					<RowActionsCell deleteRow={deleteRow} table={table} row={row} />
				),
			}),
		],
		[
			columnHelper,
			t,
			sourceStereotypes,
			sourceObjectTypes,
			systemUsers,
			isSystemUserLoading,
			editMode,
			updateCellValue,
			systemTables.data,
			systemTables.loading,
			cellLoading?.rowIndex,
			cellLoading?.columnId,
			deleteRow,
		],
	)

	return sourceItemProperties as MRT_ColumnDef<SourceItem>[]
}
