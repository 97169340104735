import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'

import { useAppContext } from '@/hooks'

import { NewCustomAttributesSwitcher } from './NewCustomAttributeSwitcher'
import { type NewCustomAttributesProps } from './types'

export const NewCustomAttributes = <T extends object>({
	$pageType,
	control,
	fields,
}: NewCustomAttributesProps<T>) => {
	const { t } = useAppContext()

	return (
		<section>
			<Typography
				variant="subtitle1"
				className="mb-4 border-b border-solid border-emerald-900"
			>
				{t('CUSTOM_ATTRIBUTES')}
			</Typography>
			{fields?.map((field, index) => (
				<Grid>
					<div className="mb-4" key={field.id}>
						<NewCustomAttributesSwitcher
							field={field}
							$pageType={$pageType}
							control={control}
							index={index}
						/>
					</div>
				</Grid>
			))}
		</section>
	)
}
