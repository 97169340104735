import { formFieldSwitch } from '@/components'
import { ItemPropertyType } from '@/enums'
import { cn } from '@/utils/cn'

// Helper to generate a class name for unavailable options
export const getUnavailableOptionClass = (
	columnCode: string | undefined,
	invalidColumnCodes: string[] | undefined,
): string => {
	const isInvalid = invalidColumnCodes?.includes(columnCode || '')
	return cn({
		'underline decoration-wavy text-red-600': isInvalid,
	})
}

export const fieldTypeRender = (fieldType: ItemPropertyType) => {
	// Make sure fieldType is a valid ItemPropertyType
	const safeFieldType = fieldType as ItemPropertyType

	return (
		formFieldSwitch[safeFieldType] ||
		(() => <div>Unsupported field type: {fieldType}</div>)
	)
}
