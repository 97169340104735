import {
	MaterialReactTableProps,
	MRT_ColumnDef,
	useMaterialReactTable,
} from 'material-react-table'
import { useMemo } from 'react'

type UseMRTTableInstanceProps<TData extends object> = {
	columns: MRT_ColumnDef<TData>[]
	data: TData[]
	maxHeight?: string
	maxWidth?: string
	options?: Partial<MaterialReactTableProps<TData>>
	withDefaults?: boolean
}

/**
 * Flexible hook to create an MRT Table instance.
 * - `withDefaults`: When `true` (default), includes common default table settings for MRT.
 * - Developers can override or skip defaults for full control.
 */
export const useTable = <TData extends object>({
	columns,
	data,
	options,
	withDefaults = true,
	maxHeight = '800px',
	maxWidth = '100%',
}: UseMRTTableInstanceProps<TData>) => {
	// Define default options for MRT
	const defaultOptions: Partial<MaterialReactTableProps<TData>> = withDefaults
		? {
				columns,
				data,
				enableBottomToolbar: true,
				enableColumnFilters: true,
				enableColumnPinning: true,
				enablePagination: false,
				enableRowOrdering: true,
				enableSorting: true,
				enableStickyHeader: true,
				enableTopToolbar: false,
				muiTablePaperProps: {
					sx: {
						padding: '1rem 0.5rem;',
						elevation: 0,
					},
				},
				muiTableHeadProps: {
					sx: { zIndex: 20 }, // z-index for the header
				},
				muiTableBodyProps: {
					sx: {
						zIndex: 5,
						//stripe the rows, make odd rows a darker color
						'& tr:nth-of-type(odd) > td': {
							backgroundColor: '#f5f5f5',
						},
					},
				},
				muiTableBodyRowProps: {
					sx: {
						'&:hover': {
							backgroundColor: '#ecfdf5 !important', // Custom hover color
						},
					},
				},
				muiTableContainerProps: { sx: { maxHeight, maxWidth } },
				renderBottomToolbar: false,
			}
		: {}

	// Merge default options with any user-defined options
	const tableOptions = useMemo(
		() => ({
			...defaultOptions,
			...options, // Allow developers to overwrite defaults via options
			columns,
			data,
		}),
		[columns, data, options, defaultOptions],
	)

	return useMaterialReactTable(tableOptions)
}
