import { ChangeEventHandler } from 'react'

import { cn } from '@/utils/cn'

import { StyledLabel } from './styles'

interface NewCheckboxProps {
	checked?: boolean
	className?: string
	disabled?: boolean
	label?: string
	name?: string
	onBlur?: ChangeEventHandler<HTMLInputElement>
	onChange?: ChangeEventHandler<HTMLInputElement>
}

export const NewCheckBox = ({
	checked,
	className,
	disabled,
	label,
	name,
	onChange,
	onBlur,
}: NewCheckboxProps) => {
	return (
		<div
			className={cn(
				'flex items-center justify-center w-full h-full',
				className,
			)}
		>
			<StyledLabel className="form-control" disabled={disabled}>
				<input
					className="cursor-pointer"
					type="checkbox"
					name={name}
					checked={checked}
					disabled={disabled}
					onChange={onChange}
					onBlur={onBlur}
				/>
				{label}
			</StyledLabel>
		</div>
	)
}
