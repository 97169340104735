import { slice, uniqBy } from 'lodash'
import { useMemo, useState } from 'react'
import { createRelation, searchStructure, updateRelation } from 'src/endpoints'

import { CodeInput } from '@/components'
import { ModalForm } from '@/components/Modal/ModalForm'
import {
	FormField,
	SelectFormField,
	TextAreaFormField,
	TextFormField,
} from '@/components/UberForm'
import { useApi, useApiRequest } from '@/endpoints/hooks'
import { useAppContext, useDebounce } from '@/hooks'
import { RelationDto, StructureDto } from '@/rtkApi/mddApiSliceGenerated'
import { enumToValueLabelCollection } from '@/utils/collections'

import { SearchEnum } from '../types'

type DependenciesModalProps = {
	dependency?: Partial<RelationDto>
	node: StructureDto
	onClose: () => void
	onSave: () => void
}

export const DependenciesModal = ({
	node,
	dependency,
	onSave,
	onClose,
}: DependenciesModalProps) => {
	const { t } = useAppContext()
	const request = useApiRequest()

	const [search, setSearch] = useState(
		dependency?.rightSideStructure?.name ?? '',
	)

	const [searchType, setSearchType] = useState(
		dependency?.rightSideStructure?.type ?? 'TABLE',
	)

	const searchDebounced = useDebounce(search, 300)

	const [name, setName] = useState(dependency?.name ?? '')

	const searchStructures = useApi(
		searchStructure(searchDebounced, {
			nodeType: searchType as 'TABLE',
		}),
	)

	const handleSubmit = async (form: Partial<RelationDto>) => {
		form['searchElementType' as keyof RelationDto] = undefined

		const searchStructure = searchStructures.data?.find(
			(structure) => structure.structureId === form.rightSideStructure,
		)

		form.rightSideStructure = { id: searchStructure?.structureId }

		let action

		if (dependency && dependency.id) {
			action = updateRelation(node.id, dependency.id, {
				body: form,
			})
		} else {
			action = createRelation(node.id, {
				body: form,
			})
		}

		const result = await request(action)

		if (result) {
			onSave()
			onClose()
		}
	}

	const elements = useMemo(() => {
		const elements = slice(searchStructures.data || [], 0, 30)

		if (dependency?.rightSideStructure) {
			return uniqBy(
				elements.concat({
					structureId: dependency.rightSideStructure?.id,
					fullPath: dependency.rightSideStructure?.fullPath,
				}),
				(value) => value.structureId,
			)
		}

		return elements
	}, [dependency, searchStructures.data])

	return (
		<>
			<ModalForm<
				RelationDto & { rightSideStructure: number; searchElementType: string }
			>
				open={true}
				onClose={onClose}
				contentStyle={{
					width: '550px',
				}}
				initialValues={
					dependency
						? {
								...dependency,
								searchElementType: searchType,
								rightSideStructure: dependency.rightSideStructure?.id,
							}
						: { searchElementType: searchType }
				}
				header={dependency ? t('UPDATE_DEPENDENCY') : t('CREATE_DEPENDENCY')}
				onSubmit={handleSubmit}
			>
				<TextFormField
					title={t('NAME')}
					name="name"
					required
					onChange={(v) => setName(v as string)}
				/>
				<FormField name="code" title={t('CODE')}>
					<CodeInput value={name} />
				</FormField>
				<TextAreaFormField title={t('DESCRIPTION')} name="description" />
				<SelectFormField
					title={t('SEARCH_ELEMENT_TYPE')}
					name="searchElementType"
					options={enumToValueLabelCollection(SearchEnum)}
					required
					clearable={false}
					onChange={(value) => setSearchType(value)}
				/>
				<SelectFormField
					title={t('ELEMENT')}
					name="rightSideStructure"
					options={elements}
					fieldIsLoading={searchStructures.loading}
					valueKey="structureId"
					required
					labelKey="fullPath"
					clearable={false}
					onInputChange={(search) => {
						if (search) {
							setSearch(search)
						}
					}}
					isNumeric
					placeholder={t('SEARCH')}
				/>
			</ModalForm>
		</>
	)
}
