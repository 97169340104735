import { format, parseISO } from 'date-fns'
import { MaterialReactTable } from 'material-react-table'

import { PageWrap } from '@/components'
import { useTable } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { useGetAllNodeHistoryQuery } from '@/rtkApi'
import { HistoryDto } from '@/rtkApi/mddApiSliceGenerated'

import { useVersionsTableColumns } from './hooks'

export const NewVersionsTab = () => {
	const {
		state: { node },
	} = useDetailTabContext()

	const { data: tableData, isLoading } = useGetAllNodeHistoryQuery({
		structureId: node.id,
	})

	const dataWithParsedDate = tableData?.map((item) => {
		const parsedDate = parseISO(item.created || '')
		return {
			...item,
			created: format(parsedDate, 'dd.MM.yyyy HH:mm'),
		}
	})

	const columns = useVersionsTableColumns()

	const table = useTable<HistoryDto>({
		columns,
		data: dataWithParsedDate || [],
		options: {
			initialState: {
				density: 'compact',
			},
			enableRowOrdering: false,
			state: {
				isLoading,
			},
			muiSkeletonProps: {
				animation: 'pulse',
				height: 28,
			},
			enableTopToolbar: true,
			enableGlobalFilter: true,
			renderTopToolbar: false,
		},
	})

	return (
		<PageWrap>
			<MaterialReactTable table={table} />
		</PageWrap>
	)
}
