import {
	TemplateDtoRead,
	TemplateListDto,
	UserDto,
} from '@/generated-sources/mddApi'

import { type TemplateFormValues } from './components'

export const getTempModalInitValues = (data: TemplateDtoRead | undefined) => ({
	technologyId: data?.technology?.id,
	stereotypeId: data?.stereotype?.id,
	structureType: data?.structureType,
	comment: data?.comment,
	description: data?.description,
	fileExtension: data?.fileExtension,
	data: data?.data,
	formatType: data?.formatType,
	objectSettingsId: data?.objectSettings?.id,
	version: data?.version,
})

interface IsDisabled {
	isCreateModal: boolean
	isLockedByCurrentUser: boolean
	isTemplateLocked: boolean
}

export const isDisabled = ({
	isCreateModal,
	isLockedByCurrentUser,
	isTemplateLocked,
}: IsDisabled) => {
	if (isCreateModal) return false

	const areLockStatesUndefined =
		isTemplateLocked === undefined && isLockedByCurrentUser === undefined
	const isLockedNotByCurrentUser = isTemplateLocked && !isLockedByCurrentUser

	if (areLockStatesUndefined) return false
	if (isLockedNotByCurrentUser) return true

	return !isTemplateLocked
}

export const isLockedByCurrentUser = (
	tableRow: TemplateListDto,
	user: UserDto | null,
) => !tableRow?.lockedBy || user?.id === tableRow?.lockedBy?.id

export const handleTemplateOperation = async (
	formValues: TemplateFormValues,
	onClose: () => void,
	options: {
		createTemplate?: any
		isCreate: boolean
		templateRowData?: TemplateListDto
		updateTemplate?: any
	},
) => {
	const {
		data,
		description,
		technologyId,
		comment,
		stereotypeId,
		objectSettingsId,
		structureType,
		formatType,
		fileExtension,
	} = formValues

	const { isCreate, createTemplate, updateTemplate, templateRowData } =
		options || {}

	// Build the shared payload logic
	const payload = {
		data,
		description,
		technology: {
			id: technologyId,
		},
		stereotype: {
			id: stereotypeId,
		},
		objectSettings: objectSettingsId ? { id: objectSettingsId } : null,
		comment,
		structureType,
		formatType,
		fileExtension,
	}

	if (isCreate) {
		createTemplate({ templateDto: payload }).unwrap()
		onClose()
	} else if (templateRowData?.id) {
		await updateTemplate({
			id: templateRowData.id,
			templateDto: payload,
		}).unwrap()
		onClose()
	}
}
