import { MaterialReactTable } from 'material-react-table'
import { useCallback, useEffect } from 'react'

import { muiRowDragHandleProps, TableToolbar } from '@/components'
import { PageWrap } from '@/components/PageWrap/PageWrap'
import { newPermissionPayload } from '@/constants'
import { MappingPermission } from '@/endpoints/schemas'
import { useAppStore, useTable, useTableHandlers } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { permissionUpdateCellValue } from '@/pages/User/pages/Home/helpers'
import { useGetSystemUsersQuery } from '@/rtkApi'
import { SystemUser } from '@/rtkApi/mddApiSliceGenerated'
import { saveMapping, updateMapping } from '@/store/modules/mapping/actions'
import { normalizeTableData } from '@/utils'

import { usePermissionsColumns } from './Properties'

export const Permissions = () => {
	const {
		state: { node, systemNodeId },
	} = useDetailTabContext()

	const MAX_TABLE_WIDTH = '800px'
	const mappings = useAppStore((state) => state.mapping.mappings)
	const mappingsData = mappings[node.id ?? 0]

	const systemUsers = useGetSystemUsersQuery({ structureId: systemNodeId })

	const {
		isUserInteracting,
		setTableData,
		tableData,
		updateTableData,
		deleteRow,
		updateCellValue,
	} = useTableHandlers<MappingPermission>({
		initialData: [],
		reduxActions: {
			saveAction: saveMapping,
			updateAction: updateMapping,
			updateKey: 'objectPermissions',
		},
		customUpdateCellValue: useCallback(
			(rowIndex: number, columnId: keyof MappingPermission, value: unknown) =>
				permissionUpdateCellValue<MappingPermission>({
					rowIndex,
					columnId,
					value,
					updateTableData,
					isUserInteracting,
					systemUsers: systemUsers.data as SystemUser[],
				}),
			[systemUsers.data],
		),
	})

	const columns = usePermissionsColumns({ deleteRow, updateCellValue })

	const table = useTable<MappingPermission>({
		columns,
		data: tableData,
		options: {
			initialState: {
				density: 'compact',
			},
			renderBottomToolbar: () => {
				return (
					<TableToolbar<MappingPermission>
						updateTableData={updateTableData}
						newRowPayload={newPermissionPayload}
						tableData={tableData}
					/>
				)
			},
			muiRowDragHandleProps: ({ table }) =>
				muiRowDragHandleProps({ updateTableData, table }),
		},
		maxWidth: MAX_TABLE_WIDTH,
	})

	useEffect(() => {
		if (!mappingsData) return
		setTableData(
			normalizeTableData<MappingPermission>(
				mappingsData?.form.objectPermissions,
			),
		)
	}, [mappingsData])

	return (
		<PageWrap
			maxWidth={MAX_TABLE_WIDTH}
			onMouseEnter={() => {
				isUserInteracting.current = true
			}}
		>
			<MaterialReactTable table={table} />
		</PageWrap>
	)
}
