import { ReactNode, useCallback, useState } from 'react'

import { TabsBar, TabTitle, TabTitleIcon } from './styles'
import { cn } from '@/utils/cn'

export interface TabProps {
	content: ReactNode
	icons?: ReactNode
	id: string
	isDevMode?: boolean
	title: string
}

interface TabsProps {
	className?: string
	onChange?: (tab: TabProps) => void
	selectedTabId?: string
	tabs: TabProps[]
	hasNewTable?: boolean
}

export const Tabs = ({
	tabs,
	onChange,
	selectedTabId,
	hasNewTable,
}: TabsProps) => {
	const [selectedTab, setSelectedTab] = useState(0)

	const handleChange = useCallback(
		(index: number) => {
			setSelectedTab(index)

			if (onChange) {
				onChange(tabs[index])
			}
		},
		[onChange, tabs],
	)

	const isSelected = useCallback(
		(index: number, tab: TabProps) =>
			(selectedTabId && selectedTabId === tab.id) ||
			(selectedTabId === undefined && index == selectedTab),
		[selectedTabId, selectedTab],
	)

	const tabsContainerStyles = cn('flex', 'flex-col', 'flex-grow', {
		'overflow-auto': !hasNewTable,
	})

	const tabsContentStyles = cn('flex-grow', 'content-start', {
		'overflow-auto': !hasNewTable,
	})

	return (
		<div className={tabsContainerStyles}>
			<TabsBar role="tablist">
				{tabs.map((tab, i) => {
					const selected = isSelected(i, tab)

					return (
						<TabTitle
							role="tab"
							key={tab.title}
							onClick={() => handleChange(i)}
							$isSelected={selected}
							aria-selected={selected}
						>
							<span>{tab.title}</span>
							{tab.icons && <TabTitleIcon>{tab.icons}</TabTitleIcon>}
						</TabTitle>
					)
				})}
			</TabsBar>
			<div className={tabsContentStyles} role="tabpanel">
				{tabs.map((tab, i) => isSelected(i, tab) && tab.content)}
			</div>
		</div>
	)
}
