import { Loader } from '@/components'
import { SqlContent } from '@/pages/User/pages/Home/components/Deployments/components/SqlContent'
import { GeneratedSqlDto } from '@/rtkApi/mddApiSliceGenerated'

interface ModalContentProps {
	loading: boolean
	sql: GeneratedSqlDto | null
}

export const ModalContent = ({ loading, sql }: ModalContentProps) => {
	return loading ? <Loader loaded={false} /> : <SqlContent sql={sql} />
}
