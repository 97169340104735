import { useCallback, useState } from 'react'

import { useParentTableColumns } from '@/pages/User/pages/Home/pages/MappingDetail/pages/Columns/hooks'
import { type UsePanelPropertiesArgs } from '@/types'

export const usePanelProperties = ({
	setColumnVisibility,
	columnsHiddenOnOpen,
	columnsShownOnClose = columnsHiddenOnOpen,
}: UsePanelPropertiesArgs) => {
	const [isPanelOpen, setIsPanelOpen] = useState(false)
	const [invalidColumnCodes, setInvalidColumnCodes] = useState<string[]>([])

	const parentTableColumns = useParentTableColumns()

	const handleOpenPanel = useCallback(() => {
		setIsPanelOpen(true)
		setColumnVisibility((prevState) => {
			if (!prevState) return prevState

			const updatedVisibility = { ...prevState }
			columnsHiddenOnOpen.forEach((col) => {
				updatedVisibility[col] = false
			})

			return updatedVisibility
		})
	}, [setColumnVisibility, columnsHiddenOnOpen])

	const handleClosePanel = useCallback(() => {
		setIsPanelOpen(false)
		setColumnVisibility((prevState) => {
			if (!prevState) return prevState

			const updatedVisibility = { ...prevState }
			columnsShownOnClose.forEach((col) => {
				updatedVisibility[col] = true
			})

			return updatedVisibility
		})
	}, [setColumnVisibility, columnsShownOnClose])

	return {
		handleClosePanel,
		handleOpenPanel,
		invalidColumnCodes,
		isPanelOpen,
		parentTableColumns,
		setInvalidColumnCodes,
	}
}
