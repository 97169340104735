import { Box, Drawer, Tab, Tabs, Typography } from '@mui/material'

import { DRAWER_WIDTH } from '@/constants'
import { DiagramTab } from '@/enums'
import { useAppContext, useAppSelector } from '@/hooks'

import { useDiagramContext } from '../../context'
import { DiagramPropertiesPanelProps } from '../../types'
import { DiagramProperties } from './DiagramProperties'
import { LinkProperties } from './LinkProperties'
import { NodeProperties } from './NodeProperties'
import { MuiTabPanel } from '@/components'
import { a11yProps } from '@/components/MuiTab/helpers'

export const DiagramPropertiesPanel = ({
	isPanelOpen,
}: DiagramPropertiesPanelProps) => {
	const { t } = useAppContext()
	const { diagramRef, node, selectedTab, setSelectedTab } = useDiagramContext()
	const inspector = useAppSelector(
		(state) => state.diagram.diagrams[node?.id as number].form.graph.inspector,
	)

	const handleChange = (event: any, newValue: DiagramTab) => {
		setSelectedTab?.(newValue)
	}

	return (
		<Drawer
			sx={{
				position: 'absolute',
				top: 0,
				right: 0,
				height: '100%',
				width: DRAWER_WIDTH,
				flexShrink: 0,
				'& .MuiDrawer-paper': {
					width: DRAWER_WIDTH,
					position: 'absolute',
					height: '100%',
				},
			}}
			variant="persistent"
			anchor="right"
			open={isPanelOpen}
		>
			<Box
				sx={{
					padding: '10px 0 0 15px',
					borderBottom: 1,
					borderColor: 'divider',
				}}
			>
				<Typography variant="h6" component="div">
					Properties
				</Typography>
			</Box>

			<Tabs
				value={selectedTab}
				onChange={handleChange}
				aria-label="basic tabs example"
			>
				<Tab label="Diagram" {...a11yProps(0)} />
				<Tab label="Table" {...a11yProps(1)} />
				<Tab label="Link" {...a11yProps(2)} />
			</Tabs>

			<MuiTabPanel value={selectedTab as number} index={0}>
				<DiagramProperties
					diagramRef={diagramRef}
					nodeId={node?.id as number}
				/>
			</MuiTabPanel>
			<MuiTabPanel value={selectedTab as number} index={1}>
				{inspector?.inspectedData && inspector?.type === 'node' ? (
					<NodeProperties
						diagramRef={diagramRef}
						propertiesWidth={DRAWER_WIDTH}
						nodeId={node?.id as number}
					/>
				) : (
					<p>{t('DIAGRAM_NODE_PROPERTIES_TEXT')}</p>
				)}
			</MuiTabPanel>
			<MuiTabPanel value={selectedTab as number} index={2}>
				{inspector?.inspectedData && inspector?.type === 'link' ? (
					<LinkProperties
						diagramRef={diagramRef}
						propertiesWidth={DRAWER_WIDTH}
						nodeId={node?.id as number}
					/>
				) : (
					<p>{t('DIAGRAM_LINK_PROPERTIES_TEXT')}</p>
				)}
			</MuiTabPanel>
		</Drawer>
	)
}
