interface HoverableCellProps {
	className?: string
	onclick: ((rowOriginal: any) => void) | undefined
	value: string
}

export const HoverableCell = ({
	className,
	onclick,
	value,
}: HoverableCellProps) => {
	return (
		<div
			onClick={onclick}
			className={`relative group flex flex-1 ${className}`}
		>
			{/* ReadOnly Input */}
			<input
				style={{ border: 'none' }}
				type="text"
				value={value}
				readOnly
				className="w-full p-2 cursor-pointer"
			/>
		</div>
	)
}
