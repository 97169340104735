import { Action } from '@reduxjs/toolkit'
import debounce from 'debounce'
import { current } from 'immer'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useImmer } from 'use-immer'

import { useAppDispatch } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { StructureDto } from '@/rtkApi/mddApiSliceGenerated'
import { AppDispatch, RootState } from '@/store'
import { UpdateDeepPartial } from '@/store/utils'
import { ColumnVisibility } from '@/types'
import { normalizeTableData } from '@/utils'

export type UpdateCellValueHandler<T> = (
	rowIndex: number,
	columnId: keyof T,
	value: unknown,
) => void

export type CustomDeleteRowHandler = (
	deletedRowId: number | string,
	defaultDelete: () => void,
) => void

export interface UseTableHandlersProps<TData> {
	columnInitVisibility?: ColumnVisibility
	customDeleteRow?: CustomDeleteRowHandler
	customUpdateCellValue?: UpdateCellValueHandler<TData>
	debounceTime?: number
	initialData: TData[]
	reduxActions: {
		saveAction: (
			node: StructureDto,
		) => (dispatch: AppDispatch, getState: () => RootState) => Promise<unknown>
		updateAction: (node: StructureDto, update: UpdateDeepPartial<any>) => Action
		updateKey: string
	}
}

export const useTableHandlers = <TData extends { id?: number; uuid?: string }>({
	initialData,
	debounceTime = 500,
	columnInitVisibility = {
		name: true,
	},
	customDeleteRow,
	customUpdateCellValue,
	reduxActions,
}: UseTableHandlersProps<TData>) => {
	const {
		state: { node, editMode },
	} = useDetailTabContext()
	const dispatch = useAppDispatch()
	const [dataKey, setDataKey] = useState(0)
	const [columnVisibility, setColumnVisibility] =
		useState<ColumnVisibility>(columnInitVisibility)
	const [tableData, setTableData] = useImmer(
		normalizeTableData<TData>(initialData),
	)

	const [selectedRow, setSelectedRow] = useState<TData | null>(
		tableData[0] ?? null,
	)

	const isUserInteracting = useRef(false)

	const { saveAction, updateAction, updateKey } = reduxActions

	const saveDebounced = useCallback(
		debounce(async () => {
			try {
				await dispatch(saveAction(node))
			} catch (e) {
				console.error(e)
			}
		}, debounceTime),
		[dispatch, node, saveAction, debounceTime],
	)

	const updateTableData = useCallback(
		(updater: (draft: TData[]) => void) => {
			setTableData((draft) => {
				updater(draft as TData[])
				dispatch(updateAction(node, { [updateKey]: current(draft) }))
			})
			saveDebounced()

			// Trigger table re-render by incrementing the key
			setDataKey((key) => key + 1)
		},
		[node, updateAction],
	)

	const defaultUpdateCellValue = useCallback(
		(rowIndex: number, columnId: keyof TData, value: unknown) => {
			// Without this check on first render the logic would assign undefined to some dropdowns
			if (!isUserInteracting.current || value === undefined || value === null)
				return

			updateTableData((draft) => {
				const row = draft[rowIndex]
				if (row && row[columnId] !== value) {
					row[columnId] = value as TData[keyof TData]
				}
			})
		},
		[updateTableData],
	)

	const updateCellValue = useCallback(
		(rowIndex: number, columnId: keyof TData, value: unknown) => {
			if (customUpdateCellValue) {
				customUpdateCellValue(rowIndex, columnId, value)
			} else {
				defaultUpdateCellValue(rowIndex, columnId, value)
			}
		},
		[customUpdateCellValue, defaultUpdateCellValue],
	)

	const updateSelectedRow = useCallback((updatedRow: TData) => {
		updateTableData((draft) => {
			const rowIndex = draft.findIndex((row) => row.id === updatedRow.id)
			if (rowIndex !== -1) {
				draft[rowIndex] = { ...draft[rowIndex], ...updatedRow }
			}
		})
	}, [])

	const defaultDeleteRow = useCallback(
		(deletedRowId: number | string) => {
			if (!isUserInteracting.current) return
			updateTableData((draft) => {
				const idx = draft.findIndex((row) => row.id === deletedRowId)
				if (idx !== -1) draft.splice(idx, 1)
			})
		},
		[updateTableData],
	)

	const deleteRow = useCallback(
		(deletedRowId: number | string) => {
			if (customDeleteRow) {
				customDeleteRow(deletedRowId, () => defaultDeleteRow(deletedRowId))
			} else {
				defaultDeleteRow(deletedRowId)
			}
		},
		[customDeleteRow, defaultDeleteRow],
	)

	const handleRowClick = useCallback((rowOriginal: TData) => {
		setSelectedRow(rowOriginal)
	}, [])

	useEffect(() => {
		if (!editMode) {
			setTableData(initialData)
		}
	}, [editMode])

	return {
		columnVisibility,
		dataKey,
		deleteRow,
		handleRowClick,
		isUserInteracting,
		selectedRow,
		setColumnVisibility,
		setSelectedRow,
		setTableData,
		tableData,
		updateCellValue,
		updateSelectedRow,
		updateTableData,
	}
}
