import { v4 } from 'uuid'

export const newMappingRowPayload = {
	id: -1,
	columnCode: '',
	keyFlag: false,
	updateFlag: false,
	mapping: '',
	mappingDescription: '',
}

export const newSourceItemPayload = {
	id: -1,
	alias: '',
	ownerId: undefined,
	objectId: undefined,
	ownerName: '',
	expression: '',
	objectCode: '',
	objectType: '',
	stereotype: '',
}

export const newPermissionPayload = {
	id: -1,
	permissions: [],
	user: undefined,
}

export const newTableColumnPayload = {
	id: -1,
	uuid: v4(),
	name: '',
}
