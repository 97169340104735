import { uniqBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { getAllBusinessDomains } from 'src/endpoints'

import { Col, Flex, Legend, Placeholder, Row } from '@/components/Layout'
import {
	Form,
	NamingInputFormField,
	SelectFormField,
	TextAreaFormField,
	TextFormField,
} from '@/components/UberForm'
import { FormlessFormField } from '@/components/UberForm/FormField'
import CheckboxFormField from '@/components/UberForm/Helpers/CheckBoxFormField'
import { useCanGenerateNaming } from '@/components/UberForm/Input/Naming/useCanGenerateNaming'
import { useApi } from '@/endpoints/hooks'
import { ObjectSettingsUpdateDto, StructureObjectDto } from '@/endpoints/models'
import {
	IdCodeName,
	PartitioningStrategy,
	TableData,
	TableOverview,
} from '@/endpoints/schemas'
import { PageType, TableMode } from '@/enums'
import {
	useAppContext,
	useAppDispatch,
	useCustomAttributesInitValues,
} from '@/hooks'
import ObjectSettingsControl from '@/pages/User/pages/Home/components/ObjectSettingsControl'
import {
	updateColumnsWithHistoryColumns,
	updateSequenceCode,
	updateStereotypeColumns,
} from '@/store/modules/table/actions'
import { defaultHistoryTableData } from '@/store/modules/table/helpers'
import { MainContentWrap } from '@/styles'
import { NamingDtoTypeEnum, StereotypeTypeEnum } from '@/types'
import { booleanString } from '@/utils/booleans'
import { useTechnologyId } from '@/utils/domain'
import { uniqueNameValidator } from '@/utils/validators'

import { CustomAttributes } from '../../../../components/CustomAttributes/CustomAttributes'
import { FieldSetGap, FieldSetProject } from '../../../../components/FieldSet'
import {
	useResponsibleUsers,
	useStereotypes,
	useStructureStatus,
	useSystemUsers,
} from '../../../../utils'
import { SyncFormField } from '../../components/SyncFormField'
import {
	getPartitionStrategies,
	useEntityTypes,
	useRetentionTimes,
	useStrategies,
} from '../../lists'
import { SyncFormFieldNoPadding, TextFormFieldNoPadding } from '../../styles'
import {
	getIsFieldDisabled,
	getIsFieldDisabledGenerated,
	getNamingTypeCode,
	getSqlType,
} from '../../utils'
import {
	StatusCustomOption,
	StatusCustomSingleValue,
} from './StatusSelectOption'
import { OverviewProps } from './types'

export const Overview = ({
	node,
	data,
	systemNodeId,
	editMode,
	mode,
	onChange,
	unsyncedFields,
	onSync,
	modeForm,
	modeFormTable,
	selectedNodes,
	refForm,
}: OverviewProps) => {
	const { t } = useAppContext()
	const refInit = useRef(false)
	const dispatch = useAppDispatch()
	const technologyId = useTechnologyId(systemNodeId)
	const stereotypes = useStereotypes(StereotypeTypeEnum.TABLE)
	const businessDomains = useApi(getAllBusinessDomains())
	const userList = useResponsibleUsers(modeForm)
	const systemUsers = useSystemUsers(systemNodeId)

	const isHistoryMode = mode === TableMode.HISTORY

	const hasPartitioningColumn =
		modeForm.partitioningStrategy === PartitioningStrategy.KEY ||
		modeForm.partitioningStrategy === PartitioningStrategy.KEY_AND_SNAPSHOT ||
		modeForm.partitioningStrategy ===
			PartitioningStrategy.KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE

	const isKeepingFullStory =
		modeForm.retentionStrategy ===
			TableOverview.RetentionStrategyEnum.KEEP_FULL_HISTORY ||
		modeForm.retentionStrategy ===
			TableOverview.RetentionStrategyEnum.KEEP_FULL_HISTORY_AND_EOM

	const isKeepingFullStoryAndEOM =
		modeForm.retentionStrategy ===
		TableOverview.RetentionStrategyEnum.KEEP_FULL_HISTORY_AND_EOM

	const entityTypes = useEntityTypes(t)
	const nodeStates = useStructureStatus(t)
	const strategies = useStrategies(t)
	const retentionTimes = useRetentionTimes(t)
	const partitionStrategies = getPartitionStrategies(isHistoryMode, t)

	const canGenerateNaming = useCanGenerateNaming(node?.id)

	const {
		initialValuesCustomAttributes,
		getCustomAttributesReset,
		parseCustomAttribute,
		customAttributes,
	} = useCustomAttributesInitValues(
		data.form,
		StructureObjectDto.TypeEnum.TABLE,
		mode,
	)

	useEffect(() => {
		refInit.current = false
	}, [mode])

	const handleObjectSettingsChange = (newValue: IdCodeName[]) => {
		onChange({
			objectSettings: newValue,
		})
	}

	const handleChange = useCallback(
		(item: Partial<TableData>, source?: FormlessFormField<TableData>) => {
			if (source) {
				const name = source.props.name

				if (name === 'code' && !refInit.current) {
					if (item.code) {
						refInit.current = true
					}

					return
				}

				refInit.current = true

				if (name === 'stereotypeId' && technologyId) {
					const stereotype = stereotypes.data?.find((s) => s.id === item[name])

					const { customAttributesForm, customAttributesRedux } =
						getCustomAttributesReset(stereotype?.id)

					refForm?.current?.setValues(customAttributesForm)

					onChange({
						stereotypeId: stereotype?.id,
						stereotypeCode: stereotype?.code,
						customAttributes: customAttributesRedux,
					})

					if (selectedNodes) {
						selectedNodes.forEach((node) =>
							dispatch(updateStereotypeColumns(node, technologyId, item[name])),
						)
					} else if (node) {
						dispatch(updateStereotypeColumns(node, technologyId, item[name]))
					}

					if (stereotype?.code === 'reference') {
						onChange({
							hasHistoryTable: false,
							historyTable: defaultHistoryTableData(),
							hasReferenceTable: true,
						})
					} else {
						onChange({
							hasReferenceTable: false,
						})
					}

					if (!stereotype?.children) {
						onChange({
							subStereotypeId: undefined,
						})
					}

					return
				}

				if (name === 'sequenceColumnCode') {
					if (selectedNodes) {
						selectedNodes.forEach((node) =>
							dispatch(
								updateSequenceCode(
									node,
									{ sequenceColumnCode: item.sequenceColumnCode },
									mode,
									[
										NamingDtoTypeEnum.SEQUENCE_NAME,
										node.id,
										{
											sequence_column: item.sequenceColumnCode,
										},
									],
								),
							),
						)
					} else if (node) {
						dispatch(
							updateSequenceCode(
								node,
								{ sequenceColumnCode: item.sequenceColumnCode },
								mode,
								[
									NamingDtoTypeEnum.SEQUENCE_NAME,
									node.id,
									{
										sequence_column: item.sequenceColumnCode,
									},
								],
							),
						)
					}

					onChange({
						[name]: item[name],
					})

					return
				}

				if (name === 'retentionStrategy') {
					if (item[name] === TableOverview.RetentionStrategyEnum.INFINITE) {
						onChange({
							retentionStrategyFullHistoryValue: undefined,
							retentionStrategyFullHistoryUnit: undefined,
							retentionStrategyEomValue: undefined,
							retentionStrategyEomUnit: undefined,
						})
					}

					if (
						item[name] === TableOverview.RetentionStrategyEnum.KEEP_FULL_HISTORY
					) {
						onChange({
							retentionStrategyFullHistoryValue: undefined,
							retentionStrategyFullHistoryUnit: undefined,
						})
					}

					onChange({
						[name]: item[name],
					})

					return
				}

				if (name === 'businessDomainId') {
					const businessDomain = businessDomains.data?.find(
						(d) => d.id === item[name],
					)

					onChange({
						businessDomainId: businessDomain?.id,
						businessDomainName: businessDomain?.name,
					})

					return
				}

				if (name === 'partitioningColumnId') {
					const column = modeForm.columns?.find((d) => d.id === item[name])

					onChange({
						partitioningColumnId: item[name],
						partitioningColumnCode: column?.code,
					})

					return
				}

				if (name === 'responsiblePersonId' || name === 'responsibleAnalystId') {
					const user = userList.data?.find((u) => u.id === item[name])

					const userName =
						name === 'responsiblePersonId'
							? 'responsiblePersonName'
							: 'responsibleAnalystName'

					onChange({
						[name]: user?.id,
						[userName]: user?.value,
					})

					return
				}

				if (name === 'hasHistoryTable' && technologyId) {
					if (selectedNodes) {
						selectedNodes.forEach((node) =>
							dispatch(
								updateColumnsWithHistoryColumns(
									node,
									technologyId,
									item.hasHistoryTable as boolean,
								),
							),
						)
					} else if (node) {
						dispatch(
							updateColumnsWithHistoryColumns(
								node,
								technologyId,
								item.hasHistoryTable as boolean,
							),
						)
					}

					onChange({
						hasHistoryTable: item.hasHistoryTable,
					})

					return
				}

				if (name === 'ownerId') {
					const owner = systemUsers?.data?.find((u) => u.id === item[name])

					onChange({
						ownerId: owner?.id,
						ownerName: owner?.name,
					})

					return
				}

				const parsedCa = parseCustomAttribute(
					{
						[name]: item[name],
					},
					modeForm.customAttributes,
					customAttributes,
				)

				onChange(parsedCa)
			}
		},
		[
			technologyId,
			onChange,
			parseCustomAttribute,
			modeForm.customAttributes,
			modeForm.columns,
			stereotypes.data,
			getCustomAttributesReset,
			selectedNodes,
			node,
			dispatch,
			mode,
			businessDomains.data,
			userList.data,
			systemUsers,
		],
	)

	const namingConfig = useMemo(
		() =>
			node && editMode
				? ([
						getNamingTypeCode(mode),
						node.id,
						getSqlType(mode),
						{
							master_node_code: data.form.code,
							master_node_name: data.form.name,
							body: { data: JSON.stringify(modeForm) },
						},
					] as const)
				: undefined,
		[data.form.code, data.form.name, editMode, mode, modeForm, node],
	)

	const getDefaultValues = () => {
		if (mode === TableMode.HISTORY) {
			return modeForm.HISTORY
		}

		return modeForm
	}

	const defaultValues = { ...getDefaultValues() }

	return (
		<MainContentWrap>
			<Form<TableData>
				onChange={handleChange}
				disabled={!editMode}
				defaultValues={{ ...defaultValues, ...initialValuesCustomAttributes }}
				key={booleanString(editMode)}
				ref={refForm}
				enableFieldHighlight
			>
				<Row>
					<Col size="medium">
						<TextFormField
							title={t('TABLE_GENERAL_NAME')}
							name="name"
							validators={[
								uniqueNameValidator(dispatch, data.original.name, systemNodeId),
							]}
							required
							disabled={
								getIsFieldDisabledGenerated('name', mode, canGenerateNaming) ||
								Boolean(selectedNodes)
							}
						/>
					</Col>
					<Col size="medium">
						<NamingInputFormField
							node={node}
							name="code"
							title={t('TABLE_GENERAL_CODE')}
							callNamingWorkingDataParams={namingConfig}
							disabled={
								getIsFieldDisabledGenerated('code', mode, canGenerateNaming) ||
								Boolean(selectedNodes)
							}
							validators={[
								uniqueNameValidator(dispatch, data.original.code, systemNodeId),
							]}
						/>
					</Col>
					<Col size="medium">
						<SelectFormField
							name="stereotypeId"
							title={t('TABLE_GENERAL_STEREOTYPE')}
							fieldIsLoading={stereotypes.reloading}
							options={stereotypes.data || []}
							valueKey="id"
							labelKey="name"
							isNumeric
							allowEmpty
							disabled={getIsFieldDisabled('stereotypeId', mode)}
						/>
					</Col>
				</Row>
				<Row $alignItems="flex-end">
					<Col size="medium">
						<SyncFormField
							unsyncedFields={unsyncedFields}
							disabled={!editMode}
							field="comment"
							onSync={onSync}
						>
							<TextAreaFormField
								title={t('TABLE_GENERAL_COMMENT')}
								name="comment"
								rows={5}
							/>
						</SyncFormField>
					</Col>
					<Col size="medium">
						<SyncFormField
							unsyncedFields={unsyncedFields}
							disabled={!editMode}
							field="description"
							onSync={onSync}
						>
							<TextAreaFormField
								title={t('TABLE_GENERAL_DESCRIPTION')}
								name="description"
								rows={5}
							/>
						</SyncFormField>
					</Col>
					<Col size="medium">
						<Placeholder $flexGrow={1}>
							<CheckboxFormField
								name="hasHistoryTable"
								title={t('TABLE_GENERAL_HAS_HISTORY_TABLE')}
								isHidden={modeForm.hasReferenceTable || isHistoryMode}
							/>
						</Placeholder>
					</Col>
				</Row>
				<SyncFormField
					unsyncedFields={unsyncedFields}
					disabled={!editMode}
					field="objectSettings"
					onSync={onSync}
				>
					<ObjectSettingsControl
						name="objectSettings"
						label={t('OBJECTS')}
						onChange={handleObjectSettingsChange}
						disabled={!editMode}
						data={modeForm.objectSettings ?? []}
						type={ObjectSettingsUpdateDto.StructureTypeEnum.TABLE}
					/>
				</SyncFormField>
				<FieldSetProject>
					<Legend>{t('TABLE_GENERAL_PROJECT_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="status"
								onSync={onSync}
							>
								<SelectFormField
									name="status"
									title={t('TABLE_GENERAL_TABLE_STATUS')}
									options={nodeStates}
									valueKey="value"
									labelKey="label"
									allowEmpty
									components={{
										SingleValue: StatusCustomSingleValue,
										Option: StatusCustomOption,
									}}
								/>
							</SyncFormField>
						</Col>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="responsiblePersonId"
								onSync={onSync}
							>
								<SelectFormField
									name="responsiblePersonId"
									title={t('RESPONSIBLE_PERSON')}
									options={userList.data || []}
									valueKey="id"
									fieldIsLoading={userList.reloading}
									labelKey="value"
									isNumeric
									allowEmpty
								/>
							</SyncFormField>
						</Col>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="responsibleAnalystId"
								onSync={onSync}
							>
								<SelectFormField
									name="responsibleAnalystId"
									title={t('RESPONSIBLE_ANALYST')}
									options={userList.data || []}
									fieldIsLoading={userList.reloading}
									valueKey="id"
									labelKey="value"
									isNumeric
									allowEmpty
								/>
							</SyncFormField>
						</Col>
					</Row>
				</FieldSetProject>
				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_BUSINESS_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="businessDomainId"
								onSync={onSync}
							>
								<SelectFormField
									name="businessDomainId"
									title={t('TABLE_GENERAL_BUSINESS_DOMAIN')}
									options={businessDomains.data}
									fieldIsLoading={businessDomains.reloading}
									valueKey="id"
									labelKey="name"
									isNumeric
									allowEmpty
								/>
							</SyncFormField>
						</Col>
					</Row>
				</FieldSetGap>
				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_TECHNICAL_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="entityType"
								onSync={onSync}
							>
								<SelectFormField
									name="entityType"
									title={t('TABLE_GENERAL_ENTITY_TYPE')}
									options={entityTypes}
									valueKey="value"
									labelKey="label"
									allowEmpty
								/>
							</SyncFormField>
						</Col>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="partitioningStrategy"
								onSync={onSync}
							>
								<SelectFormField
									title={t('TABLE_PARTITIONING_STRATEGY')}
									name="partitioningStrategy"
									options={partitionStrategies}
									allowEmpty
								/>
							</SyncFormField>
						</Col>
						{hasPartitioningColumn ? (
							<Col size="medium">
								<SyncFormField
									unsyncedFields={unsyncedFields}
									disabled={!editMode}
									field="partitioningColumnId"
									onSync={onSync}
								>
									<SelectFormField
										title={t('TABLE_PARTITIONING_COLUMN')}
										name="partitioningColumnId"
										options={modeFormTable.columns?.filter(
											(c) =>
												!!c.name &&
												!!modeFormTable.constraints?.find(
													(x) => !!x.columns.find((y) => y.code === c.code),
												),
										)}
										valueKey="id"
										labelKey="name"
										isNumeric
										allowEmpty
										disabled={Boolean(selectedNodes)}
									/>
								</SyncFormField>
							</Col>
						) : (
							<Placeholder $flexGrow={1} $flexShrink={1} />
						)}
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="sequenceColumnCode"
								onSync={onSync}
							>
								<SelectFormField
									name="sequenceColumnCode"
									title={t('TABLE_GENERAL_SEQUENCE_COLUMN')}
									options={uniqBy(
										modeFormTable.columns?.filter((c) => !!c?.name),
										(column) => column?.code,
									)}
									disabled={Boolean(selectedNodes)}
									valueKey="code"
									labelKey="name"
									allowEmpty
									clearable
								/>
							</SyncFormField>
						</Col>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="sequenceCode"
								onSync={onSync}
							>
								<TextFormFieldNoPadding
									title={t('TABLE_GENERAL_SEQUENCE')}
									name="sequenceCode"
									type="text"
									isReadOnly={true}
									disabled={getIsFieldDisabled('sequenceCode', mode)}
								/>
							</SyncFormField>
						</Col>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="ownerId"
								onSync={onSync}
							>
								<SelectFormField
									name="ownerId"
									title={t('NODE_OWNER')}
									options={systemUsers.data || []}
									fieldIsLoading={systemUsers.loading}
									valueKey="id"
									labelKey="name"
									isNumeric
									allowEmpty
									clearable
								/>
							</SyncFormField>
						</Col>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="retentionStrategy"
								onSync={onSync}
							>
								<SelectFormField
									name="retentionStrategy"
									title={t('TABLE_GENERAL_TABLE_RETENTION_STRATEGY')}
									options={strategies}
									valueKey="value"
									labelKey="label"
									allowEmpty
								/>
							</SyncFormField>
						</Col>
						{isKeepingFullStory ? (
							<Col size="medium">
								<Flex>
									<SyncFormFieldNoPadding
										unsyncedFields={unsyncedFields}
										disabled={!editMode}
										field="retentionStrategyFullHistoryValue"
										onSync={onSync}
									>
										<TextFormFieldNoPadding
											title={t(
												'TABLE_RETENTION_STRATEGY_KEEP_FULL_HISTORY_LABEL',
											)}
											name="retentionStrategyFullHistoryValue"
											type="number"
											required
											disabled={getIsFieldDisabled(
												'retentionStrategyFullHistoryValue',
												mode,
											)}
										/>
									</SyncFormFieldNoPadding>
									<SyncFormField
										unsyncedFields={unsyncedFields}
										disabled={!editMode}
										field="retentionStrategyFullHistoryUnit"
										onSync={onSync}
									>
										<SelectFormField
											name="retentionStrategyFullHistoryUnit"
											title=" "
											options={retentionTimes}
											valueKey="value"
											labelKey="label"
										/>
									</SyncFormField>
								</Flex>
							</Col>
						) : (
							<Placeholder />
						)}
						{isKeepingFullStoryAndEOM ? (
							<Col size="medium">
								<Flex>
									<SyncFormField
										unsyncedFields={unsyncedFields}
										disabled={!editMode}
										field="retentionStrategyEomValue"
										onSync={onSync}
									>
										<TextFormFieldNoPadding
											title={t(
												'TABLE_RETENTION_STRATEGY_KEEP_FULL_HISTORY_AND_EOM_LABEL',
											)}
											name="retentionStrategyEomValue"
											type="number"
											required
											disabled={getIsFieldDisabled(
												'retentionStrategyEomValue',
												mode,
											)}
										/>
									</SyncFormField>
									<SyncFormField
										unsyncedFields={unsyncedFields}
										disabled={!editMode}
										field="retentionStrategyEomUnit"
										onSync={onSync}
									>
										<SelectFormField
											name="retentionStrategyEomUnit"
											title=" "
											options={retentionTimes}
											valueKey="value"
											labelKey="label"
										/>
									</SyncFormField>
								</Flex>
							</Col>
						) : (
							<Placeholder />
						)}
					</Row>
				</FieldSetGap>
				<CustomAttributes
					customAttributes={customAttributes}
					$pageType={PageType.OVERVIEW_PAGE}
				/>
			</Form>
		</MainContentWrap>
	)
}
