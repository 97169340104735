export enum ItemPropertyType {
	ACE_EDITOR,
	CHECKBOX,
	COLUMNS,
	COLUMNS_WITH_ORDER,
	CUSTOM_JSX,
	FOREIGN_KEY,
	GENERATED_CODE,
	HIDDEN,
	NUMBER,
	OPTION,
	OPTIONS,
	REGEXP_VALIDATED,
	SECRET,
	TEXT,
	TEXTAREA,
}

export enum Position {
	Top,
	Left,
	Bottom,
	BottomLeft,
}

export enum TabName {
	DeploymentPackage = 'DeploymentPackage',
	Diagram = 'Diagram',
}

export enum Environment {
	Cez = 'cez',
	Dev = 'dev',
	Docker = 'docker',
	Drmax = 'drmax',
	Privatbank = 'privatbank',
}

export enum LsKey {
	DeletedNodes = 'DELETED_NODES',
}

export enum PageType {
	COLUMN_PAGE = 'COLUMN_PAGE',
	OVERVIEW_PAGE = 'OVERVIEW_PAGE',
}
