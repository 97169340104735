import {
	type StructureDetailDto,
	type StructureDto,
} from '@/rtkApi/mddApiSliceGenerated'
import { type RootState } from '@/store'
import { type AppDispatch } from '@/store/utils'
import { isEditable } from '@/utils/locking'

import { loadNode } from '../node/general-actions'
import { type TabSession } from '.'
import {
	TAB_CANCEL_EDIT,
	TAB_CHANGE_NAME,
	TAB_CLOSE,
	TAB_CLOSE_CUSTOM,
	TAB_CLOSE_RELATED,
	TAB_EDIT,
	TAB_IMPORT_STATE,
	TAB_OPEN,
	TAB_SELECT,
	TAB_SELECT_CUSTOM,
	TAB_SELECT_TAB,
	TAB_SET_PERMANENT,
	TAB_SET_PROPERTIES_SHOWN,
	TAB_SET_PROPERTIES_WIDTH,
} from './constants'
import { type Actions, type CustomTabProps } from './types'

export const openTab =
	(
		node: StructureDetailDto,
		replace: boolean,
		editMode = false,
		customTabProps?: CustomTabProps,
	) =>
	(dispatch: AppDispatch, getValues: () => RootState): Actions =>
		dispatch({
			type: TAB_OPEN,
			node,
			replace,
			editMode,
			nodes: getValues().node.nodes,
			customTabProps,
		})

export const selectTab = (node: StructureDto): Actions => ({
	type: TAB_SELECT,
	node,
})

export const selectCustomTab = (id: number): Actions => ({
	type: TAB_SELECT_CUSTOM,
	id,
})

export const setTabPermanent = (node: StructureDto): Actions => ({
	type: TAB_SET_PERMANENT,
	node,
})

export const closeTab = (nodeId: number): Actions => ({
	type: TAB_CLOSE,
	nodeId,
})

export const closeCustomTab = (nodeId: number): Actions => ({
	type: TAB_CLOSE_CUSTOM,
	nodeId,
})

export const editTab = (node: StructureDto): Actions => ({
	type: TAB_EDIT,
	node,
})

export const cancelEditingTab = (node: StructureDto): Actions => ({
	type: TAB_CANCEL_EDIT,
	node,
})

export const setTabPropertiesShown = (
	node: StructureDto,
	tabKey: string,
	shown: boolean,
): Actions => ({
	type: TAB_SET_PROPERTIES_SHOWN,
	node,
	tabKey,
	shown,
})

export const setTabPropertiesWidth = (
	node: StructureDto,
	tabKey: string,
	width: number,
): Actions => ({
	type: TAB_SET_PROPERTIES_WIDTH,
	node,
	tabKey,
	width,
})

export const closeTabAndRelated =
	(node: StructureDto) =>
	(dispatch: AppDispatch, getValues: () => RootState): Actions =>
		dispatch({
			type: TAB_CLOSE_RELATED,
			node,
			children: getValues().node.children,
		})

export const loadTabState =
	(session: TabSession) =>
	async (dispatch: AppDispatch, getValues: () => RootState) => {
		const updatedSession = { ...session }
		updatedSession.openedTabs = updatedSession.openedTabs.filter((tab) => !!tab)

		await Promise.all(
			updatedSession.openedTabs.map((tab) =>
				dispatch(loadNode(tab.nodeId))
					.then((data) => {
						// Exit edit mode if we don't have edit rights or we lost the lock
						if (tab.editMode && !isEditable(data, getValues().auth.user)) {
							tab.editMode = false
						}
					})
					.catch(() => {
						console.warn(
							'Node',
							tab.nodeId,
							'specified in user session not found',
						)

						updatedSession.openedTabs = updatedSession.openedTabs.filter(
							(t) => t.nodeId !== tab.nodeId,
						)
					}),
			),
		)

		// Reset selectedTab and temproraryTab indexes when openedTabs size was changed
		if (updatedSession.openedTabs.length !== session.openedTabs.length) {
			updatedSession.selectedTab = undefined
			updatedSession.temporaryTab = undefined
		}

		// Reset selected tab index if out of bounds
		if (
			updatedSession.selectedTab !== undefined &&
			updatedSession.selectedTab >= updatedSession.openedTabs.length
		) {
			updatedSession.selectedTab = undefined
		}

		// Reset temporary tab index if out of bounds
		if (
			updatedSession.temporaryTab !== undefined &&
			updatedSession.temporaryTab >= updatedSession.openedTabs.length
		) {
			updatedSession.temporaryTab = undefined
		}

		dispatch({
			type: TAB_IMPORT_STATE,
			session: updatedSession,
		})
	}

export const selectTabInTab = (nodeId: number, tab: string): Actions => ({
	type: TAB_SELECT_TAB,
	tab,
})

export const changeTabName = (nodeId: number, newName: string): Actions => ({
	type: TAB_CHANGE_NAME,
	nodeId,
	newName,
})
