import { useState } from 'react'

import { useAppContext } from '@/hooks'

import Button from '../../Button/Button'
import Tooltip from '../../Tooltip/Tooltip'

type CopyButtonProps = {
	text: string
}

export const CopyTextButton = ({ text }: CopyButtonProps) => {
	const { t } = useAppContext()
	const [isTooltipShown, setTooltipShown] = useState(false)

	const onCopy = async () => {
		await navigator.clipboard.writeText(text)
		setTooltipShown(true)
		setTimeout(() => setTooltipShown(false), 500)
	}

	return (
		<Tooltip content={t('COPIED')} showOnHover={false} shown={isTooltipShown}>
			<Button schema="default" onClick={onCopy}>
				{t('COPY')}
			</Button>
		</Tooltip>
	)
}
