import { ChipOwnProps } from '@mui/material'

import { ValidationMessageDto } from '@/rtkApi/mddApiSliceGenerated'

import { FilterValues } from './Filter'

export const filterValidationData = (
	data: ValidationMessageDto[],
	filter: FilterValues,
) => {
	const fulltext = filter.fulltext?.toLowerCase()

	return data.filter(
		(x) =>
			(!filter.severity || x.severity === filter.severity) &&
			(!fulltext ||
				x.elementName.toLowerCase().indexOf(fulltext) >= 0 ||
				x.nodeName.toLowerCase().indexOf(fulltext) >= 0 ||
				x.message.toLowerCase().indexOf(fulltext) >= 0 ||
				x.description.toLowerCase().indexOf(fulltext) >= 0),
	)
}

export const getColor = (cellValue: string): ChipOwnProps['color'] => {
	switch (cellValue) {
		case 'WARN':
			return 'warning'
		case null:
		case undefined:
			return 'info'
		default:
			return cellValue?.toLowerCase() as ChipOwnProps['color']
	}
}
