import { createAsyncThunk } from '@reduxjs/toolkit'

import { apiRequest } from '@/endpoints/axiosInstance'
import { StructureDto } from '@/endpoints/models'
import { HttpMethod } from '@/endpoints/utils'
import { RootState } from '@/store'
import { loadNodeOrHistoryVersion } from '@/store/modules/node/utils'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'

export const initDiagram = createAsyncThunk(
	'diagram/initDiagram',
	async ({
		nodeId,
		editMode = false,
		force = false,
		version,
		envId,
	}: InitDataParams) => {
		const node = await loadNodeOrHistoryVersion(nodeId, version, envId)
		return { node, editMode, force }
	},
)

export const updateNodeData = async (nodeId: number, body: any) => {
	try {
		const response = await apiRequest({
			url: `/structure/${nodeId}/data`,
			method: HttpMethod.PUT,
			body,
		})

		if (response.statusText !== 'OK') {
			throw new Error(`HTTP error! status: ${response.status}`)
		}

		return response
	} catch (error) {
		console.error('Error updating node data:', error)
	}
}

export const saveDiagram = createAsyncThunk<
	StructureDto, // Return type of the payload creator
	StructureDto, // First argument to the payload creator
	{ state: RootState } // ThunkAPI configuration
>(
	'diagram/saveDiagram',
	async (node: StructureDto, { getState, rejectWithValue }) => {
		try {
			const state = getState()

			const token = state.auth.token

			const diagram = state.diagram.diagrams[node.id]
			console.log('Retrieved diagram', diagram)

			if (!diagram) {
				return rejectWithValue(
					new Error(`Saving unopened state ${JSON.stringify(node)}`),
				)
			}

			if (!token) return rejectWithValue(new Error('No token'))

			await updateNodeData(node.id, {
				body: JSON.stringify(diagram.form),
			})

			return node
		} catch (error: any) {
			console.error('Error in saveDiagram thunk:', error)
			return rejectWithValue(error)
		}
	},
)
