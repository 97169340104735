import { MaterialReactTable } from 'material-react-table'
import { useMemo } from 'react'

import { PageWrap } from '@/components'
import { RelationDto as RelationDtoOld } from '@/endpoints/models'
import { useAppContext, useTable } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { useFilter } from '@/pages/User/pages/Settings/hooks/useFilter'
import { useGetNodeRelationsQuery } from '@/rtkApi'
import { RelationDto } from '@/rtkApi/mddApiSliceGenerated'
import { enumToLabelCollection } from '@/utils'

import { DependenciesFilter, type DependenciesFilterValues } from './components'
import { useDepColumns, useDependenciesFilteredData } from './hooks'
import { TypeEnum } from './types'

export const NewDependencies = () => {
	const {
		state: { node },
	} = useDetailTabContext()
	const { t } = useAppContext()

	const { filter, onChangeFilter } = useFilter<DependenciesFilterValues>()

	const { data, isLoading } = useGetNodeRelationsQuery({
		structureId: node.id,
	})

	const statusStates = useMemo(
		() =>
			enumToLabelCollection(RelationDtoOld.StatusEnum, 'RELATION_STATUS', t),
		[t],
	)

	const typeStates = useMemo(
		() => enumToLabelCollection(TypeEnum, 'RELATION_TYPE', t),
		[t],
	)

	const dependenciesData = useDependenciesFilteredData(filter, data)

	const columns = useDepColumns()

	const table = useTable<RelationDto>({
		columns,
		data: dependenciesData || [],
		options: {
			initialState: {
				density: 'compact',
			},
			enableRowOrdering: false,
			state: {
				isLoading,
			},
			muiSkeletonProps: {
				animation: 'pulse',
				height: 28,
			},
			enableTopToolbar: true,
			enableGlobalFilter: true,
			renderTopToolbar: false,
		},
	})

	return (
		<PageWrap>
			<DependenciesFilter
				onChangeFilter={onChangeFilter}
				statusStates={statusStates}
				typeStates={typeStates}
			/>
			<MaterialReactTable
				key={JSON.stringify(dependenciesData)}
				table={table}
			/>
		</PageWrap>
	)
}
