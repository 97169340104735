import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'

import { SearchWrap, StyledText } from '@/components/NavBar/styles'
import { useDebounce } from '@/hooks'
import { useSearchFullTextJsonQuery } from '@/rtkApi'
import { SearchFullTextJsonApiResponse } from '@/rtkApi/mddApiSliceGenerated'

import { Results } from './components/Results'

export const Search = () => {
	const [search, setSearch] = useState('')
	const [loading, setLoading] = useState(false)
	const [focused, setFocused] = useState(false)

	const [results, setResults] = useState<
		SearchFullTextJsonApiResponse | undefined
	>(undefined)
	const query = useDebounce(search, 300)

	const { data, isLoading } = useSearchFullTextJsonQuery(
		{ searchText: query },
		{ skip: query === '' || query?.length < 2 },
	)

	useEffect(() => {
		if (!query || query.length === 0) {
			setResults(undefined)
			setLoading(false)
			return
		}

		setLoading(true)
	}, [query])

	useEffect(() => {
		if (data) {
			setResults(data)
		}
		setLoading(isLoading)
	}, [data, isLoading])

	return (
		<SearchWrap>
			<StyledText
				inputIcon={faSearch}
				value={search}
				onChange={(v) => setSearch(v as string)}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				clearable
				name="NavBarSearch"
			/>

			{focused && (results || loading) && (
				<Results results={results} loading={loading} />
			)}
		</SearchWrap>
	)
}
