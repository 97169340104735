import {
	MRT_Cell,
	MRT_Row,
	MRT_RowData,
	MRT_TableInstance,
} from 'material-react-table'

import { IsDisabledParams } from '@/components/FieldRenderer/types'

import { UpdateTableData } from './types'

interface MuiRowDragHandleProps<TData extends object> {
	table: MRT_TableInstance<TData>
	updateTableData: UpdateTableData<TData>
}

export const muiRowDragHandleProps = <TData extends object>({
	updateTableData,
	table,
}: MuiRowDragHandleProps<TData>) => ({
	onDragEnd: () => {
		const { draggingRow, hoveredRow } = table.getState()
		if (hoveredRow && draggingRow) {
			updateTableData((draft) => {
				return draft.splice(
					(hoveredRow as MRT_Row<TData>).index,
					0,
					draft.splice(draggingRow.index, 1)[0],
				)
			})
		}
	},
})

export const getInitCellValue = <TData extends MRT_RowData>(
	customValue: string | number | boolean | undefined,
	cell: MRT_Cell<TData, any> | undefined,
) => {
	if (!customValue && !cell?.getValue()) return ''

	return customValue ?? cell?.getValue()
}

export const getMrtTableDisabled = <TData extends object, TValue = any>({
	column,
	editMode,
	row,
	isTechColumn,
}: IsDisabledParams<TData, TValue>): boolean => {
	const isDisabledMeta = column?.columnDef?.meta?.isDisabled
	// 1. Highest priority: Global edit mode. If not in edit mode, ALWAYS disabled.
	if (isTechColumn ?? !editMode) {
		return true
	}

	// 2. Second Priority: Column-level disabled function. This overrides.
	if (isDisabledMeta && typeof isDisabledMeta === 'function') {
		return isDisabledMeta(row)
	}

	// If none of the above, it's NOT disabled
	return false
}
