import { useCallback } from 'react'

import { DomainDto, StereotypeDto } from '@/rtkApi/mddApiSliceGenerated'
import { TableColumnFlat } from '@/store/modules/table/types'

interface UseSyncColumnValueUpdateParams {
	columnStereotypes?: StereotypeDto[]
	domains: DomainDto[]
	formColumns: TableColumnFlat[]
	getDomainData: (domain?: DomainDto) => Partial<TableColumnFlat> | undefined
}

export const useSyncColumnValueUpdate = ({
	columnStereotypes,
	domains,
	getDomainData,
	formColumns,
}: UseSyncColumnValueUpdateParams) => {
	const handleSyncColumnValueUpdate = useCallback(
		(
			draft: TableColumnFlat[],
			rowIndex: number,
			columnId: keyof TableColumnFlat,
			value: unknown,
			newCode: string | undefined,
		) => {
			const draftRow = draft[rowIndex]
			if (!draftRow) return

			if (columnId === 'name' && newCode) {
				draftRow.code = newCode
			}

			if (columnId === 'stereotypeId') {
				const matchedStereotype = columnStereotypes?.find(
					(col) => col.id === value,
				)
				draftRow.stereotypeName = matchedStereotype?.name
				draftRow.stereotypeCode = matchedStereotype?.code
			}

			if (columnId === 'domainId') {
				const foundDomain = domains.find((foundD) => foundD.id === value)
				const domainData = getDomainData(foundDomain)
				const foundFormCol = formColumns.find((col) => col.id === value)

				if (foundFormCol && foundFormCol.domainId === value) {
					return
				}

				if (foundDomain) {
					draftRow.domainId = foundDomain.id
					draftRow.domainName = foundDomain.name
					draftRow.domainCode = foundDomain.code
					draftRow.notNullFlag = foundDomain.notNullFlag

					if (!foundDomain.custom) {
						draftRow.dataType = domainData?.dataType
						draftRow.defaultValue = domainData?.defaultValue
					}
				} else {
					Object.assign(draftRow, {
						domainId: undefined,
						domainName: undefined,
						domainCode: undefined,
						notNullFlag: undefined,
						dataType: undefined,
						defaultValue: undefined,
					})
				}
			}

			if (draftRow[columnId] !== value) {
				draftRow[columnId] = value
			}
		},
		[columnStereotypes, domains, getDomainData, formColumns],
	)

	return { handleSyncColumnValueUpdate }
}
