import { useEffect, useState } from 'react'
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
} from 'react-router-dom'

import { Loader, MainLayout } from '@/components'
import { StereotypeDto } from '@/endpoints/models'
import { useAppDispatch, useAppStore } from '@/hooks'
import { Home, Settings } from '@/pages'
import { loadNodeState } from '@/store/modules/node/actions'
import { updateSettings } from '@/store/modules/settings/actions'
import { loadStereotypes } from '@/store/modules/stereotype/actions'
import { loadTabState } from '@/store/modules/tab/actions'
import {
	loadUserSession,
	updateUserSession,
} from '@/store/modules/user/actions'

export const AppRouter = () => {
	const dispatch = useAppDispatch()
	const userSession = useAppStore((state) => state.user.session)
	const [loaded, setLoaded] = useState(false)
	const [userLoaded, setUserLoaded] = useState(true)

	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route path="/" element={<MainLayout />}>
				<Route index element={<Home />} />
				<Route path="/settings" element={<Settings />} />
			</Route>,
		),
	)

	// Load global values
	useEffect(() => {
		const load = async () => {
			await Promise.all([
				dispatch(loadStereotypes(StereotypeDto.TypeEnum.TABLE_HISTORY)),
				dispatch(loadUserSession()),
			])

			setLoaded(true)
		}

		load()
	}, [])

	// Process user session
	useEffect(() => {
		const load = async () => {
			setUserLoaded(false)

			if (userSession) {
				await dispatch(loadNodeState(userSession.node))
				await dispatch(loadTabState(userSession.tab))
				dispatch(updateSettings(userSession.settings))
			}

			setUserLoaded(true)
		}

		load()
	}, [userSession])

	// Periodically save user session
	// TODO: Is this the best way to do this?
	useEffect(() => {
		if (loaded) {
			let lastSaved = JSON.stringify(userSession)

			const interval = setInterval(async () => {
				lastSaved = ((await dispatch(updateUserSession(lastSaved))) || {})
					.context as string
			}, 1000)

			return () => clearInterval(interval)
		}
	}, [dispatch, updateUserSession, loaded])

	return (
		<>
			<Loader loaded={loaded && userLoaded} $absolute={true} />
			<RouterProvider router={router} />
		</>
	)
}
