import {
	faArrowLeft,
	faArrowRight,
	faEdit,
	faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'
import { deleteRelation } from 'src/endpoints'
import styled from 'styled-components'

import { Button, DialogWrapper, RemoveModal } from '@/components'
import { Flex } from '@/components/Layout'
import { useAppContext } from '@/hooks'
import { RelationDto, StructureDto } from '@/rtkApi/mddApiSliceGenerated'
import { colors } from '@/styles'
import { LightTableColumn } from '@/types'

import { DependenciesModal } from '../components/DependenciesModal'
import { useOpenTab } from './useOpenTab'

export const useDependenciesColumns = (
	node: StructureDto,
	handleSave: () => void,
	editMode: boolean,
	typeStates: { label: string; value: string }[],
) => {
	const { t } = useAppContext()
	const { openTabById } = useOpenTab()

	const columns = useMemo<LightTableColumn<RelationDto>[]>(
		() => [
			{
				field: 'name',
				title: t('NAME'),
				width: 50,
				sortable: true,
			},
			{
				field: 'code',
				title: t('CODE'),
				width: 50,
				sortable: true,
			},
			{
				field: 'description',
				title: t('DESCRIPTION'),
				width: 50,
				sortable: true,
			},
			{
				field: 'relationType',
				title: t('TYPE'),
				width: 50,
				formatter: (cell) =>
					typeStates.find((value) => cell && value.value === cell)?.label,
				sortable: true,
			},
			{
				field: 'rightSideStructure',
				title: t('ELEMENT'),
				width: 100,
				sortable: true,
				formatter: (_, dependency) => {
					let id = dependency.rightSideStructure?.id
					let fullPath = dependency.rightSideStructure?.fullPath
					let icon = faArrowRight
					let color = colors.info.base

					if (dependency.rightSideStructure?.id === node.id) {
						fullPath = dependency.leftSideStructure?.fullPath
						id = dependency.leftSideStructure?.id
						icon = faArrowLeft
						color = '#3387ee'
					}

					return (
						<Wrapper title={fullPath} onClick={() => openTabById(id as number)}>
							<FontAwesomeIcon
								icon={icon}
								style={{ marginRight: '4px', color }}
							/>
							{fullPath}
						</Wrapper>
					)
				},
			},
			{
				field: '',
				width: 60,
				maxWidth: 60,
				// eslint-disable-next-line react/display-name
				formatter: (_, dependency) => (
					<Flex>
						{dependency.relationType === 'CUSTOM_LINK' &&
							dependency.leftSideStructure?.id === node.id && (
								<>
									<DialogWrapper
										dialog={(opened, onClose) =>
											opened && (
												<DependenciesModal
													node={node}
													dependency={dependency}
													onSave={handleSave}
													onClose={onClose}
												/>
											)
										}
									>
										{(onClick) => (
											<Button
												size="sm"
												schema="transparent"
												onClick={onClick}
												disabled={!editMode}
											>
												<FontAwesomeIcon title={t('EDIT')} icon={faEdit} />
											</Button>
										)}
									</DialogWrapper>

									<DialogWrapper
										dialog={(opened, onClose) =>
											opened && (
												<RemoveModal
													callback={deleteRelation}
													params={[dependency.id]}
													onRemove={handleSave}
													removeId={node.id}
													textOnModal={t('REMOVE_DESC', [
														dependency?.name ?? '',
													])}
													header={t('REMOVE_STEREOTYPE')}
													onClose={onClose}
												/>
											)
										}
									>
										{(onClick) => (
											<Button
												size="sm"
												schema="transparent"
												onClick={onClick}
												disabled={!editMode}
											>
												<FontAwesomeIcon
													title={t('REMOVE')}
													color="#d00"
													icon={faTimes}
												/>
											</Button>
										)}
									</DialogWrapper>
								</>
							)}
					</Flex>
				),
			},
		],
		[editMode, handleSave, node, openTabById, t],
	)

	return columns
}

const Wrapper = styled.div`
	display: flex;
	cursor: pointer;
`
