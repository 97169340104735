import React from 'react'
import { OpenedMappingData } from '@/store/modules/mapping/types'
import { StructureObjectDto } from '@/endpoints/models'
import styled from 'styled-components'
import { Flex } from '@/components/Layout/Flex'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DialogWrapper } from '@/components'
import { DeleteModal } from '@/pages/User/pages/Home/components/DeleteModal'
import { Cell, ActionsCell } from '@/components/Layout'

interface Props {
	node: StructureObjectDto
	mapping: OpenedMappingData | undefined
	onEdit: (node: StructureObjectDto) => void
}

export const Mapping = ({ node, mapping, onEdit }: Props) => (
	<Flex $align="stretch">
		<Cell>{node.name}</Cell>
		<Cell>{mapping && mapping.form.code}</Cell>
		<TextCell>{mapping && mapping.form.description}</TextCell>

		<ActionsCell>
			<Action onClick={() => onEdit(node)}>
				<FontAwesomeIcon icon={faEdit} />
			</Action>
			{node.canEdit && (
				<DialogWrapper
					dialog={(opened, onClose) =>
						opened && <DeleteModal node={node} onClose={onClose} />
					}
				>
					{(onClick) => (
						<Action onClick={onClick}>
							<FontAwesomeIcon icon={faTimes} />
						</Action>
					)}
				</DialogWrapper>
			)}
		</ActionsCell>
	</Flex>
)

const Action = styled.div`
	opacity: 0.5;
	cursor: pointer;
	margin-left: 5px;

	&:hover {
		opacity: 1;
	}
`

const TextCell = styled(Cell)`
	flex: 5;
	text-overflow: ellipsis;
	white-space: nowrap;
`
