import { MaterialReactTable } from 'material-react-table'
import { useMemo, useState } from 'react'

import { PageWrap } from '@/components'
import { useTable } from '@/hooks'
import { useCheckStructureQuery } from '@/rtkApi'
import { ValidationMessageDto } from '@/rtkApi/mddApiSliceGenerated'

import { useDetailTabContext } from '../DetailTab'
import { Filter, FilterValues } from './Filter'
import { filterValidationData } from './helpers'
import { useValidationColumns } from './hooks'

export const NewValidation = () => {
	const {
		state: { node },
	} = useDetailTabContext()

	const { data: validation, isLoading } = useCheckStructureQuery({
		structureId: node.id ?? 0,
	})

	const [filter, setFilter] = useState<FilterValues>({})

	const filteredData = useMemo(
		() => filterValidationData(validation || [], filter),
		[validation, filter],
	)

	const columns = useValidationColumns()

	const table = useTable<ValidationMessageDto>({
		columns,
		data: filteredData,
		options: {
			initialState: {
				density: 'compact',
			},
			enableRowOrdering: false,
			state: {
				isLoading,
			},
			muiSkeletonProps: {
				animation: 'pulse',
				height: 28,
			},
			enableTopToolbar: true,
			enableGlobalFilter: true,
			renderTopToolbar: false,
		},
	})

	return (
		<PageWrap>
			<Filter onChange={(values) => setFilter(values)} />
			<MaterialReactTable key={JSON.stringify(filteredData)} table={table} />
		</PageWrap>
	)
}
