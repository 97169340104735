import React, { useMemo } from 'react'

import {
	ColumnsPicker,
	RegExpValidatedInputFormless,
	SecretInput,
} from '@/components'
import { ForeignKey } from '@/components/Properties'
import { CellValueWrapper } from '@/components/Properties/components/CellValueWrapper'
import { ErrorTooltip } from '@/components/Properties/components/PropertyFieldStyles'
import { ValueWrapper } from '@/components/Properties/components/ValueWrapper'
import { Text } from '@/components/UberForm'
import { TableIndexColumn } from '@/endpoints/schemas'
import { ItemPropertyType, Position } from '@/enums'
import { SortIcon } from '@/pages/User/pages/Home/pages/TableDetail/components/SortIcon'
import {
	listValue,
	listValues,
} from '@/pages/User/pages/Home/pages/TableDetail/utils'

import { TableSelect } from './styles'
import { EditableCellSwitchProps } from './types'

export const EditableCellSwitch = ({
	autoFocus,
	readonly,
	prop,
	inputValue,
	onChange,
	value,
	item,
	isDisabled,
	error,
	textRef,
	textOnChange,
	node,
}: EditableCellSwitchProps) => {
	const isEditable = !readonly && !prop.isLoading
	const regExp = prop.regExp?.(item)
	const options = useMemo(() => {
		if (typeof prop.options === 'function') {
			return prop.options(item)
		}

		if (Array.isArray(prop.options)) {
			return prop.options
		}

		return []
	}, [prop, item])

	if (isEditable) {
		return (
			<>
				{prop.type === ItemPropertyType.TEXT && (
					<ErrorTooltip
						shown={false}
						showOnHover={true}
						content={error}
						position={Position.Bottom}
					>
						<input
							className="px-2"
							value={inputValue}
							onChange={() => textOnChange}
							disabled={isDisabled}
							type="text"
						/>
					</ErrorTooltip>
				)}
				{prop.type === ItemPropertyType.REGEXP_VALIDATED && (
					<RegExpValidatedInputFormless
						value={typeof value === 'string' ? value : ''}
						name={prop.field}
						enableAutocomplete={false}
						onChange={onChange}
						disabled={isDisabled}
						expression={regExp?.expression}
						sampleValue={regExp?.sampleValue}
					/>
				)}
				{prop.type === ItemPropertyType.FOREIGN_KEY && (
					<ForeignKey
						value={value}
						name={prop.field ?? ''}
						disabled={isDisabled}
						foreignKeyTableId={prop.foreignKeyTableId}
						primaryKeys={prop.primaryKeys}
						possibleForeignKeys={prop.possibleForeignKeys}
						error={error}
					/>
				)}
				{prop.type === ItemPropertyType.OPTION && (
					<TableSelect
						name={prop.field || ''}
						options={options}
						hideTitle={true}
						initialValue={value}
						value={value}
						onChange={onChange}
						disabled={isDisabled}
						valueKey={prop.optionValue}
						labelKey={prop.optionLabel}
						isNumeric={prop.isNumeric}
						clearable={prop.clearable}
						allowEmpty
						formless
						loading={!!prop.isLoading}
						openMenuOnFocus
						placeholder=""
						components={{ Option: prop.customOption }}
					/>
				)}
				{prop.type === ItemPropertyType.OPTIONS && (
					<TableSelect
						name={prop.field || ''}
						options={options}
						hideTitle={true}
						initialValue={value}
						onChange={onChange}
						disabled={isDisabled}
						valueKey={prop.optionValue}
						labelKey={prop.optionLabel}
						isNumeric={prop.isNumeric}
						clearable={prop.clearable}
						isMulti
						allowEmpty
						formless
						loading={!!prop.isLoading}
						autoFocus={autoFocus}
						openMenuOnFocus
						placeholder=""
					/>
				)}
				{/*MDD-1601 - migrated the core, this feature seems to be abandon and not used for a long time
				  find more in the ticket description.
				  */}
				{(prop.type === ItemPropertyType.COLUMNS ||
					prop.type === ItemPropertyType.COLUMNS_WITH_ORDER) && (
					<ColumnsPicker
						field={prop.field || ''}
						columns={options}
						value={value ?? []}
						orders={prop.type === ItemPropertyType.COLUMNS_WITH_ORDER}
					/>
				)}
				{prop.type === ItemPropertyType.SECRET && (
					<SecretInput
						item={item}
						isEditable={isDisabled}
						textOnChange={textOnChange}
						value={inputValue}
						textRef={textRef}
						error={error}
						node={node}
					/>
				)}
			</>
		)
	} else {
		return (
			<>
				{prop.type === ItemPropertyType.SECRET && (
					<Text
						autoFocus
						value={inputValue}
						type={'password'}
						onChange={textOnChange}
						disabled={isDisabled}
						className="textInputWithoutBorder"
						error={error}
						refInput={textRef as React.RefObject<HTMLInputElement>}
					/>
				)}
				{(prop.type === ItemPropertyType.TEXT ||
					prop.type === ItemPropertyType.NUMBER ||
					prop.type === ItemPropertyType.REGEXP_VALIDATED) && (
					<div className="p-2" title={value}>
						<input value={value ?? undefined} disabled={isDisabled} readOnly />
					</div>
				)}
				{prop.type === ItemPropertyType.OPTION && (
					<CellValueWrapper
						disabled={isDisabled}
						title={listValues(
							options,
							prop.optionValue || 'value',
							prop.optionLabel || 'label',
							[value],
						)}
					>
						{listValue(
							options,
							prop.optionValue || 'value',
							prop.optionLabel || 'label',
							value,
						)}
					</CellValueWrapper>
				)}

				{prop.type === ItemPropertyType.OPTIONS && (
					<ValueWrapper
						disabled={isDisabled}
						title={listValues(
							options,
							prop.optionValue || 'value',
							prop.optionLabel || 'label',
							Array.isArray(value) ? value : [],
						)}
					>
						{listValues(
							options,
							prop.optionValue || 'value',
							prop.optionLabel || 'label',
							Array.isArray(value) ? value : [],
						)}
					</ValueWrapper>
				)}
				{prop.type === ItemPropertyType.FOREIGN_KEY && (
					<ForeignKey
						value={value}
						name={prop.field ?? ''}
						disabled={isDisabled}
						primaryKeys={prop.primaryKeys}
						foreignKeyTableId={prop.foreignKeyTableId}
						possibleForeignKeys={prop.possibleForeignKeys}
						error={error}
					/>
				)}
				{prop.type === ItemPropertyType.COLUMNS && (
					<ValueWrapper
						disabled={isDisabled}
						title={listValues(
							options,
							'code',
							'name',
							Array.isArray(value) ? value.map((v) => v.code) : [],
						)}
					>
						{listValues(
							options,
							'code',
							'name',
							Array.isArray(value) ? value.map((v) => v.code) : [],
						)}
					</ValueWrapper>
				)}
				{prop.type === ItemPropertyType.COLUMNS_WITH_ORDER &&
					Array.isArray(value) &&
					(value as TableIndexColumn[]).map((ci, index) => (
						<React.Fragment key={ci.code}>
							<SortIcon sortType={ci.sortType} />
							<span>
								{options.find((o) => o.id === ci.code)?.name ?? ci.code}
							</span>
							{index != value.length - 1 && ', '}
						</React.Fragment>
					))}
			</>
		)
	}
}
