import styled from 'styled-components'

import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { Form, TextAreaFormField } from '@/components/UberForm'
import { SourceItem } from '@/endpoints/schemas'
import { useAppContext, useAppStore } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { MainContentWrap } from '@/styles'
import { MappingSource } from '../../../../components'
import { Paper } from '@mui/material'

interface SourcesValues {
	criteria?: string
	criteriaDescription?: string
	sourceItems: SourceItem[]
	sourceItemsLastId: number
	sources?: string
	sourcesDescription?: string
	targetCriteria: string
	targetCriteriaDescription: string
}

interface MappingSourceTabProps {
	onChange: (v: Partial<SourcesValues>) => void
}

export const MappingSourceTab = ({ onChange }: MappingSourceTabProps) => {
	const {
		state: { node, editMode },
	} = useDetailTabContext()
	const mappings = useAppStore((state) => state.mapping.mappings)
	const data = mappings[node.id]
	const { t } = useAppContext()

	if (!data) return null

	return (
		<StyledContainer>
			<Form<SourcesValues>
				onChange={(values) =>
					onChange({
						...values,
						sourceItems: data?.form.sourceItems,
						sourceItemsLastId: data?.form.sourceItemsLastId,
					})
				}
				defaultValues={data?.form}
				disabled={!editMode}
				enableFieldHighlight
			>
				<Wrapper>
					<MappingSource />

					<Paper className="p-4 mt-8" elevation={3}>
						<TextAreaFormField
							name="sourcesDescription"
							title={t('MAPPING_FIELD_DESCRIPTION', [t('MAPPING_SOURCES')])}
							initialValue={data?.form.sourcesDescription}
						/>
						<AceEditorField
							name="criteria"
							title={t('MAPPING_CRITERIA')}
							initialValue={data?.form.criteria}
						/>
						<TextAreaFormField
							name="criteriaDescription"
							title={t('MAPPING_FIELD_DESCRIPTION', [t('MAPPING_CRITERIA')])}
							initialValue={data?.form.criteriaDescription}
						/>
						<AceEditorField
							name="targetCriteria"
							title={t('MAPPING_TARGET_CRITERIA')}
							initialValue={data?.form.targetCriteria}
						/>
						<TextAreaFormField
							name="targetCriteriaDescription"
							title={t('MAPPING_FIELD_DESCRIPTION', [
								t('MAPPING_TARGET_CRITERIA'),
							])}
							initialValue={data?.form.targetCriteriaDescription}
						/>
					</Paper>
				</Wrapper>
			</Form>
		</StyledContainer>
	)
}

const StyledContainer = styled(MainContentWrap)`
	textarea {
		border-radius: 0;
	}
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`
