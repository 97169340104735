import { useCallback } from 'react'

import { TableMode } from '@/enums'
import { useAppDispatch } from '@/hooks'
import { useAdjustTableNameMutation } from '@/rtkApi'
import { StructureDto } from '@/rtkApi/mddApiSliceGenerated'
import { updateSequenceCode } from '@/store/modules/table/actions'
import { TableColumnFlat } from '@/store/modules/table/types'
import { getNameCode } from '@/utils'

interface UseAsyncUpdateParams {
	isCodeLowerCase: boolean
	node: StructureDto
	sequenceColumnCode?: string
}

export const useAsyncColumnValueUpdate = ({
	sequenceColumnCode,
	node,
	isCodeLowerCase,
}: UseAsyncUpdateParams) => {
	const dispatch = useAppDispatch()
	const [adjustTableNameMutation] = useAdjustTableNameMutation()

	const handleAsyncColumnValueUpdate = useCallback(
		async (
			columnId: keyof TableColumnFlat,
			value: unknown,
			draftRow: TableColumnFlat,
		): Promise<string | undefined> => {
			let newCode: string | undefined = draftRow.code || undefined

			if (columnId === 'name') {
				const response = await adjustTableNameMutation({
					utilsNameRequestDto: { name: value as string },
				}).unwrap()
				newCode = response.result
			} else if (columnId === 'code') {
				newCode = value as string
			}

			if (sequenceColumnCode) {
				const previousCode = draftRow.code
				const formattedNewCode = getNameCode(String(newCode), isCodeLowerCase)

				if (sequenceColumnCode === previousCode && formattedNewCode) {
					await dispatch(
						updateSequenceCode(
							node,
							{ sequenceColumnCode: formattedNewCode },
							TableMode.TABLE,
							[
								'SEQUENCE_NAME',
								node.id!,
								{ sequence_column: formattedNewCode },
							],
						),
					)
				}

				newCode = formattedNewCode
			}

			return newCode
		},
		[
			adjustTableNameMutation,
			sequenceColumnCode,
			node,
			isCodeLowerCase,
			dispatch,
		],
	)

	return { handleAsyncColumnValueUpdate }
}
