import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { FormValue } from '@/types'
import { nextFrame } from '@/utils/async'

import { FormFieldContext, withFormField } from '../../FormFieldContext'
import { Clear, Container } from './styles'

export interface TextProps extends FormFieldContext {
	autoFocus?: boolean
	className?: string
	clearable?: boolean
	enableAutocomplete?: boolean
	error?: string | null
	formatter?: (value: string) => string
	hasNoBorder?: boolean
	inputIcon?: IconDefinition
	isRequired?: boolean
	maxLength?: number
	placeholder?: string
	refInput?: React.RefObject<HTMLInputElement>
	role?: string
	titleContainer?: string
	type?: 'password' | 'text' | 'number'
	value?: FormValue
}

export class FormlessText extends React.PureComponent<TextProps> {
	static defaultProps = {
		type: 'text',
	}

	input = React.createRef<HTMLInputElement>()

	async componentDidMount() {
		const { refInput, autoFocus } = this.props

		await nextFrame()

		if (refInput && autoFocus) {
			refInput.current?.focus()
		}
	}

	onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let value = e.target.value

		if (typeof this.props.maxLength === 'number') {
			value = value.substring(0, Math.min(value.length, this.props.maxLength))
		}

		if (this.props.onChange) {
			this.props.onChange(value || undefined)
		}
	}

	onFocus = (e: React.FocusEvent) => {
		const { onFocus } = this.props

		if (onFocus) {
			onFocus(e)
		}
	}

	onBlur = (e: React.FocusEvent) => {
		const { onBlur } = this.props

		if (onBlur) {
			onBlur(e)
		}
	}

	handleClear = () => {
		if (this.props.onChange) {
			this.props.onChange(undefined)
		}
	}

	render() {
		const {
			id,
			value,
			placeholder,
			className,
			name,
			disabled,
			type,
			isCompact,
			enableAutocomplete,
			clearable,
			error,
			role,
			inputIcon,
			formatter,
			refInput,
			isRequired,
			titleContainer,
			hasNoBorder,
		} = this.props

		const getValue = () => {
			if (!value) return ''

			if (formatter) return value

			return value
		}

		return (
			<Container
				hasNoBorder={hasNoBorder}
				disabled={disabled}
				title={titleContainer}
			>
				{inputIcon && (
					<div className="flex items-center justify-start">
						<FontAwesomeIcon icon={inputIcon} />
					</div>
				)}
				<input
					ref={refInput ?? this.input}
					type={type}
					id={id}
					name={name}
					className={className}
					disabled={disabled}
					placeholder={placeholder}
					value={getValue() as string}
					$compact={!!isCompact}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					$error={error}
					autoComplete={enableAutocomplete ? 'on' : 'off'}
					role={role}
					$hasIcon={inputIcon !== undefined}
					required={isRequired}
				/>
				{clearable && value && (
					<Clear onClick={this.handleClear}>
						<FontAwesomeIcon icon={faTimes} />
					</Clear>
				)}
			</Container>
		)
	}
}

export default withFormField(FormlessText)
