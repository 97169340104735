import styled, { css } from 'styled-components'

import Tooltip from '@/components/Tooltip/Tooltip'
import { SelectFormField } from '@/components/UberForm'
import { cssError } from '@/styles/errorValidation'

export interface FormProps {
	$fromProps?: boolean
	$isFocused?: boolean
	error?: string
	rows?: number
	variant?: 'textarea'
}

export const cssFocused = css<FormProps>`
	${(props) =>
		props.$isFocused &&
		css`
			:focus {
				border: 1px solid ${props.theme.colors.primary.base} !important;
				box-shadow: 0 0 3px ${props.theme.colors.primary.shadowColor};
			}
		`}
`

export const FormInput = styled.input<FormProps>`
	width: 100%;
	${(props) =>
		props.$fromProps &&
		css`
			:hover {
				box-shadow: 0 0 3px ${(props) => props.theme.colors.primary.shadowColor};
			}
			${cssFocused};
		`};
	${cssError};
`

export const TextAreaInput = styled.textarea<FormProps>`
	background: #fff;
	z-index: 11;
	width: 250px;
	${cssFocused};
	${cssError};
`

export const ErrorTooltip = styled(Tooltip).attrs({
	styleTrigger: {
		width: '100%',
	},
})`
	${(props) => css`
		background: ${props.theme.colors.form.error.background};
		color: ${props.theme.colors.form.error.color};

		padding: 5px 10px;

		&::before {
			content: ' ';
			position: absolute;
			top: -15px;
			left: 50%;
			margin-left: -5px;
			height: 5px;
			border: 5px solid transparent;
			border-bottom-color: ${props.theme.colors.form.error.background};
		}
	`}
`

export const Select = styled(SelectFormField)<
	FormProps & { fromProps: boolean }
>`
	height: 100%;
	width: 100%;
	border: 0;
	margin-bottom: 1px;
	margin-right: 0;
`

export const ValueContainer = styled.div<{ disabled?: boolean }>`
	border: 1px solid ${(props) => props.theme.colors.input.border};
	display: flex;
	align-items: center;
	padding: 8px 6px;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	${(props) =>
		props.disabled &&
		css`
			border: 1px solid ${(props) => props.theme.colors.input.disabled.border};
			background: ${(props) => props.theme.colors.input.disabled.background};
		`}
`
export const ValueOverflow = styled.div`
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
`
