import React from 'react'
import Select from 'react-select'

import { EnhancedPureComponentWithContext } from '@/components/EnhancedComponents'
import { getSelectValue } from '@/components/UberForm/Input/Select/helpers'
import { FormInput } from '@/components/UberForm/types'
import { compareFlatArrays, getCustomComponents, nextFrame } from '@/utils'

import { withFormField } from '../../FormFieldContext'
import { getStylesConfig, StyledSelect } from './styles'
import { SelectProps } from './types'

const SELECT_CLASSNAME_PREFIX = 'react-select'

export class SelectWithoutForm<
	T extends Record<string, any>,
> extends EnhancedPureComponentWithContext<SelectProps<T>> {
	static defaultProps = {
		labelKey: 'label',
		valueKey: 'value',
		clearable: true,
		allowEmpty: true,
	}
	input = React.createRef<Select>()
	async componentDidMount() {
		const { register, refInput } = this.props

		if (refInput && refInput !== this.input) {
			this.input = refInput
		}

		if (register) {
			register(this as FormInput)
		}

		await nextFrame()
	}

	getValueKey = () => this.props.valueKey || 'value'
	getLabelKey = () => this.props.labelKey || 'label'

	componentDidUpdate(prevProps: SelectProps<T>) {
		const { options, isMulti, allowEmpty, loading, onChange, value } =
			this.props

		// Skip value checks if we're still loading
		if (loading) {
			return
		}

		if (
			loading !== prevProps.loading ||
			options !== prevProps.options ||
			value !== prevProps.value ||
			(!value && !allowEmpty)
		) {
			let updatedValue = value

			if (isMulti) {
				if (value && Array.isArray(value)) {
					updatedValue = this.props.options
						? value.filter((item) =>
								(this.props.options as T[]).find(
									(option) => this.getOptionValue(option) === item,
								),
							)
						: []
				}
			} else {
				updatedValue =
					value === null || value === undefined
						? value
						: this.props.options &&
							(this.props.options.some(
								(option) => this.getOptionValue(option) === value,
							)
								? value
								: allowEmpty
									? undefined
									: this.props.options[0] &&
										this.getOptionValue(this.props.options[0]))
			}

			if (
				updatedValue !== value &&
				(!isMulti ||
					!Array.isArray(updatedValue) ||
					!Array.isArray(value) ||
					!compareFlatArrays(updatedValue as string[], value as string[]))
			) {
				if (!updatedValue) return

				onChange && onChange(updatedValue)
			}
		}
	}

	onChange = (newValue: readonly T[] | T | undefined | null) => {
		const { isMulti, options, allowEmpty, onChange, onCoerseValue, value } =
			this.props

		if (onCoerseValue && options) {
			const oldValue = isMulti
				? value
					? (value as [string]).map(
							(val) =>
								options.filter(
									(option) => this.getOptionValue(option) === val,
								)[0],
						)
					: []
				: value
					? options.filter(
							(option) => this.getOptionValue(option) === (value as string),
						)
					: undefined

			newValue = onCoerseValue(oldValue, newValue)
		}

		onChange &&
			onChange(
				isMulti
					? newValue
						? (newValue as T[]).map((option) => this.getOptionValue(option))
						: []
					: newValue
						? this.getOptionValue(newValue as T)
						: allowEmpty
							? undefined
							: options && options[0] && this.getOptionValue(options[0]),
			)
	}

	getOptionValue = (opt: T) => {
		const selectRawValue = opt[this.getValueKey()]

		if (!selectRawValue) {
			return null
		}

		if (this.props.isNumeric) {
			return selectRawValue
		}

		return selectRawValue.toString()
	}

	getOptionLabel = (opt: T) => {
		let label = opt[this.getLabelKey()]

		if (opt.wasDeleted) {
			label += ' !'
		}

		return label
	}

	getNoOptionsMessage = () =>
		this.props.noResultText
			? this.props.noResultText
			: this.context.t('FILTER_NO_RESULT_TEXT')

	render() {
		const { t } = this.context

		const {
			className,
			clearable,
			components,
			customStyles,
			customWidth,
			isDisabled,
			formatOptionLabel,
			id,
			isLoading,
			isMulti,
			name,
			onBlur,
			onFocus,
			onInputChange,
			onMenuClose,
			onMenuOpen,
			openMenuOnFocus,
			options,
			placeholder,
			refInput,
			value,
		} = this.props

		const selectValue = getSelectValue<T>({
			options,
			value,
			isMulti,
			getOptionValue: this.getOptionValue,
		})

		const getPlaceHolder = (): string => {
			if (isDisabled) return ''
			if (placeholder !== undefined) return placeholder
			return isMulti ? t('FILTER_ALL_PLACEHOLDER') : ''
		}

		return (
			<div
				style={{ width: '100%' }}
				role="listbox"
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						e.stopPropagation()
						e.preventDefault()
					}
				}}
			>
				<StyledSelect<any>
					className={className}
					classNamePrefix={SELECT_CLASSNAME_PREFIX}
					closeMenuOnSelect={!isMulti}
					components={getCustomComponents(components)}
					customWidth={customWidth}
					formatOptionLabel={formatOptionLabel}
					getOptionLabel={this.getOptionLabel}
					getOptionValue={this.getOptionValue}
					hideSelectedOptions={false}
					inputId={id}
					isClearable={clearable}
					isDisabled={isDisabled}
					isMulti={isMulti}
					menuPlacement="auto"
					menuPortalTarget={document.body}
					name={name}
					noOptionsMessage={this.getNoOptionsMessage}
					onBlur={onBlur}
					onChange={this.onChange}
					onFocus={onFocus}
					onInputChange={onInputChange}
					onMenuClose={onMenuClose}
					onMenuOpen={onMenuOpen}
					openMenuOnFocus={openMenuOnFocus}
					options={options}
					placeholder={getPlaceHolder()}
					ref={refInput ?? this.input}
					styles={getStylesConfig(customStyles)}
					value={selectValue}
					isLoading={isLoading}
				/>
			</div>
		)
	}
}

export default withFormField(SelectWithoutForm)
