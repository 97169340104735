import { CustomAttributeForm } from '@/hooks'
import { CustomAttributeComboDto } from '@/rtkApi/mddApiSliceGenerated'

export const getDefaultValue = (type: string) => {
	switch (type) {
		case 'BOOLEAN':
			return false
		case 'NUMBER':
			return 0
		case 'STRING':
		case 'SCRIPT':
		case 'LOV':
			return ''
		case 'ARRAY':
			return [{ value: '' }] // Assuming your array contains objects with a 'value' key.
		default:
			return null
	}
}

export const getInitialAttributes = (
	customAttributes: CustomAttributeComboDto[],
	existingCustomAttributes: CustomAttributeForm[] | undefined,
) =>
	customAttributes.map((attr) => {
		const matchedAttr = existingCustomAttributes?.find(
			(existingAttr) => existingAttr.id == attr.id,
		)

		if (matchedAttr) {
			return {
				id: Number(matchedAttr.id),
				name: matchedAttr.name,
				value: matchedAttr.value,
				version: Number(matchedAttr.version),
				type: attr.type,
			}
		}

		return {
			id: Number(attr.id),
			name: attr.name,
			value: getDefaultValue(String(attr.type)),
			version: Number(attr.version),
			type: attr.type,
		}
	})

export const filterFalsyAttributes = (attributes: CustomAttributeForm[]) => {
	return attributes.filter((attr) => {
		if (attr.value === null || attr.value === undefined) return false

		switch (attr.type) {
			case 'ARRAY':
				return (
					Array.isArray(attr.value) &&
					attr.value.some(
						(item) =>
							typeof item.value === 'string' && item.value.trim() !== '',
					)
				)

			case 'BOOLEAN':
				return attr.value === true

			case 'STRING':
			case 'SCRIPT':
				return attr.value.trim() !== ''

			case 'NUMBER':
				return attr.value !== 0

			default:
				return !!attr.value
		}
	})
}
