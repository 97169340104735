import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { Button as MuiButton } from '@mui/material'
import { useState } from 'react'

import { ImportColumnsCsv } from '@/pages/User/pages/Home/components'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'

interface ImportColumnsButtonProps {
	reloadTableData: () => void
}

export const ImportColumnsButton = ({
	reloadTableData,
}: ImportColumnsButtonProps) => {
	const {
		state: { editMode, node },
	} = useDetailTabContext()
	const [isModalOpen, setModalOpen] = useState(false)

	return (
		<>
			<MuiButton
				onClick={() => setModalOpen(true)}
				startIcon={<PlaylistAddIcon />}
				disabled={!editMode}
				variant="contained"
				color="primary"
			>
				Import
			</MuiButton>

			<ImportColumnsCsv
				isOpen={isModalOpen}
				node={node}
				onClose={() => {
					setModalOpen(false)
					reloadTableData()
				}}
			/>
		</>
	)
}
