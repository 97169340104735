import cn from 'classnames'
import React from 'react'
import NumberFormat, {
	NumberFormatProps,
	NumberFormatValues,
} from 'react-number-format'
import styled, { css } from 'styled-components'

import { AppContext } from '@/context/AppContext'

import { FormFieldContext, withFormField } from '../../FormFieldContext'
import { isDef } from './utils'

export interface NumberProps extends FormFieldContext {
	className?: string
	decimals?: number
	numberFormatProps?: NumberFormatProps
	onEnter?: (e: React.KeyboardEvent) => void
	placeholder?: string
	step?: number
	value?: number
}

export class NumberWithoutForm extends React.PureComponent<NumberProps> {
	static contextType = AppContext

	formOnChange = (value?: number) => {
		if (!this.props.onChange) {
			return
		}

		if (!isDef(value)) {
			this.props.onChange(undefined)

			return
		}

		this.props.onChange(value)
	}

	handleChange = (values: NumberFormatValues) => {
		if (!this.props.onChange) {
			return
		}

		if (!isDef(values.floatValue)) {
			this.props.onChange(undefined)

			return
		}

		this.formOnChange(values.floatValue)
	}

	handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		const { onBlur } = this.props

		if (onBlur) {
			onBlur(e)
		}
	}

	handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		const { onFocus } = this.props

		if (onFocus) {
			onFocus(e)
		}
	}

	render() {
		const {
			placeholder,
			className,
			name,
			id,
			disabled,
			numberFormatProps,
			value,
		} = this.props

		return (
			<NumberFormatStyled
				disabled={disabled}
				onValueChange={this.handleChange}
				thousandSeparator=" "
				name={name}
				id={id}
				className={cn('form-control ', className || '')}
				placeholder={placeholder}
				value={value ?? ''}
				onBlur={this.handleBlur}
				onFocus={this.handleFocus}
				autoComplete="off"
				decimalScale={0}
				{...numberFormatProps}
			/>
		)
	}
}

interface NumberFormatStyledProps {
	$compact: boolean
	disabled?: boolean
	error?: string | null
	hasIcon?: boolean
}

const NumberFormatStyled = styled(NumberFormat)<NumberFormatStyledProps>`
	border: ${(props) => `1px solid ${props.theme.colors.input.border}`};
	background: ${(props) =>
		`${props.disabled ? props.theme.colors.input.disabled.background : ''}`};
	width: 100%;

	&& {
		${(props) => css`
			font-size: ${props.theme.input.fontSize};
			border-color: ${props.$compact
				? `${props.theme.colors.input.$compact?.horizontalBorder} ${props.theme.colors.input.$compact?.verticalBorder}`
				: props.theme.colors.input.border};
			border-radius: ${props.$compact ? 0 : props.theme.input.borderRadius};
			padding: ${props.theme.input.padding};
			:focus {
				border-color: ${props.theme.colors.primary.base};
				box-shadow: 0 0 3px ${props.theme.colors.primary.shadowColor};
			}
			:hover {
				box-shadow: ${props.disabled
					? 'none'
					: `0 0 3px ${props.theme.colors.primary.shadowColor}`};
			}
		`}

		${(props: any) => props.error && props.theme.colors.form}

		${(props) =>
			props.$compact &&
			css`
				height: 26px;
			`}
	}
` as unknown as typeof NumberFormat

export default withFormField(NumberWithoutForm) as typeof NumberWithoutForm
