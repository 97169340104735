import React, { useMemo } from 'react'

import { getNodeDetail } from '@/endpoints'
import { useApiRequest } from '@/endpoints/hooks'
import { useAppDispatch, useAppStore } from '@/hooks'
import { EntityTypeIcon } from '@/pages/User/pages/Home/components/EntityTypeIcon'
import { FullTextSearchDto } from '@/rtkApi/mddApiSliceGenerated'
import { loadNode } from '@/store/modules/node/general-actions'
import { openTab } from '@/store/modules/tab/actions'

import {
	HeadlineBit,
	HeadlineBitType,
	parseHeadline,
} from '../parsers/headline'
import { Data, Icon, MatchedText, Name, ResultWrap } from './styles'

type ResultProps = {
	item: FullTextSearchDto
}

const renderBit = (bit: HeadlineBit, index: number) => {
	switch (bit.type) {
		case HeadlineBitType.Match: {
			return <MatchedText key={index}>{bit.text}</MatchedText>
		}

		case HeadlineBitType.Text: {
			return <span key={index}>{bit.text}</span>
		}

		case HeadlineBitType.NewLine: {
			return ''
		}
	}
}

export const Result = ({ item }: ResultProps) => {
	const dispatch = useAppDispatch()
	const request = useApiRequest()
	const nodes = useAppStore((state) => state.node.nodes)

	const path = useMemo(
		() => (item.path ? parseHeadline(item.path) : []),
		[item],
	)

	const handleClick = async (e: React.MouseEvent) => {
		// Check if middle mouse button is clicked
		if (e.button === 1) {
			e.preventDefault()
		}

		// Check if item has an ID
		if (item.id !== undefined) {
			// Get the node data
			const { data: node } = { data: nodes[item.id] }

			// If the node exists, open it in a new tab
			if (node) {
				dispatch(openTab(node, false))
			} else {
				// If the node does not exist, fetch its details
				const { data: newNode } = await request(getNodeDetail(item.id))

				// If the fetched node data exists
				if (newNode) {
					// Load the node and open it in a new tab
					await dispatch(loadNode(newNode.id))
					dispatch(openTab(newNode, false))
				}
			}
		}
	}

	return (
		<ResultWrap onMouseDown={handleClick}>
			<Name>
				{item.type && (
					<Icon>
						<EntityTypeIcon type={item.type} />
					</Icon>
				)}
				{item.name}
			</Name>
			<Data>{path.map(renderBit)}</Data>
		</ResultWrap>
	)
}
