import { useState } from 'react'

import { CopyTextButton } from '@/components/MrtTable/components/CopyTextButton'
import { TextArea } from '@/components/UberForm'
import { useAppContext } from '@/hooks'

import { ModalForm } from '../../Modal/ModalForm'
import { CellProps } from './types'

export const TextModalCell = ({
	isEditMode,
	value,
	isDisabled,
	label,
	onChange,
}: CellProps) => {
	const { t } = useAppContext()
	const [isModalOpen, setModalOpen] = useState(false)
	const [textAreaValue, setTextAreaValue] = useState<any>(value || '')

	const onSubmit = () => {
		setModalOpen(false)
		onChange(textAreaValue.replace(/\n/g, ' '))
	}

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' && e.metaKey) {
			e.preventDefault()
			onSubmit()
		}
	}

	return (
		<div>
			<input
				className="px-2"
				value={textAreaValue}
				disabled={isDisabled}
				onFocus={() => setModalOpen(true)}
			/>

			<ModalForm
				header={label}
				open={isModalOpen}
				onClose={() => setModalOpen(false)}
				onSubmit={onSubmit}
				hasSubmitButton={isEditMode}
				cancelTitle={isEditMode ? t('CANCEL') : t('CLOSE')}
				footerBefore={<CopyTextButton text={textAreaValue} />}
			>
				<TextArea
					onChange={(value) => setTextAreaValue(value)}
					disabled={isDisabled || !isEditMode}
					className="textInputWithoutBorder"
					value={textAreaValue}
					autoFocus={true}
					onKeyDown={onKeyDown}
					rows={17}
				/>
			</ModalForm>
		</div>
	)
}
