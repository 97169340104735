import styled from 'styled-components'

import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { Form, TextAreaFormField } from '@/components/UberForm'
import { useAppContext, useAppStore } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { MainContentWrap } from '@/styles'

interface OthersValues {
	outWhere?: string
	outWhereDescription?: string
	postMappingScript?: string
	postMappingScriptDescription?: string
	preMappingScript?: string
	preMappingScriptDescription?: string
	sourceHints?: string
	sourceHintsDescription?: string
	targetHints?: string
	targetHintsDescription?: string
}

interface Props {
	onChange: (v: Partial<OthersValues>) => void
}

export const Others = ({ onChange }: Props) => {
	const {
		state: { node, editMode },
	} = useDetailTabContext()
	const mappings = useAppStore((state) => state.mapping.mappings)
	const data = mappings[node.id]
	const { t } = useAppContext()

	if (!data) return null

	return (
		<StyledContainer>
			<Form<OthersValues>
				onChange={onChange}
				defaultValues={data.form}
				disabled={!editMode}
				enableFieldHighlight
			>
				<AceEditorField
					name="outWhere"
					title={t('MAPPING_OUT_WHERE')}
					initialValue={data.form.outWhere}
				/>
				<TextAreaFormField
					name="outWhereDescription"
					title={t('MAPPING_FIELD_DESCRIPTION', [t('MAPPING_OUT_WHERE')])}
					initialValue={data.form.outWhereDescription}
				/>
				<AceEditorField
					name="sourceHints"
					title={t('MAPPING_SOURCE_HINTS')}
					initialValue={data.form.sourceHints}
				/>
				<TextAreaFormField
					name="sourceHintsDescription"
					title={t('MAPPING_FIELD_DESCRIPTION', [t('MAPPING_SOURCE_HINTS')])}
					initialValue={data.form.sourceHintsDescription}
				/>
				<AceEditorField
					name="targetHints"
					title={t('MAPPING_TARGET_HINTS')}
					initialValue={data.form.targetHints}
				/>
				<TextAreaFormField
					name="targetHintsDescription"
					title={t('MAPPING_FIELD_DESCRIPTION', [t('MAPPING_TARGET_HINTS')])}
					initialValue={data.form.targetHintsDescription}
				/>
				<AceEditorField
					name="preMappingScript"
					title={t('MAPPING_PRE_MAPPING_SCRIPT')}
					initialValue={data.form.preMappingScript}
				/>
				<TextAreaFormField
					name="preMappingScriptDescription"
					title={t('MAPPING_FIELD_DESCRIPTION', [
						t('MAPPING_PRE_MAPPING_SCRIPT'),
					])}
					initialValue={data.form.preMappingScriptDescription}
				/>
				<AceEditorField
					name="postMappingScript"
					title={t('MAPPING_POST_MAPPING_SCRIPT')}
					initialValue={data.form.postMappingScript}
				/>
				<TextAreaFormField
					name="postMappingScriptDescription"
					title={t('MAPPING_FIELD_DESCRIPTION', [
						t('MAPPING_POST_MAPPING_SCRIPT'),
					])}
					initialValue={data.form.postMappingScriptDescription}
				/>
				<AceEditorField
					name="mappingContextScript"
					title={t('MAPPING_CONTEXT_SCRIPT')}
					initialValue={data.form.mappingContextScript}
				/>
				<TextAreaFormField
					name="mappingContextScriptDescription"
					title={t('MAPPING_FIELD_DESCRIPTION', [t('MAPPING_CONTEXT_SCRIPT')])}
					initialValue={data.form.mappingContextScriptDescription}
				/>
				<AceEditorField
					name="mappingDeleteContextScript"
					title={t('MAPPING_DELETE_CONTEXT_SCRIPT')}
					initialValue={data.form.mappingDeleteContextScript}
				/>
				<TextAreaFormField
					name="mappingDeleteContextScriptDescription"
					title={t('MAPPING_FIELD_DESCRIPTION', [
						t('MAPPING_DELETE_CONTEXT_SCRIPT'),
					])}
					initialValue={data.form.mappingDeleteContextScriptDescription}
				/>
			</Form>
		</StyledContainer>
	)
}

const StyledContainer = styled(MainContentWrap)`
	textarea {
		border-radius: 0;
	}
`
