import {
	BaseQueryFn,
	createApi,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'

import { appConfig } from '@/config'

const dynamicBaseQuery: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, WebApi, extraOptions) => {
	const rawBaseQuery = fetchBaseQuery({
		baseUrl: appConfig.apiUrl,
		prepareHeaders: (headers) => {
			const tokenFromStorage = localStorage.getItem('AUTH_TOKEN')
			const parsedToken = JSON.parse(tokenFromStorage as string).token
			headers.set('X-TenantId', appConfig.realm)
			if (parsedToken) {
				headers.set('authorization', `Bearer ${parsedToken}`)
			}
			return headers
		},
	})
	return rawBaseQuery(args, WebApi, extraOptions)
}

export const emptySplitApi = createApi({
	baseQuery: dynamicBaseQuery,
	endpoints: () => ({}),
})
