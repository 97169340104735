import { mddApiSliceGenerated } from './mddApiSliceGenerated'

export const mddApi = mddApiSliceGenerated.enhanceEndpoints({
	addTagTypes: ['Templates'],
	endpoints: {
		createTemplate: {
			invalidatesTags: ['Templates'],
		},
		getAllTemplates: {
			providesTags: ['Templates'],
		},
		updateTemplate: {
			invalidatesTags: ['Templates'],
		},
		deleteTemplate: {
			invalidatesTags: ['Templates'],
		},
		getTemplate: {
			providesTags: ['Templates'],
		},
		lock: {
			invalidatesTags: ['Templates'],
		},
		unlock: {
			invalidatesTags: ['Templates'],
		},
		push: {
			invalidatesTags: ['Templates'],
		},
	},
})

export const {
	useCheckStructureQuery,
	useCreateTemplateMutation,
	useDeleteTemplateMutation,
	useGetAllNodeHistoryQuery,
	useGetAllTemplatesQuery,
	useGetEnvironmentsWithLastDeploymentPerObjectTypeQuery,
	useGetInitInfoQuery,
	useGetNodeDetailQuery,
	useGetNodeRelationsQuery,
	useGetStereotypesQuery,
	useGetSystemUsersQuery,
	useGetTemplateQuery,
	useLockMutation,
	usePushMutation,
	useSearchFullTextJsonQuery,
	useUnlockMutation,
	useUpdateTemplateMutation,
	useAdjustTableNameMutation,
} = mddApi
