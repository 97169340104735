import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface DependencyLinkPathProps {
	color: string
	fullPath: string | undefined
	icon: IconDefinition
}

export const DependenciesLinkPath = ({
	color,
	icon,
	fullPath,
}: DependencyLinkPathProps) => {
	return (
		<div className="flex items-center mx-2">
			<FontAwesomeIcon icon={icon} style={{ marginRight: '4px', color }} />
			{fullPath}
		</div>
	)
}
