import { MRT_ColumnDef } from 'material-react-table'
import { Control, FieldValues } from 'react-hook-form'

import { ItemPropertyType } from '@/enums'
import { TableColumnFlat } from '@/store/modules/table/types'

import { fieldTypeRender } from './helpers'

interface FormFieldRendererProps<T extends object> {
	control: Control<FieldValues, any>
	fieldName: string
	fieldProp: MRT_ColumnDef<T>
	selectOptions: TableColumnFlat[] | undefined
}

export const FormFieldRenderer = <T extends object>({
	fieldProp,
	fieldName,
	control,
	selectOptions,
}: FormFieldRendererProps<T>) => {
	const getFieldType = () => {
		if (!fieldProp.meta?.type && fieldProp.meta?.type !== 0)
			return ItemPropertyType.HIDDEN

		return fieldProp.meta?.type
	}
	const fieldType = getFieldType()

	const FormFieldSwitch = fieldTypeRender(fieldType)

	return (
		<div className="mt-6 mb-4">
			<FormFieldSwitch
				fieldProp={fieldProp}
				fieldName={fieldName}
				control={control}
				selectOptions={selectOptions}
			/>
		</div>
	)
}
