import { ModalForm } from '@/components/Modal/ModalForm'
import { ApiHookCallback, useApiRequest } from '@/endpoints/hooks'
import { useAppContext } from '@/hooks'

type RemoveModalProps = {
	callback?: (id: number, ...params: any) => ApiHookCallback<void>
	deleteMutation?: ({ id }: { id: number }) => Promise<void>
	header: string
	isTemplate?: boolean
	onClose: () => void
	onRemove?: () => void
	params?: any[]
	removeId: number | undefined
	styles?: React.CSSProperties
	textOnModal: string
}

export const RemoveModal = ({
	callback,
	header,
	isTemplate = false,
	onClose,
	onRemove,
	params,
	removeId,
	styles = { width: '400px' },
	textOnModal,
	deleteMutation,
}: RemoveModalProps) => {
	const { t } = useAppContext()
	const request = useApiRequest()

	const handleSubmit = async () => {
		if (!removeId) return

		if (isTemplate) {
			await deleteMutation?.({ id: removeId })
			onClose()
			return
		}

		if (callback) {
			await request(callback(removeId, ...(params ?? [])))
		}

		onRemove?.()
		onClose()
	}

	return (
		<ModalForm<FormData>
			open={true}
			onClose={onClose}
			saveTitle={t('REMOVE')}
			saveSchema="danger"
			contentStyle={styles}
			header={header}
			onSubmit={handleSubmit}
		>
			{textOnModal}
		</ModalForm>
	)
}
