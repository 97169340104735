import { useCallback, useMemo } from 'react'

import { useAppDispatch, useAppStore } from '@/hooks'
import { StructureDetailDto } from '@/rtkApi/mddApiSliceGenerated'
import { loadNode } from '@/store/modules/node/general-actions'
import { openTab } from '@/store/modules/tab/actions'

export const useOpenTab = () => {
	const nodes = useAppStore((state) => state.node.nodes)
	const dispatch = useAppDispatch()

	const getNodeById = async (nodeId: number) => {
		const existingNodeId = nodes[nodeId]?.id

		if (existingNodeId) {
			return nodes[nodeId]
		}

		return await dispatch(loadNode(nodeId))
	}

	const openTabById = useCallback(async (nodeId: number) => {
		const nodeToOpen = await getNodeById(nodeId)
		dispatch(openTab(nodeToOpen as StructureDetailDto, false))
	}, [])

	const result = useMemo(() => ({ openTabById }), [openTabById])

	return result
}
