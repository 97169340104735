export default {
	primary: '#265560',
	secondary: '#398291',
	info: '#265560',
	success: '#37B479',
	danger: '#DB433A',
	warn: '#FAA94B',
	highlight: '#2684ff',
	frozen: '#727272FF',
}
