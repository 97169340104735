import { CoreRow } from '@tanstack/react-table'
import { useMemo } from 'react'
import { components, OptionProps } from 'react-select'
import {
	getAvailableStructureForSourceObject,
	getExistingStructuresForSourceMapping,
} from 'src/endpoints'

import { useApi } from '@/endpoints/hooks'
import { StructureObjectDto } from '@/endpoints/models'
import { SourceEnums, SourceItem } from '@/endpoints/schemas'
import { ItemPropertyType } from '@/enums'
import { useAppContext } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab/context/DetailTabContext'
import { useGetSystemUsersQuery } from '@/rtkApi'
import { TableColumnDefinition } from '@/types'

import { getAvailableStructures } from './getAvailableStructures'
import { useSourceOptions } from './useSourceOptions'

const { Option } = components

export const useSourceItemProperties =
	(): TableColumnDefinition<StructureObjectDto>[] => {
		const { t } = useAppContext()

		const {
			state: { node, systemNodeId, editMode },
		} = useDetailTabContext()

		const systemTables = useApi(
			editMode
				? getAvailableStructureForSourceObject(node.id, 'SYSTEM')
				: getExistingStructuresForSourceMapping(node.id, 'SYSTEM'),
		)

		const { data: systemUsers, isLoading: isSystemUserLoading } =
			useGetSystemUsersQuery({
				structureId: systemNodeId,
			})

		const { sourceObjectTypes, sourceStereotypes } = useSourceOptions()

		// Custom Option component that shows the name in bold and the full path in regular text with a tooltip
		const CustomOption = (props: OptionProps<any>) => {
			return (
				<Option {...props}>
					<div className="flex flex-col">
						<div className="font-bold">{props.data.name}</div>
						<div title={props.data.fullPath} className="text-sm">
							{props.data.fullPath}
						</div>
					</div>
				</Option>
			)
		}

		const sourceItemProperties = useMemo(
			() => [
				{
					accessorKey: 'stereotype',
					header: t('MAPPING_SOURCES_STEREOTYPE'),
					type: ItemPropertyType.OPTION,
					options: sourceStereotypes || [],
					optionValue: 'value',
					optionLabel: 'label',
					isNumeric: true,
					size: 150,
				},
				{
					accessorKey: 'objectType',
					header: t('MAPPING_SOURCES_OBJECT_TYPE'),
					type: ItemPropertyType.OPTION,
					options: sourceObjectTypes || [],
					optionValue: 'value',
					optionLabel: 'label',
					isNumeric: true,
					size: 150,
				},
				{
					accessorKey: 'objectCode',
					header: t('MAPPING_SOURCES_OBJECT'),
					type: ItemPropertyType.OPTION,
					options: (row: CoreRow<SourceItem>) =>
						getAvailableStructures(systemTables.data, row),
					optionValue: 'code',
					optionLabel: 'fullPath',
					isNumeric: true,
					isLoading: systemTables.loading,
					minSize: 150,
					size: 200,
					disabled: (row: CoreRow<SourceItem>) =>
						row?.original.objectType === SourceEnums.ObjectTypeEnum.SUB_SELECT,
					customOption: CustomOption as any,
				},
				{
					accessorKey: 'ownerId',
					header: t('NODE_OWNER'),
					type: ItemPropertyType.OPTION,
					options: systemUsers || [],
					optionValue: 'id',
					optionLabel: 'name',
					isLoading: isSystemUserLoading,
					isNumeric: true,
					minSize: 150,
					size: 200,
				},
				{
					accessorKey: 'objectCustom',
					header: t('SOURCE_SUBSELECT'),
					type: ItemPropertyType.ACE_EDITOR,
					size: 200,
					disabled: (row: CoreRow<SourceItem>) =>
						row.original.objectType !== SourceEnums.ObjectTypeEnum.SUB_SELECT,
				},
				{
					accessorKey: 'alias',
					header: t('MAPPING_SOURCES_ALIAS'),
					type: ItemPropertyType.TEXT,
					size: 200,
				},
				{
					accessorKey: 'expression',
					header: t('MAPPING_SOURCES_EXPRESSION'),
					type: ItemPropertyType.ACE_EDITOR,
					size: 200,
				},
				{
					accessorKey: 'sourceContext',
					header: t('MAPPING_SOURCES_SOURCE_CONTEXT'),
					type: ItemPropertyType.TEXTAREA,
					size: 200,
				},
				{
					accessorKey: 'rightAction',
					header: 'Actions',
					type: ItemPropertyType.RIGHT_ACTION,
					size: 70,
					maxSize: 90,
					hideFilter: true,
				},
			],
			[
				t,
				sourceStereotypes,
				sourceObjectTypes,
				systemUsers,
				systemTables.loading,
				systemTables.data,
			],
		)

		return sourceItemProperties
	}
