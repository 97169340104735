import { Form, SelectFormField, TextFormField } from '@/components/UberForm'
import { RelationDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import StatusEnum = RelationDto.StatusEnum

export type DependenciesFilterValues = {
	name?: string
	relationType?:
		| 'MAPPING_WRITE'
		| 'PK_FK_TABLE'
		| 'PK_FK_COLUMN'
		| 'LINK'
		| 'LINK_COLUMN'
		| 'CUSTOM_LINK'
		| undefined
	status?: StatusEnum | undefined
}

type DependenciesFilterProps = {
	onChangeFilter: (values: DependenciesFilterValues) => void
	statusStates: { label: string; value: string }[]
	typeStates: { label: string; value: string }[]
}

export const DependenciesFilter = ({
	onChangeFilter,
	statusStates,
	typeStates,
}: DependenciesFilterProps) => {
	const { t } = useAppContext()

	return (
		<Form<DependenciesFilterValues> onChange={onChangeFilter}>
			<div className="max-w-4xl mb-4 grid grid-cols-3 gap-4">
				<div className="min-w-60">
					<TextFormField title={t('NAME')} name="name" />
				</div>

				<div className="min-w-60">
					<SelectFormField
						title={t('TYPE')}
						name="relationType"
						options={typeStates}
						clearable
					/>
				</div>

				<div className="min-w-60">
					<SelectFormField
						title={t('STATUS')}
						name="status"
						options={statusStates}
						clearable
					/>
				</div>
			</div>
		</Form>
	)
}
