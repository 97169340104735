import { MRT_RowData } from 'material-react-table'
import { useEffect, useState } from 'react'

import { getInitCellValue, MrtCellProps } from '@/components'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { ModalForm } from '@/components/Modal/ModalForm'
import { useAppContext } from '@/hooks'
import { cn } from '@/utils/cn'

import { CopyTextButton } from './CopyTextButton'

export const ScriptModalCell = <TData extends MRT_RowData>({
	cell,
	column,
	isDisabled = false,
	row,
	updateCellValue,
	customValue,
}: MrtCellProps<TData>) => {
	const { t } = useAppContext()
	const [isModalOpen, setModalOpen] = useState(false)
	const [aceValue, setAceValue] = useState<string>('')

	const onSubmit = () => {
		setModalOpen(false)
		updateCellValue?.(row?.index as number, column?.id as string, aceValue)
	}

	useEffect(() => {
		setAceValue(getInitCellValue(customValue, cell))
	}, [cell?.getValue()])

	const className = cn('h-full w-full', {
		'disabled:text-gray-400 disabled:cursor-not-allowed': isDisabled,
	})

	return (
		<>
			<input
				style={{ border: 'none' }}
				className={className}
				type="text"
				onClick={() => setModalOpen(true)}
				value={aceValue}
				disabled={isDisabled}
			/>

			<ModalForm
				open={isModalOpen}
				onClose={() => setModalOpen(false)}
				onSubmit={onSubmit}
				header={column?.header}
				contentStyle={{ width: '31.25rem' }}
				hasSubmitButton={!isDisabled}
				cancelTitle={isDisabled ? t('CANCEL') : t('CLOSE')}
				footerBefore={<CopyTextButton text={aceValue} />}
			>
				<AceEditorField
					name="scriptModal"
					value={aceValue}
					onChange={(value) => {
						setAceValue(value as string)
					}}
					onKeyPress={({ key, metaKey }) => {
						if (metaKey && key === 'Enter') {
							onSubmit()
						}
					}}
					autoFocus
					height={250}
					disabled={isDisabled}
				/>
			</ModalForm>
		</>
	)
}
