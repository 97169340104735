import { v4 as uuidv4 } from 'uuid'

import { TableColumnFlat } from '@/store/modules/table/types'

export const createDefaultColumn = (code: string): TableColumnFlat => ({
	code,
	name: `${code} (Deleted)`, // Indicate deletion
	id: -1, // Use a sentinel value. This won't conflict with real IDs.
	comment: '',
	customAttributes: [],
	dataType: '',
	defaultValue: '',
	description: '',
	domainCode: '',
	domainId: 0,
	domainName: '',
	notNullFlag: false,
	order: -1,
	stereotypeCode: '',
	stereotypeColumn: false,
	stereotypeId: 0,
	stereotypeName: '',
	uuid: '',
})

export const generateColumnOptions = (
	columnCode: string,
	columns: TableColumnFlat[],
	fallbackColumns: TableColumnFlat[],
): TableColumnFlat[] => {
	if (!columnCode) {
		return fallbackColumns
	}

	const selectedColumn =
		columns.find((c) => c.code === columnCode) ??
		createDefaultColumn(columnCode)

	return [selectedColumn, ...fallbackColumns]
}
/**
 * Normalizes backend data for the table.
 * Ensures each row has all required fields and a unique identifier.
 * Removes unwanted or invalid rows from the data.
 * Assigns new unique ids to duplicate rows by increasing the highest current id.
 */
export const normalizeTableData = <T extends { id?: number; uuid?: string }>(
	data: T[],
): T[] => {
	if (!Array.isArray(data)) return []

	const seenIds = new Set()
	const seenUUIDs = new Set()
	let highestId = 0

	return data.map((row) => {
		// Ensure numeric ID
		let id = row.id || ++highestId
		highestId = Math.max(highestId, id)

		// Ensure UUID
		let uuid = row.uuid || uuidv4() // Assign new UUID if missing

		// Handle potential duplicates (optional, just like before)
		while (seenIds.has(id)) id = ++highestId // Increment numeric ID if duplicate
		while (seenUUIDs.has(uuid)) uuid = uuidv4() // Generate new UUID if duplicate

		seenIds.add(id)
		seenUUIDs.add(uuid)

		return {
			...row,
			id,
			// uuid,
		}
	})
}
