import {
	faArrowLeft,
	faArrowRight,
	faUnlink,
} from '@fortawesome/free-solid-svg-icons'

import { useOpenTab } from '@/pages/User/pages/Home/components/Dependencies/hooks/useOpenTab'
import { RelationDto } from '@/rtkApi/mddApiSliceGenerated'
import { colors } from '@/styles'
import { cn } from '@/utils/cn'

import { type DependencyLinkCellProps, type ResolveLinkProps } from '../types'
import { DependenciesLinkPath } from './DependenciesLinkPath'

const resolveLinkProperties = ({
	leftSideStructure,
	nodeId,
	rightSideStructure,
	status,
}: ResolveLinkProps) => {
	const DEFAULT_COLOR = colors.info.base
	const SELECTED_COLOR = '#3387ee'

	if (status === 'BROKEN') {
		return {
			id: leftSideStructure?.id,
			fullPath: leftSideStructure?.fullPath,
			icon: faUnlink,
			color: 'red',
		}
	}

	if (rightSideStructure?.id === nodeId) {
		return {
			id: leftSideStructure?.id,
			fullPath: leftSideStructure?.fullPath,
			icon: faArrowLeft,
			color: SELECTED_COLOR,
		}
	}

	return {
		id: rightSideStructure?.id,
		fullPath: rightSideStructure?.fullPath,
		icon: faArrowRight,
		color: DEFAULT_COLOR,
	}
}

export const DependenciesLinkCell = <T extends RelationDto>({
	row,
	node,
}: DependencyLinkCellProps<T>) => {
	const rowData = row.original
	const { rightSideStructure, leftSideStructure, status } = rowData

	const { openTabById } = useOpenTab()

	const { id, fullPath, icon, color } = resolveLinkProperties({
		leftSideStructure,
		nodeId: node?.id,
		rightSideStructure,
		status,
	})

	const className = cn(
		`flex ${status === 'BROKEN' ? 'cursor-not-allowed' : 'cursor-pointer'}`,
	)

	return (
		<div
			className={className}
			title={fullPath}
			onClick={() => openTabById(id as number)}
		>
			<DependenciesLinkPath color={color} icon={icon} fullPath={fullPath} />
		</div>
	)
}
