import { StructureObjectDto as StructureObjectDtoOld } from '@/endpoints/models'
import { DiagramDetail } from '@/pages/User/pages/Home/pages'

import { useFormHighlightsData } from '../../../hooks/useFormHighlightsData'
import { ApiCollection } from '../../../pages/ApiCollection/ApiCollection'
import { ApiNode } from '../../../pages/ApiNode/ApiNode'
import { FolderDetail } from '../../../pages/FolderDetail/FolderDetail'
import { MappingDetail } from '../../../pages/MappingDetail/MappingDetail'
import { QueueDetail } from '../../../pages/QueueDetail/QueueDetail'
import { ReadMappingDetail } from '../../../pages/ReadMappingDetail/ReadMappingDetail'
import { SystemDetail } from '../../../pages/SystemDetail/SystemDetail'
import { TableDetail } from '../../../pages/TableDetail/TableDetail'
import { ViewDetail } from '../../../pages/ViewDetail/ViewDetail'
import { WorkflowDetail } from '../../../pages/WorkflowDetail/WorkflowDetail'
import { WorkflowFolderDetail } from '../../../pages/WorkflowFolderDetail/WorkflowFolderDetail'
import { WriteMappingDetail } from '../../../pages/WriteMappingDetail/WriteMappingDetail'
import { useDetailTabContext } from '../context/DetailTabContext'

export const DetailTabSwitcher = () => {
	const {
		state: { node },
	} = useDetailTabContext()

	useFormHighlightsData()

	const getTabDetail = () => {
		switch (node.type) {
			case StructureObjectDtoOld.TypeEnum.API_COLLECTION: {
				return <ApiCollection />
			}

			case StructureObjectDtoOld.TypeEnum.API_NODE: {
				return <ApiNode />
			}

			case StructureObjectDtoOld.TypeEnum.FOLDER: {
				return <FolderDetail />
			}

			case StructureObjectDtoOld.TypeEnum.FOLDER_WORKFLOW: {
				return <WorkflowFolderDetail />
			}

			case StructureObjectDtoOld.TypeEnum.MAPPING: {
				return <MappingDetail />
			}

			case StructureObjectDtoOld.TypeEnum.MAPPING_DEFAULT: {
				return <MappingDetail defaultMapping />
			}

			case StructureObjectDtoOld.TypeEnum.MAPPING_READ: {
				return <ReadMappingDetail />
			}

			case StructureObjectDtoOld.TypeEnum.MAPPING_WRITE: {
				return <WriteMappingDetail />
			}

			case StructureObjectDtoOld.TypeEnum.QUEUE: {
				return <QueueDetail />
			}

			case StructureObjectDtoOld.TypeEnum.SYSTEM: {
				return <SystemDetail />
			}

			case StructureObjectDtoOld.TypeEnum.TABLE: {
				return <TableDetail />
			}

			case StructureObjectDtoOld.TypeEnum.VIEW: {
				return <ViewDetail />
			}

			case StructureObjectDtoOld.TypeEnum.WORKFLOW: {
				return <WorkflowDetail />
			}

			case 'DIAGRAM': {
				return <DiagramDetail />
			}
		}
	}

	return <>{getTabDetail()}</>
}
