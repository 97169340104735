import {
	faDatabase,
	faDownload,
	faServer,
	faTimes,
} from '@fortawesome/free-solid-svg-icons'

import { Button } from '@/components'
import { useAppContext } from '@/hooks'

interface FooterProps {
	deploying: boolean
	handleDeploy: () => void
	handleDownload: () => void
	onClose: () => void
	preview: boolean | undefined
	pushToGit: () => void
	pushingToGit: boolean
}

export const Footer = ({
	preview,
	handleDeploy,
	deploying,
	handleDownload,
	pushToGit,
	pushingToGit,
	onClose,
}: FooterProps) => {
	const { t } = useAppContext()

	return (
		<>
			{!preview && (
				<Button onClick={handleDeploy} icon={faDatabase} isLoading={deploying}>
					{t('MARK_AS_DEPLOYED')}
				</Button>
			)}
			<Button icon={faDownload} onClick={handleDownload}>
				{t('DOWNLOAD')}
			</Button>
			{!preview && (
				<Button onClick={pushToGit} icon={faServer} isLoading={pushingToGit}>
					{t('PUSH_TO_GIT')}
				</Button>
			)}
			<Button onClick={onClose} schema="transparent" icon={faTimes}>
				{t('CLOSE')}
			</Button>
		</>
	)
}
