import { TableFields } from './pages/types'

/** Disabled fields for history & reference modes */
export const TABLE_MODE_DISABLED_FIELDS: TableFields = [
	'stereotypeId',
	'stereotypeCode',
	'sequenceCode',
	'retentionStrategyFullHistoryValue',
	'retentionStrategyEomValue',
]

export const TABLE_MODE_DISABLED_FIELDS_GENERATED: TableFields = [
	'name',
	'code',
]
