import { Checkbox, FormControlLabel } from '@mui/material'

import { Modal } from '@/components'
import Button from '@/components/Button/Button'
import { useAppContext } from '@/hooks'
import { TemplateConfirmModalProps } from '@/pages/User/pages/Settings/pages/Templates/components/types'

export const TemplateConfirmModal = ({
	isOpen,
	onConfirmClose,
	handleUnlockOnCancel,
	unlockOnCancel,
}: TemplateConfirmModalProps) => {
	const { t } = useAppContext()

	return (
		<Modal
			bodyStyle={{ alignItems: 'center', display: 'flex' }}
			contentStyle={{ height: '230px' }}
			header={<h1 className="text-center">Confirm close</h1>}
			open={isOpen}
			footer={() => (
				<section className="w-full flex gap-4 justify-end">
					<Button schema="danger" onClick={onConfirmClose}>
						{'CLOSE'}
					</Button>
					<Button schema="default" onClick={onConfirmClose}>
						{t('CANCEL')}
					</Button>
				</section>
			)}
		>
			<section>
				<p>{t('CONFIRM_CLOSE_MESSAGE')}</p>
				<div className="mt-5">
					<FormControlLabel
						control={
							<Checkbox
								onChange={handleUnlockOnCancel}
								checked={unlockOnCancel}
							/>
						}
						label={t('UNLOCK_TEMPLATE')}
					/>
				</div>
			</section>
		</Modal>
	)
}
