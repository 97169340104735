import { MRT_Row, MRT_RowData } from 'material-react-table'

import { SelectWithoutForm } from '@/components/UberForm/Input/Select/Select'

import { MrtCellProps } from '../types'

export const SelectCell = <TData extends MRT_RowData>({
	cell,
	column,
	row,
	updateCellValue,
	isLoading,
	isMulti = false,
	isDisabled,
}: MrtCellProps<TData>) => {
	const customStyles = { borderWidth: '0px', background: 'none' }
	const { selectOptions, optionValue, optionLabel, isNumeric } =
		cell?.column.columnDef?.meta || {}

	const isFunction = typeof selectOptions === 'function'
	const options = isFunction
		? selectOptions(cell?.row as MRT_Row<TData>)
		: selectOptions

	const onChange = (value: unknown) => {
		return updateCellValue?.(row?.index as number, column?.id as string, value)
	}

	return (
		<SelectWithoutForm
			name={column?.header}
			options={options}
			onChange={onChange}
			valueKey={optionValue}
			labelKey={optionLabel}
			value={cell?.getValue()}
			customStyles={customStyles}
			isDisabled={isDisabled}
			isNumeric={isNumeric}
			isLoading={isLoading}
			isMulti={isMulti}
		/>
	)
}
