import { MRT_RowData } from 'material-react-table'
import React, { useState } from 'react'

import { TextArea } from '@/components/UberForm'
import { useAppContext } from '@/hooks'
import { cn } from '@/utils/cn'

import { ModalForm } from '../../Modal/ModalForm'
import { type MrtCellProps } from '../types'
import { CopyTextButton } from './CopyTextButton'

export const TextModalCell = <TData extends MRT_RowData>({
	cell,
	row,
	column,
	updateCellValue,
	isDisabled = false,
}: MrtCellProps<TData>) => {
	const { t } = useAppContext()
	const [isModalOpen, setModalOpen] = useState(false)
	const [textAreaValue, setTextAreaValue] = useState<string>(cell?.getValue())

	const onSubmit = () => {
		setModalOpen(false)
		updateCellValue?.(
			row?.index as number,
			column?.id as string,
			textAreaValue.replace(/\n/g, ' '),
		)
	}

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' && e.metaKey) {
			e.preventDefault()
			onSubmit()
		}
	}

	const className = cn('h-full w-full px-1.5 py-2', {
		'disabled:text-gray-400 disabled:cursor-not-allowed': isDisabled,
	})

	return (
		<>
			<input
				value={textAreaValue}
				style={{ border: 'none' }}
				className={className}
				onFocus={() => setModalOpen(true)}
			/>

			<ModalForm
				header={column?.header}
				open={isModalOpen}
				onClose={() => setModalOpen(false)}
				onSubmit={onSubmit}
				hasSubmitButton={!isDisabled}
				cancelTitle={t('CANCEL')}
				footerBefore={<CopyTextButton text={textAreaValue} />}
			>
				<TextArea
					onChange={(value) => setTextAreaValue(value as string)}
					disabled={isDisabled}
					className="textInputWithoutBorder"
					value={textAreaValue}
					autoFocus={true}
					onKeyDown={onKeyDown}
					rows={17}
				/>
			</ModalForm>
		</>
	)
}
