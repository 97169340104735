import { MRT_Row } from 'material-react-table'

import { StructureExportDto, StructureObjectDto } from '@/endpoints/models'
import { SourceEnums } from '@/endpoints/schemas'

export const getAvailableStructures = (
	item?: StructureObjectDto[],
	row?: MRT_Row<any>,
) => {
	if (!item) {
		return []
	}

	const objectType = row?.original.objectType

	switch (objectType) {
		case SourceEnums.ObjectTypeEnum.TABLE:
			return item.filter((c) => c?.type === StructureExportDto.TypeEnum.TABLE)
		case SourceEnums.ObjectTypeEnum.VIEW:
			return item.filter((c) => c?.type === StructureExportDto.TypeEnum.VIEW)
		case SourceEnums.ObjectTypeEnum.DUMMY:
			return item.filter(
				(c) =>
					c?.type === StructureExportDto.TypeEnum.TABLE ||
					c?.type === StructureExportDto.TypeEnum.VIEW,
			)
		default:
			return []
	}
}
