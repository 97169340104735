import { ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

/**
 * A helper utility function for combining class values and performs tailwind CSS merge operation.
 * It uses 'clsx' for combining class names and 'twMerge' for merging tailwind classes.
 *
 * @module cn
 * @param {...ClassValue} inputs - Multiple ClassValue arguments can be passed, each ClassValue argument represents the CSS class names or tailwind CSS classes.
 * @returns {string} - The final combined and merged string of CSS class names.
 *
 * @example
 * ```typescript
 * const classes = cn('bg-red-500', 'hover:bg-blue-500');
 * console.log(classes); // This will log the combined string of classes (e.g., "bg-red-500 hover:bg-blue-500")
 * ```
 *
 * @see {@link https://www.npmjs.com/package/clsx}
 * @see {@link https://www.npmjs.com/package/tailwind-merge}
 */
export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}
