import { Checkbox, FormControlLabel } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useMemo } from 'react'
import { Controller } from 'react-hook-form'

import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { SelectFormField } from '@/components/UberForm'
import ArrayFormField from '@/components/UberForm/arrayField/ArrayFormField'
import { InputTypeEnum } from '@/components/UberForm/arrayField/types'
import NumberFormField from '@/components/UberForm/Helpers/NumberFormField'
import { CustomAttributeComboDto, TableColumn } from '@/endpoints/models'
import { PageType } from '@/enums'
import { useAppContext } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'

import { Wrapper } from './styles'
import { NewCustomAttributesSwitcherProps } from './types'
import { getCustomAttributeName } from './utils'

export const NewCustomAttributesSwitcher = <T extends TableColumn>({
	field,
	$pageType,
	control,
	index,
}: NewCustomAttributesSwitcherProps<T>) => {
	const { t } = useAppContext()
	const {
		state: { editMode },
	} = useDetailTabContext()

	const sharedProps = useMemo(
		() => ({
			name: getCustomAttributeName($pageType, field, undefined, index),
			title: `${field.name}`,
		}),
		[$pageType, field, index],
	)

	switch (field.type) {
		case CustomAttributeComboDto.TypeEnum.BOOLEAN: {
			return $pageType === PageType.OVERVIEW_PAGE ? (
				<Wrapper>
					<Controller
						control={control}
						name={sharedProps.name}
						render={({ field }) => {
							return (
								<FormControlLabel
									control={<Checkbox disabled={!editMode} {...field} />}
									label={sharedProps.title}
								/>
							)
						}}
					/>
				</Wrapper>
			) : (
				<Controller
					control={control}
					name={sharedProps.name}
					render={({ field }) => (
						<FormControlLabel
							control={
								<Checkbox
									disabled={!editMode}
									{...field}
									checked={field.value}
								/>
							}
							label={sharedProps.title}
						/>
					)}
				/>
			)
		}

		case CustomAttributeComboDto.TypeEnum.NUMBER: {
			return (
				<Controller
					control={control}
					name={sharedProps.name}
					render={({ field }) => (
						<NumberFormField disabled={!editMode} {...sharedProps} {...field} />
					)}
				/>
			)
		}

		case CustomAttributeComboDto.TypeEnum.SCRIPT: {
			return (
				<Controller
					control={control}
					name={sharedProps.name}
					render={({ field }) => (
						<AceEditorField disabled={!editMode} {...sharedProps} {...field} />
					)}
				/>
			)
		}

		case CustomAttributeComboDto.TypeEnum.ARRAY: {
			return (
				<Controller
					control={control}
					name={sharedProps.name}
					render={({ field }) => (
						<ArrayFormField
							disabled={!editMode}
							fields={[
								{
									name: 'value',
									inputType: InputTypeEnum.TEXT,
									placeholder: t('VALUE'),
								},
							]}
							initialValue={field.value}
							{...sharedProps}
							{...field}
						/>
					)}
				/>
			)
		}

		case CustomAttributeComboDto.TypeEnum.LOV: {
			return <SelectFormField {...sharedProps} options={field.lovValues} />
		}

		case CustomAttributeComboDto.TypeEnum.STRING: {
			return (
				<Controller
					control={control}
					name={sharedProps.name}
					render={({ field }) => (
						<TextField
							{...field}
							disabled={!editMode}
							fullWidth
							label={sharedProps.title}
							size="small"
							value={field.value ?? ''}
						/>
					)}
				/>
			)
		}

		default: {
			console.error('Unknown custom attribute type')

			return <>Unknown custom attribute type</>
		}
	}
}
