import capitalize from 'lodash/capitalize'
import { createMRTColumnHelper, MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'

import {
	getMrtTableDisabled,
	MrtCellProps,
	RowActionsCell,
	SelectCell,
} from '@/components'
import { TablePermission as TablePermissionOld } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'

import { useSystemUsers } from '../../../../utils'
import { TablePermissionExt } from '../types'

const mapStringToOption = (value: string) => ({
	value,
	label: capitalize(value),
})

export const useTablePermissionsColumns = ({
	deleteRow,
	updateCellValue,
}: MrtCellProps<TablePermissionExt>) => {
	const {
		state: { systemNodeId, editMode },
	} = useDetailTabContext()
	const { t } = useAppContext()
	const { data: users } = useSystemUsers(systemNodeId)
	const permissions = Object.values(TablePermissionOld.PermissionsEnum)

	const columnHelper = createMRTColumnHelper<TablePermissionExt>()

	const columns = useMemo(
		() => [
			columnHelper.accessor('id', {
				header: t('SYSTEM_USER'),
				size: 50,
				meta: {
					selectOptions: users ?? [],
					optionValue: 'id',
					optionLabel: 'name',
					isNumeric: true,
				},
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<TablePermissionExt>({
						editMode,
						row,
						column,
					})

					return (
						<SelectCell<TablePermissionExt>
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('permissions', {
				header: t('PERMISSIONS'),
				size: 350,
				meta: {
					selectOptions: permissions.map(mapStringToOption),
					isNumeric: true,
				},
				enableColumnActions: false,
				enableSorting: false,
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<TablePermissionExt>({
						editMode,
						row,
						column,
					})

					return (
						<SelectCell<TablePermissionExt>
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							isDisabled={isDisabled}
							isMulti
						/>
					)
				},
			}),
			columnHelper.accessor('rightAction', {
				header: 'Actions',
				size: 50,
				meta: {
					hideFilter: true,
				},
				enableColumnActions: false,
				enableSorting: false,
				Cell: ({ table, row }) => (
					<RowActionsCell deleteRow={deleteRow} table={table} row={row} />
				),
			}),
		],
		[columnHelper, deleteRow, permissions, t, updateCellValue, users],
	)

	return columns as MRT_ColumnDef<TablePermissionExt>[]
}
