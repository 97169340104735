import { CustomTabPanelProps } from '@/components/Diagram'
import { Box } from '@mui/material'

export const MuiTabPanel = ({
	children,
	value,
	index,
	isTable = false,
	...other
}: CustomTabPanelProps) => {
	const getWrapper = () => {
		if (isTable) {
			return <>{children}</>
		}

		return <Box sx={{ padding: '2rem 1rem' }}>{children}</Box>
	}
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && getWrapper()}
		</div>
	)
}
