import { useMemo } from 'react'

import { StructureDto, StructureObjectDto } from '@/endpoints/models'
import { TableMode } from '@/enums'
import { useAppStore } from '@/hooks'
import { Overview as OverviewApiNode } from '@/pages/User/pages/Home/pages/ApiNode/pages/Overview/Overview'
import { Overview as OverviewFolder } from '@/pages/User/pages/Home/pages/FolderDetail/pages/Overview/Overview'
import { MappingOverview as OverviewMapping } from '@/pages/User/pages/Home/pages/MappingDetail/pages/Overview/MappingOverview'
import { Overview as OverviewQueue } from '@/pages/User/pages/Home/pages/QueueDetail/pages/Overview/Overview'
import { Overview as OverviewReadMapping } from '@/pages/User/pages/Home/pages/ReadMappingDetail/pages/Overview/Overview'
import { Overview as OverviewSystem } from '@/pages/User/pages/Home/pages/SystemDetail/pages/Overview/Overview'
import { Overview as OverviewTable } from '@/pages/User/pages/Home/pages/TableDetail/pages/Overview/Overview'
import { Overview as OverviewView } from '@/pages/User/pages/Home/pages/ViewDetail/pages/Overview/Overview'
import { Overview as OverviewWorkflow } from '@/pages/User/pages/Home/pages/WorkflowDetail/pages/Overview/Overview'
import { Overview as OverviewWriteMapping } from '@/pages/User/pages/Home/pages/WriteMappingDetail/pages/Overview/Overview'
import {
	StructureTypeActions,
	useStructureTypeActions,
} from '@/utils/structureType/useStructureTypeActions'

import { TYPE_TO_SELECTOR } from '../constants'

export type PropsTypeGeneric = {
	[key: string]: any
	data: any
	defaultMapping: boolean
	editMode: boolean
	mode: TableMode
	modeForm: any
	modeFormTable: any
	node?: StructureDto

	onChange: (v: any) => void
	onSync: () => void
	readonly?: boolean
	/** Selected nodes for batch edit */
	selectedNodes?: StructureObjectDto[]
	systemNodeId: number
	unsyncedFields: any[]
}

type FormInfo = {
	FormComponent: (props: PropsTypeGeneric) => JSX.Element
	formData: Record<string, any>
}

type TypeData = FormInfo & StructureTypeActions

/** Get specific node type form data & component and redux actions */
export const useTypeData = (type: StructureObjectDto.TypeEnum) => {
	const selector = useMemo(() => TYPE_TO_SELECTOR[type], [type])
	const formData = useAppStore(selector)
	const structureTypeActions = useStructureTypeActions(type)

	const typeData = useMemo<TypeData>(() => {
		let formInfo: Partial<FormInfo> = {
			FormComponent: OverviewWorkflow,
		}

		switch (type) {
			case StructureObjectDto.TypeEnum.API_NODE:
				formInfo = {
					FormComponent: OverviewApiNode,
				}

				break
			case StructureObjectDto.TypeEnum.FOLDER:
				formInfo = {
					FormComponent: OverviewFolder,
				}

				break
			case StructureObjectDto.TypeEnum.MAPPING:
			case StructureObjectDto.TypeEnum.MAPPING_DEFAULT:
				formInfo = {
					FormComponent: OverviewMapping,
				}

				break
			case StructureObjectDto.TypeEnum.MAPPING_READ:
				formInfo = {
					FormComponent: OverviewReadMapping,
				}

				break
			case StructureObjectDto.TypeEnum.MAPPING_WRITE:
				formInfo = {
					FormComponent: OverviewWriteMapping,
				}

				break
			case StructureObjectDto.TypeEnum.QUEUE:
				formInfo = {
					FormComponent: OverviewQueue,
				}

				break
			case StructureObjectDto.TypeEnum.SYSTEM:
				formInfo = {
					FormComponent: OverviewSystem,
				}

				break
			case StructureObjectDto.TypeEnum.TABLE:
				formInfo = {
					FormComponent: OverviewTable,
				}

				break
			case StructureObjectDto.TypeEnum.VIEW:
				formInfo = {
					FormComponent: OverviewView,
				}

				break
			case StructureObjectDto.TypeEnum.WORKFLOW:
				formInfo = {
					FormComponent: OverviewWorkflow,
				}

				break
			default:
				console.error('Unknown structure type')

				formInfo = {
					FormComponent: OverviewWorkflow,
				}
		}

		return {
			...formInfo,
			...structureTypeActions,
			formData,
		} as TypeData
	}, [formData, structureTypeActions, type])

	return typeData
}
