import {
	faArrowDown,
	faArrowUp,
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { FormlessFormField } from '@/components/UberForm/FormField'
import { CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX } from '@/constants'
import { StructureObjectDto, TableColumn } from '@/endpoints/models'
import { TableData } from '@/endpoints/schemas'
import { ItemPropertyType, PageType, TableMode } from '@/enums'
import {
	FormType,
	useAppContext,
	useAppStore,
	useCustomAttributesInitValues,
} from '@/hooks'
import { CustomAttributes } from '@/pages/User/pages/Home/components/CustomAttributes/CustomAttributes'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab/context/DetailTabContext'
import { clamp } from '@/utils/numbers'

import { Form, Reset } from '../UberForm'
import { PropertyField } from './components/PropertyField'
import {
	ButtonSwitch,
	Container,
	OpenButton,
	OpenButtonIcon,
	OpenButtonText,
	ReadonlyValue,
	Title,
	ToggleButton,
	ValueInput,
	ValueLabel,
	ValueRow,
	Values,
} from './styles'
import { PropertiesProps } from './types'
import { errorGet, parsePanelPropCA, valueGet } from './utils'

export const Properties = <T extends TableColumn>({
	item,
	index,
	properties,
	onChange,
	listOfValues,
	readonly,
	onHide,
	onShow,
	isHidden,
	errors,
	propertiesWidth,
	onSelect,
}: PropertiesProps<T>) => {
	const { t } = useAppContext()
	const refContainer = useRef<HTMLDivElement>(null)
	const [nonUserChange, setNonUserChange] = useState(false)

	const indexCurrent = index ?? -1

	const switcher = (switchIndex: -1 | 1) => {
		if (onSelect && item && listOfValues) {
			if (indexCurrent !== -1) {
				const selected = indexCurrent + switchIndex
				const selectedIndex = clamp(selected, 0, listOfValues.length - 1)
				onSelect(selectedIndex)
			}
		}
	}

	const {
		state: { node },
	} = useDetailTabContext()

	const tables = useAppStore((state) => state.table.tables)
	const table = tables[Number(node.id)]

	const form = table?.form ? table.form : null

	const mode = table ? table.mode : TableMode.TABLE

	const refForm = useRef<Form<TableData>>(null)

	const { customAttributes, customAttributesAll } =
		useCustomAttributesInitValues(
			form as FormType,
			StructureObjectDto.TypeEnum.TABLE_COLUMN,
			mode,
		)

	const getDefaultValues = useMemo(
		() =>
			(item?.customAttributes || [])?.reduce(
				(initialValues: any, customAttribute: any) => {
					const fieldName = `${CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX}.${customAttribute.id}.${item?.code}.${customAttribute.name}`

					return {
						...initialValues,
						[fieldName]: customAttribute.value,
					}
				},
				{} as Record<string, any>,
			) ?? {},
		[item],
	)

	const changeHandler = useCallback(
		(values: any, source?: FormlessFormField<TableData>) => {
			const parsedCustomAttribute = parsePanelPropCA(
				customAttributesAll,
				values,
			)

			return onChange({
				fieldId: item?.id as number,
				fieldName: source?.props.title as string,
				fieldValue: parsedCustomAttribute,
				fieldOptions: {
					isCustomAttribute: true,
				},
				rowIndex: index as number,
			})
		},
		[customAttributesAll, index, item?.id],
	)

	useEffect(() => {
		// Set nonUserChange to true whenever index changes
		setNonUserChange(true)

		// Reset nonUserChange to false after the render is completed
		const timeoutId = setTimeout(() => setNonUserChange(false), 500)

		// Cleanup function
		return () => clearTimeout(timeoutId)
	}, [index])

	return (
		<>
			{isHidden && (
				<OpenButton onClick={onShow} role="button">
					<div>
						<OpenButtonText>{t('PROPERTIES_TITLE')}</OpenButtonText>
						<OpenButtonIcon>
							<FontAwesomeIcon icon={faChevronLeft} color="#888" />
						</OpenButtonIcon>
					</div>
				</OpenButton>
			)}
			<Container $isHidden={!!isHidden} role="complementary" ref={refContainer}>
				<Title onClick={onHide}>
					<ToggleButton>
						<FontAwesomeIcon icon={faChevronRight} color="#888" />
					</ToggleButton>
					{t('PROPERTIES_TITLE')}
				</Title>
				{item && (
					<Values>
						{properties.map((prop) => {
							const value = valueGet(prop, item)
							const error = errorGet(properties, prop, item, errors)

							if (prop.formatter) {
								return (
									<prop.formatter
										key={prop.id}
										item={item}
										readonly={!!readonly}
										fromProps
									/>
								)
							}

							return (
								(typeof prop.condition !== 'function' ||
									prop.condition(item, listOfValues)) &&
								prop.field && (
									<ValueRow
										key={prop.field}
										$valueWidth={prop.propertiesWidth}
										$propertiesWidth={propertiesWidth}
									>
										{prop.type !== ItemPropertyType.CHECKBOX && (
											<ValueLabel>{prop.label}</ValueLabel>
										)}
										<ValueInput>
											{!readonly ? (
												<PropertyField<any>
													prop={prop}
													item={item}
													compact={false}
													listOfValues={listOfValues}
													value={value}
													readonly={!!readonly}
													onChange={onChange}
													fromProps
													error={error}
													nonUserChange={nonUserChange}
												/>
											) : (
												<ReadonlyValue>
													<PropertyField<any>
														prop={prop}
														item={item}
														compact={false}
														value={value}
														listOfValues={listOfValues}
														readonly={readonly}
														onChange={onChange}
														fromProps
														nonUserChange={nonUserChange}
													/>
												</ReadonlyValue>
											)}
										</ValueInput>
									</ValueRow>
								)
							)
						})}

						{node.type === 'TABLE' && (
							<ValueRow $propertiesWidth={propertiesWidth}>
								<Form
									onChange={changeHandler}
									defaultValues={getDefaultValues}
									ref={refForm}
									disabled={readonly}
								>
									<CustomAttributes
										item={item}
										customAttributes={customAttributes}
										$pageType={PageType.COLUMN_PAGE}
									/>
									<Reset
										values={getDefaultValues}
										isColumnPage
										itemId={item.id}
									>
										<></>
									</Reset>
								</Form>
							</ValueRow>
						)}

						{!readonly && (
							<div className="w-full flex pt-2 pb-12 justify-center">
								<ButtonSwitch
									size="sm"
									schema="primary"
									onClick={() => switcher(1)}
									icon={faArrowDown}
									disabled={indexCurrent >= listOfValues.length - 1}
								>
									{t('PROPERTIES_DOWN')}
								</ButtonSwitch>
								<ButtonSwitch
									size="sm"
									schema="primary"
									onClick={() => switcher(-1)}
									icon={faArrowUp}
									disabled={indexCurrent <= 0}
								>
									{t('PROPERTIES_UP')}
								</ButtonSwitch>
							</div>
						)}
					</Values>
				)}
			</Container>
		</>
	)
}
