import styled from '@emotion/styled'

export const FooterContainer = styled.div`
	display: flex;
	gap: 0.75rem;
`

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
`
