import go from 'gojs'
import { useEffect } from 'react'

import { DiagramTab, LinkRoutingType } from '@/enums'
import { useAppContext, useAppDispatch, useAppSelector } from '@/hooks'
import { useGetInitInfoQuery } from '@/rtkApi'
import colors from '@/styles/diagramColors'

import { useDiagramContext } from '../context'
import { toggleGrid } from '../handlers'
import {
	createDiagramMenu,
	setupLinkTemplates,
	useSetupNodeTemplates,
} from '../Templates'

export const useInitDiagram = () => {
	const { t } = useAppContext()
	const { node, setSelectedTab, diagramRef } = useDiagramContext()

	const { data } = useGetInitInfoQuery()

	const thisDiagram = useAppSelector(
		(state) => state.diagram?.diagrams[node?.id as number]?.form.graph,
	)

	const dispatch = useAppDispatch()

	const properties = thisDiagram?.modelData || {}
	const { hasName } = properties?.displayOptions || false
	const { routingType } = properties?.links || {}

	const { cellSize, isVisible } = properties?.grid || {}

	// set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
	go.Diagram.licenseKey = data?.goJSLicenseKey || ''

	const goJsDiagram = new go.Diagram({
		'undoManager.isEnabled': true, // must be set to allow for model change listening
		model: new go.GraphLinksModel({
			// IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
			nodeKeyProperty: 'key',
			linkKeyProperty: 'key',
			modelData: {
				hasConstraintName: hasName,
			},
		}),
	})

	createDiagramMenu(goJsDiagram, t, dispatch)

	const noteTemplates = useSetupNodeTemplates()

	goJsDiagram.nodeTemplateMap = noteTemplates

	goJsDiagram.linkTemplateMap = setupLinkTemplates(
		routingType || LinkRoutingType.Orthogonal,
		setSelectedTab,
	)

	const horizontalLineColor =
		properties?.grid?.lineColors?.horizontal || colors.grid.horizontalLineColor
	const verticalLineColor =
		properties?.grid?.lineColors?.vertical || colors.grid.verticalLineColor
	const intervalHorizontalLineColor =
		properties?.grid?.lineColors?.intervalHorizontal ||
		colors.grid.intervalHorizontalLineColor
	const intervalVerticalLineColor =
		properties?.grid?.lineColors?.intervalVertical ||
		colors.grid.intervalVerticalLineColor

	goJsDiagram.grid = new go.Panel('Grid', {
		gridCellSize: new go.Size(cellSize || 10, cellSize || 10),
	}).add(
		new go.Shape('LineH', {
			name: 'HorizontalLine',
			stroke: horizontalLineColor,
		}),
		new go.Shape('LineV', {
			name: 'VerticalLine',
			stroke: verticalLineColor,
		}),
		new go.Shape('LineH', {
			name: 'IntervalHorizontalLine',
			stroke: intervalHorizontalLineColor,
			interval: 5,
		}),
		new go.Shape('LineV', {
			name: 'IntervalVerticalLine',
			stroke: intervalVerticalLineColor,
			interval: 5,
		}),
	)

	// Handle clicks on the diagram background
	goJsDiagram.addDiagramListener('BackgroundSingleClicked', () => {
		setSelectedTab?.(DiagramTab.Diagram)
	})

	useEffect(() => {
		if (diagramRef) {
			toggleGrid(diagramRef, { grid: { isVisible: isVisible || false } })
		}
	}, [])

	return { initDiagram: () => goJsDiagram }
}
