import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createMRTColumnHelper, MRT_ColumnDef } from 'material-react-table'
import { useCallback, useMemo } from 'react'

import { Button, DialogWrapper, MessageModal, TextCell } from '@/components'
import { ReleaseDto } from '@/endpoints/models'
import { useAppContext, useAppDispatch, useAppStore } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { StructureDto } from '@/rtkApi/mddApiSliceGenerated'
import { findSystemNodeId } from '@/store/modules/node/helpers'
import { selectSystemTab, updateSystem } from '@/store/modules/system/actions'
import { SystemTab } from '@/store/modules/system/types'
import { openTab } from '@/store/modules/tab/actions'
import { NativeMap } from '@/utils'

import { DdlModal } from '../components/DdlModal'
import { ExtEnvironmentDeploymentDto } from '../types'

export const useDeploymentsColumns = () => {
	const { t } = useAppContext()
	const {
		state: { node, editMode },
	} = useDetailTabContext()
	const nodes: NativeMap<StructureDto> = useAppStore(
		(state) => state.node.nodes,
	)
	const dispatch = useAppDispatch()

	const columnHelper = createMRTColumnHelper<ExtEnvironmentDeploymentDto>()

	const onReleaseRedirect = useCallback(
		async (release: ReleaseDto) => {
			const systemId = findSystemNodeId(node, nodes)
			const systemNode = nodes[systemId] as StructureDto
			dispatch(selectSystemTab(systemNode, SystemTab.Releases))
			dispatch(updateSystem(systemNode, { selectedReleaseId: release.id! }))
			dispatch(openTab(systemNode, false))
		},
		[dispatch, node, nodes],
	)

	const columns = useMemo(
		() => [
			columnHelper.accessor('objectType', {
				header: t('OBJECT_TYPE'),
				size: 50,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
						customValue={cell?.getValue()?.name}
						isDisabled={!editMode}
					/>
				),
			}),
			columnHelper.accessor('name', {
				header: t('ENVIRONMENT'),
				size: 50,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
						isDisabled={!editMode}
					/>
				),
			}),
			columnHelper.accessor('lastDeploymentStructureHistoryVersion', {
				header: t('DEPLOYED_VERSION'),
				size: 50,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
						isDisabled={!editMode}
					/>
				),
			}),
			columnHelper.accessor('lastDeploymentAuthorCompositeName', {
				header: t('DEPLOYED_BY'),
				size: 50,
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isReadOnly
						isDisabled={!editMode}
					/>
				),
			}),
			columnHelper.accessor('lastRelease', {
				header: t('LATEST_RELEASE'),
				size: 50,
				Cell: ({ cell, table, row, column }) => {
					const cellNameValue = cell?.getValue()?.name

					return cellNameValue ? (
						<div
							className="cursor-pointer"
							onClick={() => onReleaseRedirect(cell?.getValue() as ReleaseDto)}
						>
							<FontAwesomeIcon icon={faEdit} />
							{cellNameValue}
						</div>
					) : (
						<TextCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							isReadOnly
							isDisabled={!editMode}
						/>
					)
				},
			}),
			columnHelper.accessor('rightAction', {
				header: 'Actions',
				size: 50,
				meta: {
					hideFilter: true,
				},
				enableColumnActions: false,
				enableSorting: false,
				Cell: ({ row }) => {
					return (
						<div className="flex items-center gap-4 ml-4">
							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<>
											<DdlModal
												preview
												environment={row.original}
												node={node}
												onClose={onClose}
											/>
										</>
									)
								}
							>
								{(onClick) => (
									<Button size="sm" onClick={onClick}>
										{t('PREVIEW_DLL')}
									</Button>
								)}
							</DialogWrapper>
							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<>
											<DdlModal
												environment={row.original}
												node={node}
												onClose={onClose}
											/>
											{editMode && (
												<MessageModal
													title={t('DEPLOYED_UNPUSHED_CHANGES_WARN_TITLE')}
													type="warn"
													message={t('DEPLOYED_UNPUSHED_CHANGES_WARN')}
												/>
											)}
										</>
									)
								}
							>
								{(onClick) => (
									<Button size="sm" onClick={onClick}>
										{t('CREATE_DDL')}
									</Button>
								)}
							</DialogWrapper>
						</div>
					)
				},
			}),
		],
		[columnHelper, onReleaseRedirect, t, editMode, node],
	)

	return columns as MRT_ColumnDef<ExtEnvironmentDeploymentDto>[]
}
