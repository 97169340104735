import { Box } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { MaterialReactTable } from 'material-react-table'
import { useEffect, useMemo } from 'react'

import {
	FillColumnsButton,
	muiRowDragHandleProps,
	NewPanelProperties,
	PageWrap,
	TableProvider,
	TableToolbar,
} from '@/components'
import { newMappingRowPayload } from '@/constants'
import { MappingColumn } from '@/endpoints/schemas'
import {
	useAppStore,
	usePanelProperties,
	useResponsiveBreakpoint,
	useTable,
	useTableHandlers,
} from '@/hooks'
import { saveMapping, updateMapping } from '@/store/modules/mapping/actions'

import { useDetailTabContext } from '../../../../components/DetailTab'
import { MAPPING_HIDDEN_COLUMNS, mappingColInitVisibility } from './constants'
import { useMappingColumns } from './hooks'

export const MappingColumnTab = () => {
	const {
		state: { node, editMode },
	} = useDetailTabContext()
	const mappingsData = useAppStore(
		(state) => state.mapping.mappings[Number(node?.id)],
	)

	const { isExtraLargeScreen } = useResponsiveBreakpoint()

	const {
		columnVisibility,
		dataKey,
		deleteRow,
		handleRowClick,
		isUserInteracting,
		setColumnVisibility,
		selectedRow,
		setSelectedRow,
		setTableData,
		tableData,
		updateCellValue,
		updateTableData,
		updateSelectedRow,
	} = useTableHandlers<MappingColumn>({
		initialData: mappingsData?.form.columns || [],
		columnInitVisibility: mappingColInitVisibility,
		reduxActions: {
			saveAction: saveMapping,
			updateAction: updateMapping,
			updateKey: 'columns',
		},
	})

	const {
		isPanelOpen,
		invalidColumnCodes,
		setInvalidColumnCodes,
		parentTableColumns,
		handleClosePanel,
		handleOpenPanel,
	} = usePanelProperties({
		setColumnVisibility,
		columnsHiddenOnOpen: MAPPING_HIDDEN_COLUMNS,
	})

	const availableColumns = useMemo(() => {
		const selectedColumnCodes = tableData?.map(({ columnCode }) => columnCode)
		// Exclude invalid columns from availableColumns
		return parentTableColumns.filter(
			({ code }) => code && !selectedColumnCodes.includes(code),
		)
	}, [parentTableColumns, tableData])

	const columns = useMappingColumns({
		availableColumns,
		deleteRow,
		handleRowClick,
		invalidColumnCodes,
		isPanelOpen,
		parentTableColumns,
		tableData,
		updateCellValue,
	})

	const hasColumns = !isEmpty(availableColumns)
	const disabled = !editMode || !hasColumns

	const table = useTable<MappingColumn>({
		columns,
		data: tableData,
		options: {
			initialState: {
				density: 'compact',
			},
			layoutMode: isPanelOpen ? 'grid' : 'semantic',
			renderBottomToolbar: () => {
				return (
					<TableToolbar<MappingColumn>
						availableColumns={availableColumns}
						updateTableData={updateTableData}
						newRowPayload={newMappingRowPayload}
						tableData={tableData}
						customButtons={
							<FillColumnsButton
								disabled={disabled}
								availableColumns={availableColumns}
								tableData={tableData}
								updateTableData={updateTableData}
							/>
						}
					/>
				)
			},
			state: {
				columnVisibility,
			},
			muiRowDragHandleProps: ({ table }) =>
				muiRowDragHandleProps({ updateTableData, table }),
		},
	})

	useEffect(() => {
		if (tableData && !isEmpty(parentTableColumns)) {
			const existingCodes = tableData.map((row) => row.columnCode)
			const parentCodes = parentTableColumns.map((col) => col.code)
			const newInvalidCodes = existingCodes.filter(
				(code) => code && !parentCodes.includes(code),
			)
			setInvalidColumnCodes(newInvalidCodes)
		}
	}, [tableData, parentTableColumns])

	const isPanelOpenXl = isExtraLargeScreen && !isPanelOpen

	useEffect(() => {
		setColumnVisibility((prevState) => ({
			...(prevState || {}),
			mappingDescription: isPanelOpenXl,
			mappingComment: isPanelOpenXl,
		}))
	}, [isPanelOpenXl])

	return (
		<PageWrap
			onMouseEnter={() => {
				isUserInteracting.current = true
			}}
			hasPropPanel
		>
			<TableProvider<MappingColumn>
				availableColumns={availableColumns}
				invalidColumnCodes={invalidColumnCodes}
				isPanelOpen={isPanelOpen}
				selectedRow={selectedRow}
				setSelectedRow={setSelectedRow}
				setTableData={setTableData}
				tableData={tableData}
				updateTableData={updateTableData}
			>
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						transition: 'margin-right 0.3s ease',
						marginRight: isPanelOpen ? '627px' : '0px',
						zIndex: 20,
						position: 'relative',
					}}
				>
					<MaterialReactTable key={dataKey} table={table} />
				</Box>

				<NewPanelProperties<MappingColumn>
					parentColumns={parentTableColumns}
					formFields={columns}
					updateSelectedRow={updateSelectedRow}
					handleClosePanel={handleClosePanel}
					handleOpenPanel={handleOpenPanel}
				/>
			</TableProvider>
		</PageWrap>
	)
}
