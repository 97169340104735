import { MaterialReactTable } from 'material-react-table'

import { Message, PageWrap } from '@/components'
import { useAppContext, useTable } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { useGetEnvironmentsWithLastDeploymentPerObjectTypeQuery } from '@/rtkApi'

import { useDeploymentsColumns } from './hooks'
import { ExtEnvironmentDeploymentDto } from './types'

export const NewDeployments = () => {
	const { t } = useAppContext()

	const {
		state: { node, editMode },
	} = useDetailTabContext()
	const { data: tableData, isLoading } =
		useGetEnvironmentsWithLastDeploymentPerObjectTypeQuery({
			structureId: node.id,
		})

	const columns = useDeploymentsColumns()

	const table = useTable<ExtEnvironmentDeploymentDto>({
		columns,
		data: tableData || [],
		options: {
			initialState: {
				density: 'compact',
			},
			enableRowOrdering: false,
			state: {
				isLoading,
			},
			muiSkeletonProps: {
				animation: 'pulse',
				height: 28,
			},
		},
	})

	return (
		<PageWrap>
			{editMode && (
				<Message type="warn" message={t('DEPLOYED_UNPUSHED_CHANGES_WARN')} />
			)}
			<MaterialReactTable table={table} />
		</PageWrap>
	)
}
