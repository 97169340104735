import { CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX } from '@/constants'
import { TableColumn } from '@/endpoints/models'
import { PageType } from '@/enums'
import { CustomAttributeComboDto } from '@/rtkApi/mddApiSliceGenerated'

import { CustomFieldProps } from './types'

export const getCustomAttributeName = <T extends TableColumn>(
	type: PageType | undefined,
	field: CustomFieldProps | CustomAttributeComboDto,
	item?: T | undefined,
	index?: number | undefined,
) => {
	switch (type) {
		case PageType.COLUMN_PAGE:
			return `customAttributes.${index}.value`
		default:
			return `${CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX}.${field.id}.${field.name}`
	}
}
