import { useMediaQuery, useTheme } from '@mui/material'

export const useResponsiveBreakpoint = () => {
	const theme = useTheme()

	const isLargeScreen = useMediaQuery(theme.breakpoints.between('lg', 'xl'))
	const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))

	return { isLargeScreen, isExtraLargeScreen }
}
