import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useAppContext, useAppStore } from '@/hooks'
import { type TemplateListDto } from '@/rtkApi/mddApiSliceGenerated'

interface TemplateLockStatusProps {
	row: TemplateListDto
}

export const TemplateLockIcon = ({ row }: TemplateLockStatusProps) => {
	const { user } = useAppStore((state) => state.auth)

	const { t } = useAppContext()
	const isLockedBy = row?.lockedBy?.id
	const currentUserId = user?.id
	const isLockedByCurrentUser = isLockedBy === currentUserId

	if (!isLockedBy) {
		return null
	}

	return (
		<div key={row.id} className="w-full flex justify-center">
			<FontAwesomeIcon
				title={t(isLockedByCurrentUser ? 'LOCKED_BY_YOU' : 'LOCKED_BY_USER')}
				icon={faLock}
				color={isLockedByCurrentUser ? '#37b479' : '#ff4d4f'}
			/>
		</div>
	)
}
