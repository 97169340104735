import { StructureObjectDto as StructureObjectDtoOld } from '@/endpoints/models'
import { StructureDto, StructureObjectDto } from '@/rtkApi/mddApiSliceGenerated'
import { NativeMap } from '@/utils/collections'

import { State } from '.'

export const removeDeletedNodes = (
	deletedNode: StructureDto,
	state: State,
): {
	children: NativeMap<number[]>
	nodes: NativeMap<StructureDto>
	removedIds: number[]
} => {
	const removedIds: number[] = []
	const parentId = deletedNode.parentStructureId as number

	const updatedNodes = { ...state.nodes }

	const updatedChildren: NativeMap<number[]> = {
		...state.children,
		[parentId]: (state.children[parentId] || []).filter(
			(x) => x !== deletedNode.id,
		),
	}

	const deleteNodeIncludingChildren = (id: number) => {
		delete updatedNodes[id]
		delete updatedChildren[id]
		removedIds.push(id)

		const children = state.children[id] || []
		children.forEach((childId) => deleteNodeIncludingChildren(childId))
	}

	deleteNodeIncludingChildren(deletedNode.id || 0)

	return {
		nodes: updatedNodes,
		children: updatedChildren,
		removedIds,
	}
}

export const findSystemNodeId = (
	node: StructureDto,
	nodes: NativeMap<StructureDto>,
): number => {
	if (!node || !nodes || !node.id) return 0

	if (
		node.type === StructureObjectDtoOld.TypeEnum.SYSTEM ||
		!node.parentStructureId
	) {
		return node.id
	}

	const parent = nodes[node.parentStructureId]

	if (!parent) {
		return node.id
	}

	return findSystemNodeId(parent, nodes)
}

export const getParents = (
	node: StructureObjectDto,
	nodes: NativeMap<StructureObjectDto>,
	parentIds: number[],
): number[] => {
	if (
		node.parentStructureId === undefined ||
		node.parentStructureId === null ||
		nodes[node.parentStructureId] === undefined
	) {
		return parentIds
	}

	return getParents(
		nodes[node.parentStructureId] as StructureObjectDto,
		nodes,
		[...parentIds, node.parentStructureId],
	)
}
