import { faEdit, faHistory, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { toast } from 'react-toastify'

import { Button, DialogWrapper, RemoveModal } from '@/components'
import { Flex } from '@/components/Layout'
import { useAppContext, useAppSelector } from '@/hooks'
import { useDeleteTemplateMutation, useUpdateTemplateMutation } from '@/rtkApi'
import { TemplateListDto } from '@/rtkApi/mddApiSliceGenerated'
import { LightTableColumn } from '@/types'

import {
	TemplateDiff,
	TemplateFormValues,
	TemplateLockIcon,
	TemplateModal,
} from '../components'
import { handleTemplateOperation, isLockedByCurrentUser } from '../helpers'

interface UseTemplatesColumnsProps {
	isConfirmModalVisible: boolean
	onOpenConfirmModal: (onClose: any) => void
	setIsConfirmModalVisible: Dispatch<SetStateAction<boolean>>
}

export const useTemplatesColumns = ({
	onOpenConfirmModal,
	isConfirmModalVisible,
	setIsConfirmModalVisible,
}: UseTemplatesColumnsProps) => {
	const { t } = useAppContext()
	const [updateTemplate] = useUpdateTemplateMutation()
	const [deleteMutation] = useDeleteTemplateMutation()
	const { user } = useAppSelector((state) => state.auth)

	const textAreaHeight = '1.875rem'

	const onSubmit = async (
		formValues: TemplateFormValues,
		row: TemplateListDto,
		onClose: () => void,
	) => {
		try {
			await handleTemplateOperation(formValues, onClose, {
				isCreate: false,
				updateTemplate,
				templateRowData: row,
			})
			toast(t('TEMPLATE_UPDATE_SUCCESS'), {
				type: 'success',
			})
		} catch (error) {
			toast(t('TEMPLATE_UPDATE_FAILED', [error]), {
				type: 'error',
			})
		}
	}

	return useMemo<LightTableColumn<any>[]>(
		() => [
			{
				field: '',
				width: 35,
				maxWidth: 35,
				formatter: (_, row) => {
					return <TemplateLockIcon row={row} />
				},
			},
			{
				field: 'technologyName',
				title: t('TECHNOLOGY'),
				width: 80,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'structureType',
				title: t('TEMPLATE_ELEMENT'),
				width: 80,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'stereotypeName',
				title: t('STEREOTYPE'),
				width: 80,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'objectSettingsName',
				title: t('OBJECT_TYPE'),
				width: 80,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'comment',
				title: t('TEMPLATE_COMMENT'),
				width: 80,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'description',
				title: t('TEMPLATE_DESCRIPTION'),
				width: 80,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'authorName',
				title: t('TEMPLATE_AUTHOR'),
				width: 80,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'fileExtension',
				title: t('FILE_EXTENSION'),
				width: 100,
				maxWidth: 100,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'version',
				title: t('TEMPLATE_VERSION'),
				width: 58,
				maxWidth: 58,
				formatter: (cell) => cell || '-',
			},
			{
				field: '',
				width: 85,
				maxWidth: 85,
				formatter: (_, row) => {
					return (
						<Flex>
							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<TemplateModal
											textAreaHeight={textAreaHeight}
											templateRowData={row}
											onOpenConfirmModal={() => onOpenConfirmModal(onClose)}
											onSubmit={(formValues) =>
												onSubmit(formValues, row, onClose)
											}
											onCloseParentModal={onClose}
											isCreateModal={false}
											isConfirmModalVisible={isConfirmModalVisible}
											setIsConfirmModalVisible={setIsConfirmModalVisible}
										/>
									)
								}
							>
								{(onClick) => (
									<Button size="sm" schema="transparent" onClick={onClick}>
										<FontAwesomeIcon title={t('EDIT')} icon={faEdit} />
									</Button>
								)}
							</DialogWrapper>

							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && <TemplateDiff template={row} onClose={onClose} />
								}
							>
								{(onClick) => (
									<Button size="sm" schema="transparent" onClick={onClick}>
										<FontAwesomeIcon title={t('VERSIONS')} icon={faHistory} />
									</Button>
								)}
							</DialogWrapper>

							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<RemoveModal
											removeId={row.id}
											deleteMutation={
												deleteMutation as unknown as ({
													id,
												}: {
													id: number
												}) => Promise<void>
											}
											textOnModal={t('REMOVE_DESC', [
												row.description || t('TEMPLATE'),
											])}
											header={t('TEMPLATE_DELETE')}
											onClose={onClose}
											isTemplate
										/>
									)
								}
							>
								{(onClick) => {
									return (
										<Button
											disabled={!isLockedByCurrentUser(row, user)}
											size="sm"
											schema="transparent"
											onClick={onClick}
										>
											<FontAwesomeIcon
												title={t('REMOVE')}
												color="#d00"
												icon={faTimes}
											/>
										</Button>
									)
								}}
							</DialogWrapper>
						</Flex>
					)
				},
			},
		],
		[
			t,
			isConfirmModalVisible,
			setIsConfirmModalVisible,
			onOpenConfirmModal,
			onSubmit,
			deleteMutation,
			user,
		],
	)
}
