import {
	IdCodeName,
	MappingColumn,
	MappingData,
	MappingPermission,
} from '@/endpoints/schemas'

import { ensureValidData } from '../helpers'
import { MappingPermissionFlat } from './types'

export const defaultMappingData: Readonly<MappingData> = {}

export const getInitialMappingData = (
	name: string | undefined,
	code = '',
	defaultObjectSettings: IdCodeName | undefined = undefined,
) =>
	ensureValidData(
		{
			name,
			code,
			description: '',
			columns: [],
			columnsLastId: 0,
			objectSettings: defaultObjectSettings ? [defaultObjectSettings] : [],
		},
		defaultMappingData,
	)

export const createEmptyMappingColumn = (): MappingColumn => ({
	id: -1,
	columnCode: '',
	keyFlag: false,
	updateFlag: false,
	mapping: '',
	mappingDescription: '',
})

export const flatMappingPermission = (permission: MappingPermission) => {
	const { user, ...data } = permission

	const copied: MappingPermissionFlat = {
		...data,
		userId: user?.id,
		userName: user?.name,
		userCode: user?.code,
		userDescription: user?.description,
		userComment: user?.comment,
	}

	return copied
}

export const inflateMappingPermission = (permission: MappingPermissionFlat) => {
	const { userId, userName, userCode, userComment, userDescription, ...data } =
		permission

	const copied: MappingPermission = {
		...data,
		user: {
			id: userId,
			code: userCode,
			name: userName,
			comment: userComment,
			description: userDescription,
		},
	}

	return copied
}
