import { cn } from '@/utils/cn'

interface PageWrap {
	children: React.ReactNode
	hasPropPanel?: boolean
	maxWidth?: string
	onMouseEnter?: () => void
}
export const PageWrap = ({
	children,
	hasPropPanel,
	onMouseEnter,
	maxWidth,
}: PageWrap) => {
	const className = cn(
		`relative h-full pt-2 pb-0 pl-2 ${hasPropPanel ? 'pr-10' : 'pr-4'}`,
	)

	return (
		<div style={{ maxWidth }} onMouseEnter={onMouseEnter} className={className}>
			{children}
		</div>
	)
}
