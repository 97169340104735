import styled from 'styled-components'
import { colors } from '@/styles'

export const TableWrap = styled.div<{
	$maxWidth?: number
	maxHeight?: number
}>`
	/* This is required to make the table full-width */
	display: block;
	max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : '100%')};
	overflow: auto;
	position: relative;
	z-index: 5;
	max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '100%')};

	th {
		padding: 0.313rem 0.5rem;
		background: ${(props) => props.theme.colors.primary.base};
		color: #fff;
	}

	table {
		/* Make sure the inner table is always as wide as needed */
		width: 100%;
		border-spacing: 0;
		table-layout: fixed;

		td {
			padding: 0;
		}

		/* Fixes the issue with blank row below expandedRow */
		.expandedRow + tr {
			display: none !important;
		}

		.expandedRow {
			td {
				border-top: none;
				border-bottom: none;
				border-right: none;
			}
		}

		th,
		td {
			margin: 0;
			border: 1px solid ${colors.table.thead.borderColor};
			vertical-align: middle;
		}

		/* Fixes the issue with checkboxes => not centered */
		input {
			width: 100%;
		}

		input[type='text'] {
			border: none;
		}

		.table__closeBtn {
			position: sticky;
			top: 0;
			right: 0;
		}
	}

	.pagination {
		padding: 0.5rem;
	}

	.textInputWithoutBorder {
		border: none;
		min-height: 32px;
	}
`

export const TableActionCell = styled.td<{
	$hasLastRowEdit: boolean
	$isEditMode: boolean
}>`
	padding: 0 !important;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none !important;
	visibility: ${({ $isEditMode }) => {
		return $isEditMode ? 'visible' : 'hidden'
	}};
	flex-shrink: 0;
`

export const FooterContainer = styled.div`
	display: flex;
	gap: 0.75rem;
`

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
`
