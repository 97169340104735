import {
	createMRTColumnHelper,
	MRT_ColumnDef,
	MRT_Row,
} from 'material-react-table'
import { useMemo } from 'react'

import {
	CheckboxCell,
	getMrtTableDisabled,
	RowActionsCell,
	ScriptModalCell,
	SelectCell,
	TextCell,
	TextModalCell,
} from '@/components'
import { HoverableCell } from '@/components/FieldRenderer/Fields/HoverableCell'
import { DomainDto, StereotypeDto } from '@/endpoints/models'
import { TableColumn, TableConstraint } from '@/endpoints/schemas'
import { ItemPropertyType } from '@/enums'
import { useAppContext, useMemoObject } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { UseTableColumnProps } from '@/pages/User/pages/Home/pages/TableDetail/pages/types'
import { useGetStereotypesQuery } from '@/rtkApi'
import { TableColumnFlat } from '@/store/modules/table/types'
import { DisabledType } from '@/types'

const customDomainPredicate =
	(domains: DomainDto[], constraints: TableConstraint[]) =>
	(row: MRT_Row<TableColumnFlat>) =>
		!domains.find((x) => x.id === row.original.domainId && x.custom) ||
		isPrimaryOrForeignKey(constraints)(row)

const isPrimaryOrForeignKey =
	(constraints: TableConstraint[]) => (row: MRT_Row<TableColumn>) =>
		!!constraints?.find(
			(x) =>
				(x.constraintType === TableConstraint.ConstraintTypeEnum.PRIMARY_KEY ||
					x.constraintType ===
						TableConstraint.ConstraintTypeEnum.FOREIGN_KEY) &&
				x.columns?.find((c) => c.uuid === row.original.uuid),
		)

const foreignKeyValue =
	(constraints: TableConstraint[]) => (row: MRT_Row<TableColumn>) =>
		!!constraints?.find(
			(x) =>
				x.constraintType === TableConstraint.ConstraintTypeEnum.FOREIGN_KEY &&
				x.columns?.find((c) => c.uuid === row.original.uuid),
		)

const primaryKeyValue =
	(constraints: TableConstraint[]) => (row: MRT_Row<TableColumn>) => {
		const keyOrder = constraints
			.filter(
				(x) =>
					x.constraintType === TableConstraint.ConstraintTypeEnum.PRIMARY_KEY,
			)
			.map((x) => x.columns?.findIndex((c) => c.uuid === row.original.uuid))
			.find((x) => x !== undefined && x >= 0)

		return keyOrder !== undefined ? `PK [${keyOrder + 1}]` : null
	}

export const useColumnProperties = ({
	canGenerateNaming,
	constraints,
	deleteRow,
	domains,
	handleRowClick,
	isPanelOpen,
	isTechColumn,
	updateCellValue,
}: UseTableColumnProps) => {
	const { t } = useAppContext()
	const {
		state: { editMode },
	} = useDetailTabContext()

	const { data: columnStereotypes, isLoading } = useGetStereotypesQuery({
		type: StereotypeDto.TypeEnum.COLUMN,
	})

	const cachedConstraints = useMemoObject(constraints)

	const columnHelper = createMRTColumnHelper<TableColumnFlat>()

	const properties = useMemo(() => {
		return [
			columnHelper.accessor('name', {
				header: t('TABLE_COLUMNS_NAME'),
				size: 200,
				meta: {
					type: ItemPropertyType.TEXT,
				},
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<TableColumnFlat>({
						editMode,
						row,
						column,
						isTechColumn,
					})

					return isPanelOpen ? (
						<HoverableCell
							onclick={() => handleRowClick?.(row.original)}
							value={cell.getValue()}
						/>
					) : (
						<TextCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							isDisabled={isDisabled}
							updateCellValue={updateCellValue}
						/>
					)
				},
			}),
			columnHelper.accessor('code', {
				header: t('TABLE_COLUMNS_CODE'),
				size: 150,
				meta: {
					type: ItemPropertyType.TEXT,
				},
				Cell: ({ cell, table, row, column }) => (
					<TextCell
						cell={cell}
						column={column}
						table={table}
						row={row}
						isDisabled={!editMode || canGenerateNaming}
					/>
				),
			}),
			columnHelper.accessor('description', {
				header: t('MAPPING_COLUMNS_DESCRIPTION'),
				size: 100,
				meta: {
					type: ItemPropertyType.TEXTAREA,
				},
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<TableColumnFlat>({
						editMode,
						row,
						column,
						isTechColumn,
					})

					return (
						<TextModalCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('comment', {
				header: t('TABLE_COLUMNS_COMMENT'),
				size: 100,
				meta: {
					type: ItemPropertyType.TEXTAREA,
				},
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<TableColumnFlat>({
						editMode,
						row,
						column,
						isTechColumn,
					})
					return (
						<TextModalCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('domainId', {
				header: t('TABLE_COLUMNS_DOMAIN'),
				size: 100,
				meta: {
					selectOptions: domains,
					optionValue: 'id',
					optionLabel: 'name',
					isNumeric: true,
					isDisabled: isPrimaryOrForeignKey(cachedConstraints),
					type: ItemPropertyType.OPTION,
				},
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<TableColumnFlat>({
						editMode,
						row,
						column,
						isTechColumn,
					})

					return (
						<SelectCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('dataType', {
				header: t('TABLE_COLUMNS_DATA_TYPE'),
				size: 100,
				meta: {
					isDisabled: customDomainPredicate(domains, cachedConstraints),
					type: ItemPropertyType.TEXT,
				},
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<TableColumn>({
						editMode,
						row,
						column,
					})
					return (
						<TextCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							isReadOnly
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('defaultValue', {
				header: t('DOMAIN_DEFAULT_VALUE'),
				size: 80,
				meta: {
					type: ItemPropertyType.TEXT,
				},
				Cell: ({ cell, table, row, column }) => {
					return (
						<TextCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							isReadOnly
							isDisabled
						/>
					)
				},
			}),
			columnHelper.accessor('stereotypeId', {
				header: t('TABLE_COLUMNS_STEREOTYPE'),
				size: 100,
				meta: {
					selectOptions: columnStereotypes || [],
					optionValue: 'id',
					optionLabel: 'name',
					isNumeric: true,
					type: ItemPropertyType.OPTION,
				},
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<TableColumn>({
						editMode,
						row,
						column,
						isTechColumn,
					})

					return (
						<SelectCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							isLoading={isLoading}
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('virtualColumnScript', {
				header: t('TABLE_COLUMNS_VIRTUAL_COLUMN_SCRIPT'),
				size: 100,
				meta: {
					type: ItemPropertyType.ACE_EDITOR,
				},
				Cell: ({ cell, table, row, column }) => {
					const { stereotypeCode, virtualColumnScript } = row.original
					return (
						<ScriptModalCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							customValue={
								stereotypeCode && stereotypeCode === 'VIRTUAL_COLUMN'
									? virtualColumnScript
									: ''
							}
							isDisabled={stereotypeCode !== 'VIRTUAL_COLUMN'}
						/>
					)
				},
			}),
			columnHelper.accessor('primaryKey', {
				header: t('TABLE_COLUMNS_PRIMARY_KEY_ORDER_SHORT'),
				size: 40,
				meta: {
					isDisabled: primaryKeyValue(cachedConstraints) as unknown as
						| DisabledType<TableColumnFlat>
						| undefined,
				},
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<TableColumnFlat>({
						editMode,
						row,
						column,
						isTechColumn,
					})

					return (
						<TextCell
							cell={cell}
							column={column}
							table={table}
							row={row}
							isReadOnly
							customValue={primaryKeyValue(cachedConstraints)(row)}
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('notNullFlag', {
				header: t('TABLE_COLUMNS_MANDATORY_SHORT'),
				size: 60,
				enableColumnActions: false,
				enableSorting: false,
				meta: {
					isDisabled: customDomainPredicate(domains, cachedConstraints),
				},
				Cell: ({ cell, table, row, column }) => {
					const isDisabled = getMrtTableDisabled<TableColumnFlat>({
						editMode,
						row,
						column,
					})

					return (
						<CheckboxCell<TableColumn>
							cell={cell}
							column={column}
							table={table}
							row={row}
							updateCellValue={updateCellValue}
							isDisabled={isDisabled}
						/>
					)
				},
			}),
			columnHelper.accessor('foreignKey', {
				header: t('TABLE_COLUMNS_FOREIGN_KEY_SHORT'),
				size: 60,
				enableColumnActions: false,
				enableSorting: false,
				Cell: ({ cell, table, row, column }) => (
					<CheckboxCell<TableColumn>
						cell={cell}
						column={column}
						table={table}
						row={row}
						updateCellValue={updateCellValue}
						isDisabled
						customValue={foreignKeyValue(cachedConstraints)}
					/>
				),
			}),
			columnHelper.accessor('rightAction', {
				header: 'Actions',
				size: 50,
				enableColumnActions: false,
				enableSorting: false,
				Cell: ({ table, row }) => (
					<RowActionsCell deleteRow={deleteRow} table={table} row={row} />
				),
			}),
		]
	}, [
		cachedConstraints,
		canGenerateNaming,
		columnHelper,
		columnStereotypes,
		deleteRow,
		domains,
		editMode,
		handleRowClick,
		isLoading,
		isPanelOpen,
		isTechColumn,
		t,
		updateCellValue,
	])

	return properties as MRT_ColumnDef<TableColumnFlat>[]
}
