import { configureStore } from '@reduxjs/toolkit'

import { mddApiSliceGenerated } from '@/rtkApi'
import {
	diagramApiSlice,
	historyApiSlice,
	objectSettingsApiSlice,
	stereoTypeApiSlice,
	technologiesApiSlice,
	templateApiSlice,
} from '@/rtkQueries'

import { rootReducer } from './modules'

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(diagramApiSlice.middleware)
			.concat(historyApiSlice.middleware)
			.concat(stereoTypeApiSlice.middleware)
			.concat(objectSettingsApiSlice.middleware)
			.concat(technologiesApiSlice.middleware)
			.concat(technologiesApiSlice.middleware)
			.concat(templateApiSlice.middleware)
			.concat(mddApiSliceGenerated.middleware),
	devTools: { trace: true, traceLimit: 30 },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export * from './modules'
