import omit from 'lodash/omit'
import { useMemo } from 'react'

import { AnyObject } from '@/components/UberForm/types'
import {
	GetAllTemplatesApiResponse,
	TemplateListDtoRead,
} from '@/rtkApi/mddApiSliceGenerated'
import { hasObjectAllValues } from '@/utils/collections'

import { TemplateFilterValues } from '../components/types'

export const useTemplatesFilteredData = (
	filter: TemplateFilterValues,
	data: GetAllTemplatesApiResponse = [],
): TemplateListDtoRead[] => {
	const filteredData: TemplateListDtoRead[] = useMemo(() => {
		const dataValues: TemplateListDtoRead[] = data.filter(
			(item: TemplateListDtoRead) => {
				const valuesEqual: boolean = hasObjectAllValues(
					item as AnyObject,
					omit(filter, 'authorNames'),
				)

				return valuesEqual
			},
		)

		return dataValues
	}, [data, filter])

	return filteredData
}
