import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { TableAndProperties } from '@/components'
import { useCanGenerateNaming } from '@/components/UberForm/Input/Naming/useCanGenerateNaming'
import { StructureDto } from '@/endpoints/models'
import { TableColumn, TableConstraint, TableData } from '@/endpoints/schemas'
import { useAppContext, useAppDispatch, useAppStore } from '@/hooks'
import { initTable } from '@/store/modules/table/actions'
import { createEmptyKey } from '@/store/modules/table/helpers'
import { OpenedTableData } from '@/store/modules/table/types'
import { UpdateDeepPartial } from '@/store/utils'
import { NamingTypeEnum, PossibleForeignKeys } from '@/types'

import { useConstraintsProperties } from './useConstraintsProperties'
import {
	findPrimarykeyColumns,
	getParsedData,
	getPossibleForeignKeys,
} from './utils'
import { duplication } from './validation'

type ConstraintsProps = {
	data: OpenedTableData
	node: StructureDto
	onChange: (v: UpdateDeepPartial<TableData>) => void
	systemNodeId: number
	technicalColumns: TableColumn[]
}

const ConstraintsComponent = ({
	node,
	data,
	systemNodeId,
	onChange,
	technicalColumns,
}: ConstraintsProps) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const tables = useAppStore((state) => state.table.tables)

	// primaryKeys of referenced table
	const [primaryKeys, setPrimaryKeys] = useState<TableColumn[]>([])

	// Columns of current table with the same data type as PK
	const [possibleForeignKeys, setPossibleForeignKeys] =
		useState<PossibleForeignKeys>({})

	const [foreignKeyTableToProcess, setForeignKeyTableToProcess] = useState<{
		id: number
	} | null>(null)

	const canGenerateNaming = useCanGenerateNaming(
		node.id,
		NamingTypeEnum.GENERATE_TABLE_COLUMN_CODE,
	)

	const properties = useConstraintsProperties({
		columns: [...data.form.columns.filter((c) => c.name), ...technicalColumns],
		foreignKeyTableId: foreignKeyTableToProcess?.id,
		possibleForeignKeys,
		primaryKeys,
		systemNodeId,
		canGenerateNaming,
	})

	const foreignKeyTable = useMemo(
		() => foreignKeyTableToProcess && tables[foreignKeyTableToProcess.id],
		[tables, foreignKeyTableToProcess],
	)

	const setForeignKeyTable = useCallback(
		async (constraints?: TableConstraint[]) => {
			// update foreignKeyTable only when the onChange is called for single constraint
			if (!constraints || constraints.length !== 1) {
				return
			}

			const changedData = Object.values(constraints).map((data) => ({
				constraintType: data.constraintType,
				foreignKeyTableId: data.foreignKeyTableId as number,
			}))[0]

			if (!changedData.foreignKeyTableId) {
				return
			}

			if (!tables[changedData.foreignKeyTableId]) {
				await dispatch(initTable({ nodeId: changedData.foreignKeyTableId }))
			}

			setForeignKeyTableToProcess({ id: changedData.foreignKeyTableId })
		},
		[dispatch, tables],
	)

	// update primaryKeys & possibleForeignKeys on row change
	const onSelect = useCallback(
		(item: TableConstraint) => {
			setForeignKeyTable([item])
		},
		[setForeignKeyTable],
	)

	useEffect(() => {
		if (!foreignKeyTableToProcess || !foreignKeyTable) {
			return
		}

		const { primaryColumns } = findPrimarykeyColumns(
			foreignKeyTable.form,
			foreignKeyTable,
		)

		const foreignKeys = getPossibleForeignKeys(
			primaryColumns,
			data.form.columns,
			data.stereotypeColumns,
		)

		setPrimaryKeys(primaryColumns)
		setPossibleForeignKeys(foreignKeys)
	}, [
		foreignKeyTableToProcess,
		data.form.columns,
		data.stereotypeColumns,
		foreignKeyTable,
	])

	const handleChange = async (v: UpdateDeepPartial<TableData>) => {
		// parse data to change form value in COLUMNS & CONSTRAINTS tabs
		const {
			columns,
			columnsLastId,
			constraints,
			constraintTypeChanged,
			foreignKeyTableChanged,
			current,
		} = getParsedData(v, data, primaryKeys)

		// clear reference table when constraint type changed
		if (constraintTypeChanged && current) {
			onChange({ constraints: { [current]: { foreignKeyTableId: undefined } } })
		}

		// clear columns when referenced table changed
		if (foreignKeyTableChanged && current) {
			onChange({ constraints: { [current]: { columns: [] } } })
		}

		if (columns.length > 0) {
			onChange({
				constraints,
				columns,
				columnsLastId,
			})
		} else {
			onChange(v)
		}

		setForeignKeyTable(Object.values(v.constraints as object))
	}

	const errors = useMemo(
		() => duplication(data.form.constraints, t),
		[data.form.constraints, t],
	)

	return (
		<TableAndProperties
			idKey="id"
			node={node}
			data={data.form}
			createEmpty={createEmptyKey}
			itemsKey="constraints"
			idCounterKey="constraintsLastId"
			onChange={handleChange}
			panelPropertiesMinWidth={500}
			panelPropertiesDefaultOpen
			properties={properties}
			tabKey="key"
			onSelect={onSelect}
			errors={errors}
		/>
	)
}

export const Constraints = React.memo(ConstraintsComponent)
