import {
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'

import {
	CodeInputFormless,
	errorGet,
	ErrorTooltip,
	NewCheckBox,
	useTablePropertiesContext,
} from '@/components'
import { CellValueWrapper } from '@/components/Properties/components/CellValueWrapper'
import { callBackChangeHandler } from '@/components/Table/utils'
import { ItemPropertyType, Position } from '@/enums'
import { useDebounceCallback } from '@/hooks'
import { PropertiesErrorProps } from '@/types'
import { nextFrame } from '@/utils/async'

import { ScriptModalCell, TextModalCell } from './Cell'
import { EditableCellSwitch } from './EditableCellSwitch'
import { EditableCellProps } from './types'

export const EditableCell = forwardRef(
	(
		{
			autoFocus,
			cell,
			handleChange,
			value,
			columnModelProps,
			properties,
			node,
		}: EditableCellProps,
		lastEditedCellRef, // Add the ref as a second prop here
	) => {
		const [inputValue, setInputValue] = useState()
		const [focused, setFocused] = useState(false)
		const container = useRef<HTMLDivElement>(null)

		const context = useTablePropertiesContext()

		const items = context ? context.state.items : []
		const readonly = context ? context.state.readonly : false
		const errors = context ? context.state.errors : false

		const cellRowProps = cell.row.original

		const error =
			errorGet(
				properties,
				columnModelProps,
				cellRowProps,
				errors as PropertiesErrorProps,
			) || false

		const onDisableEditing = useCallback(async () => {
			await nextFrame()

			if (
				document.activeElement === null ||
				document.activeElement.tagName === 'BODY'
			) {
				container.current?.focus()
				setFocused(true)
			}
		}, [])

		const isDisabled = useMemo(() => {
			if (typeof columnModelProps.disabled === 'function') {
				return columnModelProps.disabled(cellRowProps, items)
			} else if (typeof columnModelProps.disabled === 'boolean') {
				return columnModelProps.disabled
			} else {
				return false
			}
		}, [cellRowProps, items, columnModelProps])

		const onChangeDebounce = useDebounceCallback((value: any) => {
			return callBackChangeHandler(handleChange, cell, columnModelProps, value)
		}, 500)

		const textOnChange = useCallback((value: any) => {
			setInputValue(value)
			onChangeDebounce(value)
		}, [])

		const checkboxOnchange = (value: any) => {
			return callBackChangeHandler(handleChange, cell, columnModelProps, value)
		}

		// If the initialValue is changed external, sync it up with our state
		useEffect(() => {
			setInputValue(value)
		}, [value])

		if (columnModelProps.type === ItemPropertyType.CHECKBOX) {
			// render Checkbox because we want to change it on click in both readonly and editing modes
			return (
				<NewCheckBox
					checked={value}
					disabled={isDisabled || (columnModelProps.disabled as boolean)}
					onChange={() => checkboxOnchange(!value)}
					name={columnModelProps.field}
				/>
			)
		}

		if (columnModelProps.type === ItemPropertyType.GENERATED_CODE) {
			const readonlyContainer = (value: any) => (
				<CellValueWrapper disabled={isDisabled}>{value}</CellValueWrapper>
			)

			return (
				<ErrorTooltip
					shown={!!error && focused}
					showOnHover={false}
					content={error}
					position={Position.Bottom}
				>
					<CodeInputFormless
						value={
							columnModelProps.canGenerateCodeFieldName ? value : inputValue
						}
						name={columnModelProps.field}
						onChange={(value) => textOnChange(value)}
						error={error}
						readonly={readonly}
						disabled={columnModelProps.canGenerateCodeFieldName}
						readonlyContainer={readonlyContainer}
						hasNoBorder
					/>
				</ErrorTooltip>
			)
		}

		if (columnModelProps.type === ItemPropertyType.TEXTAREA) {
			return (
				<TextModalCell
					value={value}
					onChange={onChangeDebounce}
					isEditMode={!readonly}
					label={columnModelProps.label}
					isDisabled={isDisabled}
				/>
			)
		}

		if (columnModelProps.type === ItemPropertyType.ACE_EDITOR) {
			return (
				<ScriptModalCell
					value={value}
					onChange={onChangeDebounce}
					isEditMode={!readonly}
					label={columnModelProps.label}
					isDisabled={isDisabled}
				/>
			)
		}

		if (columnModelProps.type === ItemPropertyType.CUSTOM_JSX) {
			return columnModelProps.Cell && columnModelProps.Cell(cell as any)
		}

		return (
			<EditableCellSwitch
				autoFocus={autoFocus}
				value={value}
				inputValue={inputValue}
				onChange={onChangeDebounce}
				prop={columnModelProps}
				readonly={readonly}
				item={cellRowProps}
				isDisabled={isDisabled}
				onDisableEditing={onDisableEditing}
				focused={focused}
				error={error}
				textRef={lastEditedCellRef}
				textOnChange={textOnChange}
				node={node}
			/>
		)
	},
)
