import { MaterialReactTable } from 'material-react-table'
import { useCallback, useEffect } from 'react'

import { muiRowDragHandleProps, PageWrap, TableToolbar } from '@/components'
import { newPermissionPayload } from '@/constants'
import { useAppStore, useTable, useTableHandlers } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { permissionUpdateCellValue } from '@/pages/User/pages/Home/helpers'
import { useGetSystemUsersQuery } from '@/rtkApi'
import { SystemUser } from '@/rtkApi/mddApiSliceGenerated'
import { saveTable, updateTable } from '@/store/modules/table/actions'
import { normalizeTableData } from '@/utils'

import { TablePermissionExt } from '../types'
import { useTablePermissionsColumns } from './useTablePermissionsColumns'

export const Permissions = () => {
	const {
		state: { node, systemNodeId },
	} = useDetailTabContext()

	const tables = useAppStore((state) => state.table.tables)
	const tablesData = tables[node.id ?? 0]

	const systemUsers = useGetSystemUsersQuery({ structureId: systemNodeId })
	const MAX_TABLE_WIDTH = '1200px'

	const {
		deleteRow,
		isUserInteracting,
		setTableData,
		tableData,
		updateTableData,
		updateCellValue,
	} = useTableHandlers<TablePermissionExt>({
		initialData: [],
		reduxActions: {
			saveAction: saveTable,
			updateAction: updateTable,
			updateKey: 'objectPermissions',
		},
		customUpdateCellValue: useCallback(
			(rowIndex: number, columnId: keyof TablePermissionExt, value: unknown) =>
				permissionUpdateCellValue<TablePermissionExt>({
					rowIndex,
					columnId,
					value,
					updateTableData,
					isUserInteracting,
					systemUsers: systemUsers.data as SystemUser[],
				}),
			[systemUsers.data],
		),
	})

	const columns = useTablePermissionsColumns({ deleteRow, updateCellValue })

	const table = useTable<TablePermissionExt>({
		columns,
		data: tableData,
		options: {
			initialState: {
				density: 'compact',
			},
			renderBottomToolbar: () => {
				return (
					<TableToolbar<TablePermissionExt>
						updateTableData={updateTableData}
						newRowPayload={newPermissionPayload}
						tableData={tableData}
					/>
				)
			},
			muiRowDragHandleProps: ({ table }) =>
				muiRowDragHandleProps({ updateTableData, table }),
		},
		maxWidth: MAX_TABLE_WIDTH,
	})

	useEffect(() => {
		if (!tablesData) return
		setTableData(
			normalizeTableData<TablePermissionExt>(
				tablesData?.form.objectPermissions,
			),
		)
	}, [tablesData])

	return (
		<PageWrap
			maxWidth={MAX_TABLE_WIDTH}
			onMouseEnter={() => {
				isUserInteracting.current = true
			}}
		>
			<MaterialReactTable table={table} />
		</PageWrap>
	)
}
