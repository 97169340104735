import { MRT_RowData } from 'material-react-table'
import { useState } from 'react'

import { cn } from '@/utils/cn'

import { getInitCellValue } from '../helpers'
import { type MrtCellProps } from '../types'

export const TextCell = <TData extends MRT_RowData>({
	cell,
	row,
	column,
	updateCellValue,
	isReadOnly = false,
	customValue,
	isDisabled = false,
}: MrtCellProps<TData>) => {
	const [value, setValue] = useState(getInitCellValue(customValue, cell))

	const className = cn('p-2 h-full w-full', {
		'disabled:text-gray-400 disabled:cursor-not-allowed': isDisabled,
	})

	return (
		<input
			readOnly={isReadOnly}
			style={{ border: 'none' }}
			className={className}
			type="text"
			value={value}
			onBlur={(event) =>
				updateCellValue?.(
					row?.index as number,
					column?.id as string,
					event.target.value,
				)
			}
			onChange={(event) => setValue(event.target.value)}
			disabled={isDisabled}
		/>
	)
}
