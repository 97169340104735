import { Tabs } from '@/components'
import { useAppContext, useAppStore } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { NewVersionsTab } from '@/pages/User/pages/Home/components/VersionsTab/NewVersionsTab'
import { MappingTabsProps } from '@/pages/User/pages/Home/types'
import { MappingTab } from '@/store/modules/mapping/types'

import {
	NewDependencies,
	NewDeployments,
	NewValidation,
	Preview,
} from '../../../components'
import {
	MappingColumnTab,
	MappingOverview,
	MappingSourceTab,
	Others,
	Permissions,
} from '../pages'

export const MappingTabs = ({
	defaultMapping,
	onTabChange,
	onChange,
}: MappingTabsProps) => {
	const {
		state: { node },
	} = useDetailTabContext()

	const mappings = useAppStore((state) => state.mapping.mappings)
	const data = mappings[node?.id ?? 0]
	const objectTypes = data?.form.objectSettings

	const { t } = useAppContext()

	return (
		<Tabs
			hasNewTable
			onChange={onTabChange}
			selectedTabId={data?.tab}
			tabs={[
				{
					id: MappingTab.Overview,
					title: t('TAB_OVERVIEW'),
					content: (
						<MappingOverview
							defaultMapping={defaultMapping}
							onChange={onChange}
						/>
					),
				},
				...(!defaultMapping
					? [
							{
								id: MappingTab.Columns,
								title: t('TAB_COLUMN_MAPPINGS'),
								content: <MappingColumnTab />,
							},
							{
								id: MappingTab.Sources,
								title: t('TAB_SOURCES'),
								content: <MappingSourceTab onChange={onChange} />,
							},
							{
								id: MappingTab.Permissions,
								title: t('PERMISSIONS'),
								content: <Permissions />,
							},
							{
								id: MappingTab.Others,
								title: t('TAB_OTHERS'),
								content: <Others onChange={onChange} />,
							},
						]
					: [
							{
								id: MappingTab.Permissions,
								title: t('PERMISSIONS'),
								content: <Permissions />,
							},
						]),
				{
					id: MappingTab.Preview,
					title: t('TAB_PREVIEW'),
					content: (
						<Preview
							key={MappingTab.Preview}
							node={node}
							showObjectTypes
							objectTypes={objectTypes}
						/>
					),
				},
				{
					id: MappingTab.Deployments,
					title: t('TAB_DEPLOYMENTS'),
					content: <NewDeployments />,
				},
				{
					id: MappingTab.Validation,
					title: t('TAB_VALIDATION'),
					content: <NewValidation />,
				},
				{
					id: MappingTab.Dependencies,
					title: t('DEPENDENCIES'),
					content: <NewDependencies />,
				},
				{
					id: MappingTab.Versions,
					title: t('VERSIONS'),
					content: <NewVersionsTab />,
				},
			]}
		/>
	)
}
