import styled from 'styled-components'

export const HeaderContainer = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 2px solid ${(props) => props.theme.mainColors.primary};
	padding-bottom: 10px;
	margin-bottom: 10px;
	font-size: 1.5rem;
`
export const Header = styled.div`
	font-size: 18px;
	margin-right: 6px;
`

export const DropZone = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border: ${(props) => `1px dashed ${props.theme.colors.primary.base}`};
	height: 30px;
	width: 200px;
	margin-right: 6px;
`

export const Info = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border: ${(props) => `1px solid ${props.theme.colors.primary.base}`};
	padding: 6px;
	width: 10px;
	height: 10px;
	margin-bottom: 10px;
`

export const SqlData = styled.pre`
	position: relative;
	min-height: 400px;
	height: 100%;
`
