import { createApi } from '@reduxjs/toolkit/query/react'

import { apiRequest } from '@/endpoints/axiosInstance'
import { HttpMethod } from '@/endpoints/utils'
import {
	CreateTemplateApiArg,
	CreateTemplateApiResponse,
	DeleteTemplateApiArg,
	DeleteTemplateApiResponse,
	GetAllTemplatesApiArg,
	GetAllTemplatesApiResponse,
	GetTemplateApiArg,
	GetTemplateApiResponse,
	LockApiArg,
	LockApiResponse,
	PushApiArg,
	PushApiResponse,
	UnlockApiArg,
	UnlockApiResponse,
	UpdateTemplateApiArg,
	UpdateTemplateApiResponse,
} from '@/generated-sources/mddApi'

export const templateApiSlice = createApi({
	reducerPath: 'templateApiSlice',
	baseQuery: apiRequest, // Adjust the base URL based on your needs
	tagTypes: ['Templates'], // Add this line to explicitly define your valid tags
	endpoints: (build) => ({
		createTemplate: build.mutation<
			CreateTemplateApiResponse,
			CreateTemplateApiArg
		>({
			query: ({ templateDto }) => ({
				url: `/templates`,
				method: HttpMethod.POST,
				body: templateDto,
			}),
			invalidatesTags: ['Templates'],
		}),
		getAllTemplates: build.query<
			GetAllTemplatesApiResponse,
			GetAllTemplatesApiArg
		>({
			query: ({ technologyId, stereotypeId }) => ({
				url: '/templates',
				method: HttpMethod.GET,
				params: {
					technologyId,
					stereotypeId,
				},
			}),
			providesTags: ['Templates'],
		}),
		updateTemplate: build.mutation<
			UpdateTemplateApiResponse,
			UpdateTemplateApiArg
		>({
			query: ({ id, templateDto }) => ({
				url: `/templates/${id}`,
				method: HttpMethod.PUT,
				body: templateDto,
			}),
			invalidatesTags: ['Templates'],
		}),
		deleteTemplate: build.mutation<
			DeleteTemplateApiResponse,
			DeleteTemplateApiArg
		>({
			query: ({ id }) => ({
				url: `/templates/${id}`,
				method: HttpMethod.DELETE,
			}),
			invalidatesTags: ['Templates'],
		}),
		getTemplate: build.query<GetTemplateApiResponse, GetTemplateApiArg>({
			query: ({ id }) => ({
				url: `/templates/${id}`,
				method: HttpMethod.GET,
			}),
			providesTags: ['Templates'],
		}),
		lockTemplate: build.mutation<LockApiResponse, LockApiArg>({
			query: ({ templateId }) => ({
				url: `/templates/${templateId}/lock`,
				method: HttpMethod.POST,
			}),
			invalidatesTags: ['Templates'],
		}),
		unlockTemplate: build.mutation<UnlockApiResponse, UnlockApiArg>({
			query: ({ templateId }) => ({
				url: `/templates/${templateId}/unlock`,
				method: HttpMethod.POST,
			}),
			invalidatesTags: ['Templates'],
		}),
		pushTemplate: build.mutation<PushApiResponse, PushApiArg>({
			query: ({ templateId, unlock }) => ({
				url: `/templates/${templateId}/push`,
				method: HttpMethod.POST,
				params: { unlock },
			}),
			invalidatesTags: ['Templates'],
		}),
	}),
})

export const {
	useCreateTemplateMutation,
	useDeleteTemplateMutation,
	useGetAllTemplatesQuery,
	useUpdateTemplateMutation,
	useGetTemplateQuery,
	useLockTemplateMutation,
	useUnlockTemplateMutation,
	usePushTemplateMutation,
} = templateApiSlice
