import React from 'react'

import { cn } from '@/utils/cn'

import { ValueOverflow } from './PropertyFieldStyles'

type Props = {
	children: React.ReactNode
	disabled?: boolean
	setRefWrapper?: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
	title?: string
}

export const CellValueWrapper = ({
	disabled,
	children,
	setRefWrapper,
	title,
}: Props) => {
	const divClasses = cn(
		'flex items-center p-2', // padding (p-2) to add some spacing around content
		{
			'bg-gray-200': disabled, // grey background when disabled
			'bg-white': !disabled, // white background as default
		},
	)

	return (
		<div className={divClasses} ref={setRefWrapper} title={title}>
			<ValueOverflow>{children}</ValueOverflow>
		</div>
	)
}
