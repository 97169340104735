import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Drawer } from '@mui/material'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import debounce from 'debounce'
import { cloneDeep, isEmpty } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'

import { FormFieldRenderer } from '@/components'
import { useTableContext } from '@/components/NewTable'
import {
	OpenButton,
	OpenButtonIcon,
	OpenButtonText,
	Title,
	ToggleButton,
} from '@/components/Properties/styles'
import { StructureObjectDto } from '@/endpoints/models'
import { ItemPropertyType, PageType, TableMode } from '@/enums'
import {
	useAppContext,
	useAppStore,
	useCustomAttributesInitValues,
} from '@/hooks'
import { NewCustomAttributes } from '@/pages/User/pages/Home/components/CustomAttributes/NewCustomAttributes'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { generateColumnOptions } from '@/utils'

import { NewPanelPropertiesProps, WithCustomAttributes } from './types'
import { filterFalsyAttributes, getInitialAttributes } from './utils'

export const NewPanelProperties = <T extends WithCustomAttributes<T>>({
	parentColumns = [],
	formFields,
	updateSelectedRow,
	handleClosePanel,
	handleOpenPanel,
}: NewPanelPropertiesProps<T>) => {
	const WIDTH = 650
	const { t } = useAppContext()
	const {
		state: { node },
	} = useDetailTabContext()
	const { isPanelOpen, selectedRow, availableColumns } = useTableContext<T>()

	const tables = useAppStore((state) => state.table.tables)
	const table = tables[Number(node.id)]
	const form = table?.form ? table.form : undefined
	const existingCustomAttributes = selectedRow?.customAttributes
	const mode = table ? table.mode : TableMode.TABLE

	const prevIsPanelOpen = useRef(isPanelOpen)
	const prevSelectedRowId = useRef(selectedRow?.id)

	const { customAttributes } = useCustomAttributesInitValues(
		form,
		StructureObjectDto.TypeEnum.TABLE_COLUMN,
		mode,
	)

	const {
		control,
		watch,
		reset,
		formState: { isDirty },
	} = useForm()

	const { fields, replace } = useFieldArray({
		control,
		name: 'customAttributes',
	})

	const initialCustomAttributes = useMemo(() => {
		return getInitialAttributes(customAttributes, existingCustomAttributes)
	}, [customAttributes, existingCustomAttributes])

	const getSelectOptions = () => {
		if (selectedRow && 'columnCode' in selectedRow && availableColumns) {
			return generateColumnOptions(
				selectedRow.columnCode as string,
				parentColumns,
				availableColumns,
			)
		}
	}

	const selectOptions = useMemo(
		() => getSelectOptions(),
		[parentColumns, selectedRow],
	)

	const debouncedUpdate = debounce((formValues) => {
		const filteredValues = {
			...formValues,
			customAttributes: filterFalsyAttributes(formValues.customAttributes),
		}

		updateSelectedRow(filteredValues)
	}, 500)

	useEffect(() => {
		const panelJustOpened = !prevIsPanelOpen.current && isPanelOpen
		const selectedRowIdChanged = prevSelectedRowId.current !== selectedRow?.id

		if (
			selectedRow &&
			initialCustomAttributes &&
			((panelJustOpened && !isDirty) || selectedRowIdChanged)
		) {
			const { customAttributes: _customAttributes, ...restRow } = selectedRow
			reset(cloneDeep(restRow))
			replace(cloneDeep(initialCustomAttributes))
		}

		prevIsPanelOpen.current = isPanelOpen
		prevSelectedRowId.current = selectedRow?.id
	}, [
		selectedRow?.id,
		isPanelOpen,
		reset,
		replace,
		initialCustomAttributes,
		isDirty,
		selectedRow,
	])

	useEffect(() => {
		const subscription = watch((formValues) => {
			debouncedUpdate(cloneDeep(formValues))
		})
		return () => subscription.unsubscribe()
	}, [debouncedUpdate, watch])

	const optionFields = formFields.filter(
		(prop) => prop.meta?.type === ItemPropertyType.CHECKBOX,
	)
	const otherFields = formFields.filter(
		(prop) => prop.meta?.type !== ItemPropertyType.CHECKBOX,
	)

	return (
		<>
			<OpenButton role="button">
				<div>
					<OpenButtonText onClick={handleOpenPanel}>
						{t('PROPERTIES_TITLE')}
					</OpenButtonText>
					<OpenButtonIcon>
						<FontAwesomeIcon icon={faChevronLeft} color="#888" />
					</OpenButtonIcon>
				</div>
			</OpenButton>
			<Drawer
				sx={{
					position: 'absolute',
					top: 0,
					right: 0,
					height: '100%',
					width: WIDTH,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: WIDTH,
						position: 'absolute',
						height: '100%',
					},
				}}
				variant="persistent"
				anchor="right"
				open={isPanelOpen}
			>
				<Title onClick={handleClosePanel}>
					<ToggleButton>
						<FontAwesomeIcon icon={faChevronRight} color="#888" />
					</ToggleButton>
					{t('PROPERTIES_TITLE')}
				</Title>
				{selectedRow ? (
					<Box sx={{ p: 2, width: '100%' }}>
						<Grid container direction="column" spacing={2}>
							<form>
								<section>
									<Typography
										variant="subtitle1"
										className="border-b border-solid border-emerald-900"
									>
										{t('PROPERTIES_TITLE')}
									</Typography>

									{otherFields.map((prop) => {
										return (
											<Grid>
												<FormFieldRenderer
													key={prop.accessorKey}
													fieldProp={prop}
													fieldName={prop.accessorKey || ''}
													control={control}
													selectOptions={selectOptions}
												/>
											</Grid>
										)
									})}

									{optionFields.length > 0 && (
										<div className="grid grid-cols-3 gap-4 items-center">
											{optionFields.map((prop) => (
												<Grid>
													<FormFieldRenderer
														key={prop.accessorKey}
														fieldProp={prop}
														fieldName={prop.accessorKey || ''}
														control={control}
														selectOptions={selectOptions}
													/>
												</Grid>
											))}
										</div>
									)}
								</section>

								{/* Custom Attributes */}
								{!isEmpty(selectedRow.customAttributes) &&
									node.type === 'TABLE' && (
										<NewCustomAttributes<T>
											item={selectedRow}
											$pageType={PageType.COLUMN_PAGE}
											control={control}
											fields={fields}
										/>
									)}
							</form>
						</Grid>
					</Box>
				) : (
					<p className="font-bold text-amber-600 p-5">
						Please select a row to see the properties
					</p>
				)}
			</Drawer>
		</>
	)
}
