import AddIcon from '@mui/icons-material/Add'
import { Button as MuiButton } from '@mui/material'

import { TableToolbarProps } from '@/components'
import { addNewRow } from '@/components/NewTable'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { StructureDto } from '@/rtkApi/mddApiSliceGenerated'

export const TableToolbar = <T extends StructureDto>({
	customButtons,
	newRowPayload,
	updateTableData,
}: TableToolbarProps<T>) => {
	const {
		state: { editMode },
	} = useDetailTabContext()

	return (
		<section className="flex items-center pt-4 gap-4">
			<MuiButton
				onClick={() => addNewRow<T>(updateTableData, newRowPayload)}
				startIcon={<AddIcon />}
				disabled={!editMode}
				variant="contained"
				color="primary"
			>
				New
			</MuiButton>

			{customButtons ? customButtons : <></>}
		</section>
	)
}
