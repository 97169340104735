import { components, OptionProps } from 'react-select'

const { Option } = components
// Custom Option component that shows the name in bold and the full path in regular text with a tooltip

export const CustomSelectOption = (props: OptionProps<any>) => {
	return (
		<Option {...props}>
			<div className="flex flex-col">
				<div className="font-bold"> {props.data.name} </div>
				<div title={props.data.fullPath} className="text-sm">
					{props.data.fullPath}
				</div>
			</div>
		</Option>
	)
}
