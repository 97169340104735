import { TableSelectOptions } from '@/types'

/**
 * Helper function to handle options that might be either a function or a direct value
 * @param options - The options value or function that returns options
 * @returns The resolved options array
 */
export const resolveSelectOptions = (
	options: TableSelectOptions<any>,
): Record<string, any>[] => {
	if (typeof options === 'function') {
		return options()
	}
	return options || []
}
