import React from 'react'

import { FormFieldProps } from '@/components/UberForm/types'

import FormField, { FormlessFormField } from '../FormField'
import { ArrayFormFieldWrapper } from './ArrayFormFieldWrapper'
import { ArrayFormFieldProps } from './types'

/**
 * Form field with text input.
 */
export default class ArrayFormField extends React.PureComponent<
	Omit<ArrayFormFieldProps, 'onChange'> &
		FormFieldProps & { formless?: boolean }
> {
	render() {
		const FF = this.props.formless ? FormlessFormField : FormField

		return (
			<FF {...this.props}>
				<ArrayFormFieldWrapper {...this.props} />
			</FF>
		)
	}
}
